import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './startupnavpage.css'
import { NavLink } from 'react-router-dom';
import useDidMountEffect from '../../../hooks/useDidMountEffect';

const Startupnavpage = () => {

  const [showPartners, setShowPartners] = useState('')
  useDidMountEffect(() => {
    // let tanseed = localStorage.getItem('tanseed')
    // setShowPartners(tanseed)
    // const url = 
    setShowPartners(window.location.hash)
    // debugger
  }, [])
  const routeNameValue = localStorage.getItem('routePath')
  const val = '/startup/associated-partners'
  const val1 = '/startup/associated-hub'
  const handleClick = (event) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
    }
  };
  return (
    <nav>
      <div className='submenu_div'>
        <div className='container-fluid'>
          <Box sx={{ display: 'flex', paddingTop: '5px', textAlign: 'center', marginLeft: '25px', marginRight: '25px' }}>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to="/startup/all-dashboard/scst">SC/ST</NavLink>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to="/startup/all-dashboard/tanfund">TANFUND</NavLink>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to="/startup/all-dashboard/tanseed">TANSEED</NavLink>
            </Typography>

            {/* <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to={routeNameValue === '/startup/dashboard/SCST' ? val1 : val} onClick={handleClick}>
                {routeNameValue === '/startup/dashboard/SCST' ? "Associated Hub" : "Associated Partners"}
              </NavLink>
            </Typography> */}
            {/* <Typography sx={{ minWidth: 100 }}>
                <NavLink className="nav-link"  to="/investor/jury" onClick={handleClick}>Jury</NavLink>
                </Typography>
                <Typography sx={{ minWidth: 100 }}>
                <NavLink className="nav-link"  to="/investor/round" onClick={handleClick}>Rounds</NavLink>
                </Typography>         */}
            {
              showPartners === '#/startup/dashboard' && (
                <Typography sx={{ minWidth: 100 }}>
                  <NavLink className="nav-link" activeclassname="" to="/startup/tanseed" onClick={handleClick}>TANSEED Partners</NavLink>
                </Typography>
              )
            }
          </Box>
        </div>
      </div>
    </nav>
  )
}

export default Startupnavpage