import React, { useContext, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Accordion, AccordionDetails, AccordionSummary, Button, Table, TableBody, TableCell, TableContainer, TableRow, Paper, TextField, Typography, FormControl, FormHelperText, FormLabel, RadioGroup, FormControlLabel, Radio, Autocomplete, InputLabel, Select, MenuItem } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DataContext from '../../../../context/DataContext';
import notifyService from '../../../../api/notifySerivce';
import { Link } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Viewimage from '../../../../api/Viewimage';
import cryptodecryptJS from '../../../../hooks/cryptodecryptJS';
import PreliminaryTextPart1 from './PreliminaryTextPart1';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
// const fileTypes = ["pdf","XLSX","zip","doc", "docx"];
const fileTypes = ["pdf"];
const fileTypesPDF = ["pdf"];
const fileTypesExcel = ["XLSX",];
const fileTypesPDFandExcel = ["pdf", "XLSX",];
const fileTypesAll = ["pdf", "XLSX", "doc", "docx"];
// const fileTypesExcel = ["pdf", "XLSX"];
const PreliminaryDocumentPart1 = () => {
    const [formData, setFormData] = useState({});
    const { handleBackapp, setPageLoading, validateNumberonly } = useContext(DataContext)
    const { handleSubmit, setValue, getValues, clearErrors, control, formState: { errors } } = useForm({
        values: formData,
    });
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    useDidMountEffect(() => {
        getApi()
        getCityApi();
        getStateApi();
    }, [])
    const [fileUploadId, setFileUploadId] = useState(0)
    const [nonEditAble, SetNonEditAble] = useState(false)
    const [patentsShow, SetPatentsShow] = useState(false)
    const getApi = async () => {
        setPageLoading(true)
        await apiService(`startup/ldd/sectionone/get`, '', 'get').then((res) => {
            setPageLoading(false)
            if (res && res.data) {
                var data = res.data
                SetNonEditAble(true)
                if (data.editForm === false) {
                    setReadyOnlyValue(true)
                }
                if (data.patents) {
                    SetPatentsShow(true)
                }
                if (data.id) {
                    setFileUploadId(res.data.id)
                }
                if (data.nonResidentShareholder) {

                    setResidentShareholder(data.nonResidentShareholder)
                }
                if (data.technologyUsageFees) {

                    setTechnology(data.technologyUsageFees)
                }
                if (res.data.shareHoldingPatterns) {
                    res.data.shareHoldingPatterns.forEach(element => {
                        if (element.date) {
                            element.date = dayjs(element.date, "YYYY-MM-DD");
                        }
                    });
                }
                setFormData(res.data)
            }

        })
    }
    const handleDetailsCertificate = (e) => {
        handleFileUpload(e, "nonResidentShareholderDoc", "nonResidentShareholderDocName", "nonResidentShareHolderDocURL", 'file');
    }
    const handleMoaDocName = (e) => {
        handleFileUpload(e, "moaDoc", "moaDocName", "moaDocURL", 'file');
    }
    const handleAoaDoc = (e) => {
        handleFileUpload(e, "aoaDoc", "aoaDocName", "aoaDocURL", 'file');
    }
    const handleTechnologyUsageFeesDoc = (e) => {
        handleFileUpload(e, "technologyUsageFeesDoc", "technologyUsageFeesDocName", "technologyUsageFeesDocURL", 'file');
    }
    const handleEmployeeStockOptionPlanDoc = (e) => {
        handleFileUpload(e, "employeeStockOptionPlanDoc", "employeeStockOptionPlanDocName", "employeeStockOptionPlanDocURL", 'file');
    }
    const handleShareholderAgreementsDoc = (e) => {
        handleFileUpload(e, "shareholderAgreementsDoc", "shareholderAgreementsDocName", "shareholderAgreementsDocURL", 'file');
    }
    const handleEmployeeAgreementDoc = (e) => {
        handleFileUpload(e, "employmentAgreeDoc", "employmentAgreeDocName", "employmentAgreeDocURL", 'file');
    }
    const handleFinancialStatementDoc = (e) => {
        handleFileUpload(e, "financialStatementDoc", "financialStatementDocName", "financialStatementDocURL", 'file');
    }
    const handleInsurancePolicyDoc = (e) => {
        handleFileUpload(e, "insurancePolicyDoc", "insurancePolicyDocName", "insurancePolicyDocURL", 'file');
    }

    const handleFileUpload = (event, filekey, fileName, fileURL, format) => {
        let logoselecetdFile;
        if (event !== null) {
            if (event.target === undefined) {
                logoselecetdFile = event
            } else {
                logoselecetdFile = event.target.files[0]
            }
            if (logoselecetdFile) {
                const fileSizeLimit = 5 * 1024 * 1024;
                if (logoselecetdFile.size > fileSizeLimit) {
                    notifyService(
                        "danger",
                        "File Size Exceeded",
                        "Please upload a file smaller than 5 MB."
                    );
                    return;
                }
                var reader = new FileReader();
                var imagetype = logoselecetdFile.type
                var imagedatatype = imagetype.split("/")
                var img_crt_type = imagedatatype[1]

                if (
                    ((format === 'file') && (img_crt_type === "pdf") || (img_crt_type === "vnd.ms-excel") || (img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet") ||

                        (img_crt_type === "vnd.openxmlformats-officedocument.wordprocessingml.document"))) {
                    setValue(filekey, "")
                    setValue(fileName, "")

                    var fileValue = logoselecetdFile
                    reader.readAsDataURL(logoselecetdFile);
                    reader.onload = () => {
                        var logourl1 = reader.result;
                        var spl = logourl1.split(',')
                        var ImageValue = spl[1]
                        var img_name = fileValue.name
                        setValue(filekey, ImageValue)
                        setValue(fileName, img_name)
                        setValue(fileURL, logourl1)
                        clearErrors(filekey);
                        clearErrors(fileName);
                        clearErrors(fileURL);
                        var req = {
                            [filekey]: ImageValue,
                            [fileName]: img_name,
                            id: fileUploadId
                        }
                        console.log(req);

                        saveData(req)

                    }
                } else {
                    notifyService('danger', 'File Format Invalided', 'Please check your file format')
                }
            }
        }
    }
    const saveData = (req) => {
        setPageLoading(true)
        apiService('startup/ldd/sectionone/save', req, 'post').then((res) => {
            setPageLoading(false);
            if (res && res.data) {
                if (res.data.responseStatus === "Success") {
                    getApi()
                }
            }
        })
    }

    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const handleClickOpen = (value) => {
        if (value) {
            setViewImage(value);
            setOpen(true);
        }
    };
    // const handleClickOpens = (url) => {
    //     if (url) {
    //         
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.download = 'filename.xlsx';
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    //     }

    // };
    const handleClickOpens = (url) => {
        if (url) {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.rel = 'noopener noreferrer';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };


    const handleClose = () => {
        setOpen(false);
    };
    const onPdfClick = () => {
        const pdfUrl = "https://startuptn-public-files.s3.ap-south-1.amazonaws.com/Business_Plan_Template.xlsx";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Business_Plan_Template.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    // const onSubmit = (data) => {
    //     
    //     setPageLoading(true);
    //     if (data.shareHoldingPatterns) {
    //         data.shareHoldingPatterns.forEach(element => {
    //             if (element.date) {
    //                 element.date = element.date.format('YYYY-MM-DD');
    //             }
    //         });
    //     }
    //     var req = {
    //         id: fileUploadId,
    //         directorsInterestedCompanies: data.directorsInterestedCompanies,
    //         shareHoldingPatterns: data.shareHoldingPatterns,
    //         sharesSubjectedToPledge: data.sharesSubjectedToPledge
    //     }
    //     console.log(req);
    //     apiService('startup/ldd/sectionone/save', req, 'post').then((res) => {
    //         setPageLoading(false);
    //         if (res && res.data) {
    //             if (res.data.responseStatus === "Success") {
    //                 notifyService('success', 'Success', 'Saved successfully !!')
    //                 handleBackapp()
    //             }
    //         }
    //     })
    // }
    const onSubmit = (data) => {
        setPageLoading(true);
        apiService('startup/ldd/sectionone/save', data, 'post').then((res) => {
            setPageLoading(false);
            if (res && res.data) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Saved successfully !!')
                    handleBackapp()
                }
            }
        })
    }
    const onError = (error) => {
        console.log(error);
        notifyService("danger", "Error", "Please check mandatory fields");
    };
    const [residentShareholder, setResidentShareholder] = useState();
    const Shareholders = (val) => {
        setResidentShareholder(val)
        var req = {
            nonResidentShareholder: val,
            id: fileUploadId
        }
        saveData(req)
    }
    const [technology, setTechnology] = useState();
    const Technology = (val) => {
        setTechnology(val)
        var req = {
            technologyUsageFees: val,
            id: fileUploadId
        }
        saveData(req)
    }
    const [compliances, setCompliances] = useState();
    const FEMA = (val) => {
        setCompliances(val)
        var req = {
            femaCompliance: val,
            id: fileUploadId
        }
        saveData(req)
    }


    const [licensedSoftware, setLicensedSoftware] = useState();
    const LicensedCheck = (val) => {
        setLicensedSoftware(val)
        var req = {
            licensedSoftware: val,
            id: fileUploadId
        }
        saveData(req)
    }
    const [isAccordionOpen1, setIsAccordionOpen1] = useState(true);
    const [isAccordionOpen2, setIsAccordionOpen2] = useState(true);
    const [isAccordionOpen3, setIsAccordionOpen3] = useState(true);
    const [isAccordionOpen4, setIsAccordionOpen4] = useState(true);
    const [isAccordionOpen5, setIsAccordionOpen5] = useState(true);
    const [isAccordionOpen6, setIsAccordionOpen6] = useState(true);
    const [isAccordionOpen7, setIsAccordionOpen7] = useState(true);
    const [isAccordionOpen8, setIsAccordionOpen8] = useState(true);
    const [isAccordionOpen9, setIsAccordionOpen9] = useState(true);
    const {
        fields: directorsFields,
        append: directorsAppend,
        remove: directorsRemove
    } = useFieldArray({
        control,
        name: "branches"
    });
    const adddirectorsFieldsBtn = () => {
        directorsAppend({
            id: 0,
            address: "",
            state: "",
            district: "",
            pincode: "",

        })
    }
    const deleteDirectorsInterested = async (index) => {

        const id = getValues(`branches.${index}.id`);

        if (id !== 0 && id !== undefined) {
            apiService(`startup/ldd/branches/remove?id=${id}`, '', 'delete').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        indexBaseddeleteDirectorsInterested(index)
                    }
                }
            })
        } else {
            indexBaseddeleteDirectorsInterested(index)
        }
    }
    const indexBaseddeleteDirectorsInterested = (index) => {
        var startupTeams = getValues('branches')
        if (startupTeams.length === 0 && index === 0) {

            var val = getValues()
            val.branches = [{
                id: 0,
                address: "",
                state: "",
                district: "",
                pincode: "",
            }]
            setFormData(val)
        } else {

            directorsRemove(index)
            var val = getValues()
            setFormData(val)
        }
    }
    const {
        fields: companySecretaryFields,
        append: companySecretaryAppend,
        remove: companySecretaryRemove
    } = useFieldArray({
        control,
        name: "materialAgreements"
    });

    const addCompanySecretaryBtn = () => {
        companySecretaryAppend({
            id: 0,
            partyName: "",
            contractType: "",
            valuePerAnnum: "",
            validityPeriod: "",

        })
    }

    const deleteCompanySecretaryBtn = async (index) => {
        const id = getValues(`materialAgreements.${index}.id`);
        if (id !== 0 && id !== undefined) {
            apiService(`startup/ldd/materialAgreements/remove?id=${id}`, '', 'delete').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        indexBaseddeleteCompanySecretary(index)
                    }
                }
            })
        } else {
            indexBaseddeleteCompanySecretary(index)
        }
    }

    const indexBaseddeleteCompanySecretary = (index) => {
        var startupTeams = getValues('materialAgreements')
        if (startupTeams.length === 0 && index === 0) {

            var val = getValues()
            val.materialAgreements = [{
                id: 0,
                partyName: "",
                contractType: "",
                valuePerAnnum: "",
                validityPeriod: "",
            }]
            setFormData(val)
        } else {

            companySecretaryRemove(index)
            var val = getValues()
            setFormData(val)
        }
    }
    const {
        fields: intellectualPropertyFields,
        append: intellectualPropertyAppend,
        remove: intellectualPropertyRemove
    } = useFieldArray({
        control,
        name: "intellectualProperty"
    });
    const addIntellectualBtn = () => {
        intellectualPropertyAppend({
            id: 0,
            property: "",
            details: "",
            status: "",

        })
    }
    const [uploadedPatents, setUploadedPatents] = useState({});

    const deleteIntellectualBtn = async (index) => {
        const id = getValues(`intellectualProperty.${index}.id`);
        if (id !== 0 && id !== undefined) {
            apiService(`startup/ldd/intellectualProperty/remove?id=${id}`, '', 'delete').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        indexBaseddeleteIntellectual(index)
                    }
                }
            })
        } else {
            indexBaseddeleteIntellectual(index)
        }
    }
    const indexBaseddeleteIntellectual = (index) => {
        var startupTeams = getValues('intellectualProperty')
        if (startupTeams.length === 0 && index === 0) {

            var val = getValues()
            val.intellectualProperty = [{
                id: 0,
                property: "",
                details: "",
                status: "",
            }]
            setFormData(val)
        } else {

            intellectualPropertyRemove(index)
            var val = getValues()
            setFormData(val)
        }
    }
    const {
        fields: immovablePropertiesFields,
        append: immovablePropertiesAppend,
        remove: immovablePropertiesRemove
    } = useFieldArray({
        control,
        name: "immovableProperty"
    });
    const addImmovableBtn = () => {
        immovablePropertiesAppend({
            id: 0,
            address: "",
            lessor: "",
            lease: "",
            leaseTerm: "",

        })
    }
    const deleteImmovableBtn = async (index) => {
        const id = getValues(`immovableProperty.${index}.id`);
        if (id !== 0 && id !== undefined) {
            apiService(`startup/ldd/immovableProperty/remove?id=${id}`, '', 'delete').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        indexBaseddeleteImmovable(index)
                    }
                }
            })
        } else {
            indexBaseddeleteImmovable(index)
        }
    }
    const indexBaseddeleteImmovable = (index) => {
        var startupTeams = getValues('immovableProperty')
        if (startupTeams.length === 0 && index === 0) {

            var val = getValues()
            val.immovableProperty = [{
                id: 0,
                address: "",
                lessor: "",
                lease: "",
                leaseTerm: "",
            }]
            setFormData(val)
        } else {

            immovablePropertiesRemove(index)
            var val = getValues()
            setFormData(val)
        }
    }
    const {
        fields: complaintsAndLitigationFields,
        append: complaintsAndLitigationAppend,
        remove: complaintsAndLitigationRemove
    } = useFieldArray({
        control,
        name: "complaintsAndLitigation"
    });
    const addcomplaintsAndLitigation = () => {
        complaintsAndLitigationAppend({
            id: 0,
            litigationNature: "",
            party: "",
            indicativeAmount: "",

        })
    }
    const deletecomplaintsAndLitigationBtn = async (index) => {
        const id = getValues(`complaintsAndLitigation.${index}.id`);
        if (id !== 0 && id !== undefined) {
            apiService(`startup/ldd/complaintsAndLitigation/remove?id=${id}`, '', 'delete').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        indexBaseddeleteComplaintsAndLitigation(index)
                    }
                }
            })
        } else {
            indexBaseddeleteComplaintsAndLitigation(index)
        }
    }
    const indexBaseddeleteComplaintsAndLitigation = (index) => {
        var startupTeams = getValues('complaintsAndLitigation')
        if (startupTeams.length === 0 && index === 0) {

            var val = getValues()
            val.complaintsAndLitigation = [{
                id: 0,
                litigationNature: "",
                party: "",
                indicativeAmount: "",
            }]
            setFormData(val)
        } else {

            complaintsAndLitigationRemove(index)
            var val = getValues()
            setFormData(val)
        }
    }
    const {
        fields: startupTeamsFields,
        append: startupTeamsAppend,
        remove: startupTeamsRemove
    } = useFieldArray({
        control,
        name: "startupTeams"
    });
    const addStartupTeamDetails = () => {
        startupTeamsAppend({
            id: 0,
            type: "",
            coveredUnderPF: "",

        })
    }
    const deleteStartupTeamDetailsBtn = async (index) => {
        const id = getValues(`startupTeams.${index}.id`);
        if (id !== 0 && id !== undefined) {
            apiService(`startup/vendor/remove?id=${id}`, '', 'delete').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        indexBaseddeleteStartupTeamDetails(index)
                    }
                }
            })
        } else {
            indexBaseddeleteStartupTeamDetails(index)
        }
    }
    const indexBaseddeleteStartupTeamDetails = (index) => {
        var startupTeams = getValues('startupTeams')
        if (startupTeams.length === 0 && index === 0) {

            var val = getValues()
            val.startupTeams = [{
                id: 0,
                type: "",
                coveredUnderPF: "",
            }]
            setFormData(val)
        } else {

            startupTeamsRemove(index)
            var val = getValues()
            setFormData(val)
        }
    }
    const {
        fields: startupLabourLawFields,
        append: startupLabourLawAppend,
        remove: startupLabourLawRemove
    } = useFieldArray({
        control,
        name: "startupLabourLaw"
    });
    const addStartupLabourLaw = () => {
        startupLabourLawAppend({
            id: 0,
            act: "",
            obtained: "",
            validity: "",
            status: "",

        })
    }
    const deleteStartupLabourLawBtn = async (index) => {
        const id = getValues(`startupLabourLaw.${index}.id`);
        if (id !== 0 && id !== undefined) {
            apiService(`startup/ldd/startupLabourLaw/remove?id=${id}`, '', 'delete').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        indexBaseddeleteStartupLabourLaw(index)
                    }
                }
            })
        } else {
            indexBaseddeleteStartupLabourLaw(index)
        }
    }
    const indexBaseddeleteStartupLabourLaw = (index) => {
        var startupTeams = getValues('startupLabourLaw')
        if (startupTeams.length === 0 && index === 0) {

            var val = getValues()
            val.startupLabourLaw = [{
                id: 0,
                act: "",
                obtained: "",
                validity: "",
                status: "",
            }]
            setFormData(val)
        } else {

            startupLabourLawRemove(index)
            var val = getValues()
            setFormData(val)
        }
    }
    const {
        fields: startupPolicyFields,
        append: startupPolicyAppend,
        remove: startupPolicyRemove
    } = useFieldArray({
        control,
        name: "startupPolicy"
    });
    const addStartupPolicy = () => {
        startupPolicyAppend({
            id: 0,
            name: "",
            status: "",

        })
    }

    const deleteStartupPolicyBtn = async (index) => {
        const id = getValues(`startupPolicy.${index}.id`);
        if (id !== 0 && id !== undefined) {
            apiService(`startup/ldd/startupPolicy/remove?id=${id}`, '', 'delete').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        indexBaseddeleteStartupPolicy(index)
                    }
                }
            })
        } else {
            indexBaseddeleteStartupPolicy(index)
        }
    }
    const indexBaseddeleteStartupPolicy = (index) => {
        var startupTeams = getValues('startupPolicy')
        if (startupTeams.length === 0 && index === 0) {

            var val = getValues()
            val.startupPolicy = [{
                id: 0,
                name: "",
                status: "",
            }]
            setFormData(val)
        } else {

            startupPolicyRemove(index)
            var val = getValues()
            setFormData(val)
        }
    }
    const {
        fields: patentsFields,
        append: patentsAppend,
        remove: patentsRemove
    } = useFieldArray({
        control,
        name: "patents"
    })
    const addPatentsDetailsBtn = () => {
        patentsAppend({
            id: 0,
            name: "",
            stage: "",
            number: "",
            doc: "",
            docname: ""
        })
    }
    const deletePatentsDetails = async (index) => {
        const id = getValues(`patents.${index}.id`);
        if (id !== 0 && id !== undefined) {
            apiService(`startup/patent/remove?id=${id}`, '', 'delete').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        indexBaseddeletepatentsRemove(index)
                    }
                }
            })
        } else {
            indexBaseddeletepatentsRemove(index)
        }
    }
    const indexBaseddeletepatentsRemove = (index) => {
        var startupTeams = getValues('patents')
        if (startupTeams.length === 0 && index === 0) {

            var val = getValues()
            val.patents = [{
                id: 0,
                name: "",
                stage: "",
                number: "",
                doc: "",
                docname: ""
            }]
            setFormData(val)
        } else {

            patentsRemove(index)
            var val = getValues()
            setFormData(val)
        }
    }
    const [selectedType, setSelectedType] = useState('');

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedType(value);
    };
    const [stateList, setStateList] = useState([]);
    const getStateApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=state`, "", "get").then(
            (res) => {
                if (res && res.data && res.data.dropDownValues) {
                    var val = res.data.dropDownValues;
                    setStateList(val.districts);
                }

            }
        );
    };
    const [cityList, setCityList] = useState([]);
    const getCityApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=district`, "", "get").then(
            (res) => {
                if (res && res.data && res.data.dropDownValues) {
                    var val = res.data.dropDownValues;
                    setCityList(val.districts);
                }
            }
        );
    };
    const handleSelectStateType = (value, index) => {

        setValue(`branches.${index}.state`, value)
        clearErrors(`branches.${index}.state`);
        setValue(`branches.${index}.district`, '');
    }
    const handleSelectCity = (value, index) => {
        setValue(`branches.${index}.district`, value)
        clearErrors(`branches.${index}.district`);
    };
    const [valuePincode, setValuePincode] = useState('');
    const validatePincode = (value) => {
        if (value.length) {
            if (value.length !== 6) {
                return 'Pincode must be 6 digits';
            }
            const matches = value.match(
                /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
            );
            if (matches === null) {
                return "Only numbers allowed";
            }
        }
    };
    const chartList = ["Full time Employees", "Contract employees (engaged through another entity)", "Consultants (where professional fees are paid)"]
    const handleChartList = (value, index) => {

        setValue(`startupTeams.${index}.type`, value)
        clearErrors(`startupTeams.${index}.type`);
    }
    const actList = ["PF", "ESI", "Shops & Establishments Act", "Professional Tax", "Contract Labour Regulation Act", "Labour Welfare Fund", "Trade License", "MSME", "Start Up India"]
    const handleActList = (value, index) => {

        setValue(`startupLabourLaw.${index}.act`, value)
        clearErrors(`startupLabourLaw.${index}.act`);
    }
    const propertyList = ["Domain Name", "Any Other IP",]
    const handlepropertyList = (value, index) => {
        setValue(`intellectualProperty.${index}.property`, value)
        clearErrors(`intellectualProperty.${index}.property`);
    }
    const nameList = ["Website Privacy Policy", "Website Terms of Use", "POSH Policy employees"]
    const handleNameList = (value, index) => {

        setValue(`startupPolicy.${index}.name`, value)
        clearErrors(`startupPolicy.${index}.name`);
    }
    return (
        <>
            <section className='container-fluid'>
                <div className='form_div'>
                    <div className="d-flex align-items-center">
                        <span className="back_span" onClick={handleBackapp}>
                            <img src='/images/Fig-back.png' className='img-fluid' alt='' />
                        </span>
                        <h6 className="ml-2 mb-0">Information from the Company:</h6>
                    </div>
                    <hr />
                    {/* <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className='form_div2'>
                            <div className="row doc-row">
                                <div className='col-12'>
                                    <div className='doc-title mt-3'>
                                        <h6>Corporate Records :</h6>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>All statutory registers maintained by the Company *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="statutoryRegistersDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "statutoryRegistersDoc",
                                                            "statutoryRegistersDocName",
                                                            "statutoryRegistersDocURL",
                                                            'file')}
                                                        value={getValues('statutoryRegistersDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('statutoryRegistersDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('statutoryRegistersDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('statutoryRegistersDoc') !== '' ? 'Uploaded (Click to Change)' : 'Statutory Registers Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.statutoryRegistersDoc && errors.statutoryRegistersDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('statutoryRegistersDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Copies of all forms filed with RoC – both event based as well as periodical for the past 3 financial years (including current FY) *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="rocDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "rocDoc",
                                                            "rocDocName",
                                                            "rocDocURL",
                                                            'file')}
                                                        value={getValues('rocDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('rocDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('rocDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('rocDoc') !== '' ? 'Uploaded (Click to Change)' : 'RoC Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.rocDoc && errors.rocDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('rocDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Copies of minutes of Board & Shareholders meetings for the past 3 financial years (including current FY) *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="shareholderMinutesDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "shareholderMinutesDoc",
                                                            "shareholderMinutesDocName",
                                                            "shareholderMinutesDocURL",
                                                            'file')}
                                                        value={getValues('shareholderMinutesDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('shareholderMinutesDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('shareholderMinutesDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('shareholderMinutesDoc') !== '' ? 'Uploaded (Click to Change)' : 'Share Holder Minutes Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.shareholderMinutesDoc && errors.shareholderMinutesDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('shareholderMinutesDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row doc-row">
                                <div className='col-12'>
                                    <div className='doc-title mt-3'>
                                        <h6>Shareholders :</h6>
                                    </div>
                                </div>                                
                                <div className='col-lg-6 col-12 mt-2'>
                                    <FormLabel>Details of any shareholder’s agreement or arrangements or understandings (whether legally binding or not) between the Promoters; or the Company and any shareholder of the Company *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="shareHolderAgreementDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "shareHolderAgreementDoc",
                                                            "shareHolderAgreementDocName",
                                                            "shareHolderAgreementDocURL",
                                                            'file')}
                                                        value={getValues('shareHolderAgreementDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('shareHolderAgreementDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('shareHolderAgreementDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('shareHolderAgreementDoc') !== '' ? 'Uploaded (Click to Change)' : 'Share Holder Agreement Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.shareHolderAgreementDoc && errors.shareHolderAgreementDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('shareHolderAgreementDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12 mt-2'>
                                    <FormLabel>Copies of Share Certificates, Share Transfer Forms along with details of stamp duty paid on issue / transfer of shares. *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="shareCertfDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "shareCertfDoc",
                                                            "shareCertfDocName",
                                                            "shareCertfDocURL",
                                                            'file')}
                                                        value={getValues('shareCertfDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('shareCertfDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('shareCertfDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('shareCertfDoc') !== '' ? 'Uploaded (Click to Change)' : 'Share Certificates Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.shareCertfDoc && errors.shareCertfDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('shareCertfDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row doc-row">
                                <div className='col-12'>
                                    <div className='doc-title mt-3'>
                                        <h6>Directors :</h6>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12 mt-2'>
                                    <FormLabel>Details of Remuneration drawn by the Directors, if any and date of Board/ Shareholders approval of the same *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="remunerationDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "remunerationDoc",
                                                            "remunerationDocName",
                                                            "remunerationDocURL",
                                                            'file')}
                                                        value={getValues('remunerationDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('remunerationDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('remunerationDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('remunerationDoc') !== '' ? 'Uploaded (Click to Change)' : 'Remuneration Document *'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.remunerationDoc && errors.remunerationDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('remunerationDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12 mt-2'>
                                    <FormLabel>Details of all related party transactions, including payments made to Directors & related entities *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="relatedPartyTransDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "relatedPartyTransDoc",
                                                            "relatedPartyTransDocName",
                                                            "relatedPartyTransDocURL",
                                                            'file')}
                                                        value={getValues('relatedPartyTransDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('relatedPartyTransDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('relatedPartyTransDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('rocDoc') !== '' ? 'Uploaded (Click to Change)' : 'Related Party Trans Document *'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.relatedPartyTransDoc && errors.relatedPartyTransDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('relatedPartyTransDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="row doc-row">
                                <div className='col-12'>
                                    <div className='doc-title mt-3'>
                                        <h6>Share Capital :</h6>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12 mt-2'>
                                    <FormLabel>Details of authorised share capital *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="authorizedSharedCaptialDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>
                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "authorizedSharedCaptialDoc",
                                                            "authorizedSharedCaptialDocName",
                                                            "authorizedSharedCaptialDocURL",
                                                            'file')}
                                                        value={getValues('authorizedSharedCaptialDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('authorizedSharedCaptialDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('authorizedSharedCaptialDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('authorizedSharedCaptialDoc') !== '' ? 'Uploaded (Click to Change)' : 'Authorized Shared Captial Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.authorizedSharedCaptialDoc && errors.authorizedSharedCaptialDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('authorizedSharedCaptialDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12 mt-2'>
                                    <FormLabel>Details of issued, subscribed and paid up share capital *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="issuredCapitalDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "issuredCapitalDoc",
                                                            "issuredCapitalDocName",
                                                            "issuredCapitalDocURL",
                                                            'file')}
                                                        value={getValues('issuredCapitalDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('issuredCapitalDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('issuredCapitalDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('issuredCapitalDoc') !== '' ? 'Uploaded (Click to Change)' : 'Issured Capital Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.issuredCapitalDoc && errors.issuredCapitalDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('issuredCapitalDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12 mt-2'>
                                    <FormLabel>Details of Employee Stock Option Plan or Sweat Equity Shares issued or  proposed to be issued by the Company *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="employeeStockOptionDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "employeeStockOptionDoc",
                                                            "employeeStockOptionDocName",
                                                            "employeeStockOptionDocURL",
                                                            'file')}
                                                        value={getValues('employeeStockOptionDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('employeeStockOptionDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('employeeStockOptionDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('employeeStockOptionDoc') !== '' ? 'Uploaded (Click to Change)' : 'Employee Stock Option Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.employeeStockOptionDoc && errors.employeeStockOptionDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('employeeStockOptionDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12 mt-2'>
                                    <FormLabel>Details of Foreign Direct Investments received / Overseas Investments made and their compliance with regard to Foreign Exchange Management Act / Reserve Bank of India regulations / notifications *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="foreignDirectInvestmentDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "foreignDirectInvestmentDoc",
                                                            "foreignDirectInvestmentDocName",
                                                            "foreignDirectInvestmentDocURL",
                                                            'file')}
                                                        value={getValues('foreignDirectInvestmentDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('foreignDirectInvestmentDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('foreignDirectInvestmentDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('foreignDirectInvestmentDoc') !== '' ? 'Uploaded (Click to Change)' : 'Foreign Direct Investment Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.foreignDirectInvestmentDoc && errors.foreignDirectInvestmentDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('foreignDirectInvestmentDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <PreliminaryTextPart1 {...{ control, getValues, errors, setValue, clearErrors }} setFormData={setFormData} formData={formData} readyOnlyValue={readyOnlyValue} />
                        <div className="form-group d-flex justify-content-end mt-3 mr-2">
                            <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
                            {
                                (readyOnlyValue === false) ? (
                                    <Button
                                        type="submit"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        className="btn btn-primary submit px-3 ml-3"
                                    >
                                        <span>Save & Proceed Next</span>
                                    </Button>
                                ) : (<></>)
                            }

                        </div>
                    </form> */}
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>

                        <div className="row doc-row">
                            <div className='col-lg-7 col-12 mt-2'>
                                <FormLabel>Details of the Company – Address of the Registered office of the Company and other places (branch offices) from where the Company is carrying on its activities</FormLabel>

                                <div className="form-group mt-2">
                                    <Controller name="mainAddress" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Main Address"
                                                placeholder='Enter Main Address'
                                                fullWidth
                                                multiline
                                                rows={3}
                                                inputProps={
                                                    { readOnly: nonEditAble, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.mainAddress && errors.mainAddress.message}</FormHelperText>
                                </div>

                            </div>
                            <div className="col-lg-12 col-12 mb-3 pl-3">
                                <Accordion className='Addfundacc-bor' expanded={isAccordionOpen1} onChange={() => setIsAccordionOpen1(!isAccordionOpen1)}>
                                    <AccordionSummary
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className='Addfundacc'
                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <Typography sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            margin: 0
                                        }}>
                                            <AddCircleIcon className='add-icon mr-3' />Branch
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <div className='col-12 mb-3'>
                                                <div>
                                                    {
                                                        (directorsFields.length >= 0) && (
                                                            <TableContainer component={Paper} className='table-back'>
                                                                <Table aria-label="caption table">
                                                                    <TableBody >
                                                                        {directorsFields.map((field, index) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>{index + 1}</TableCell>
                                                                                <TableCell sx={{ width: '30%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller name={`branches.${index}.address`} control={control} defaultValue="" rules={{ required: 'Address is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="Address *"
                                                                                                    placeholder='Enter address'
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    inputProps={
                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                    }
                                                                                                />} />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.branches?.[index]?.address && errors.branches?.[index]?.address.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>



                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>

                                                                                    <FormControl sx={{ width: "100%" }}>
                                                                                        <Controller
                                                                                            name={`branches.${index}.state`}
                                                                                            control={control}
                                                                                            defaultValue=""
                                                                                            rules={{ required: "State is required" }}
                                                                                            render={({ field }) => (
                                                                                                <Autocomplete
                                                                                                    disableClearable
                                                                                                    disablePortal
                                                                                                    {...field}
                                                                                                    // limitTags={2}
                                                                                                    // value={stateName}
                                                                                                    options={stateList}
                                                                                                    onChange={(e, selectedOptions) => handleSelectStateType(selectedOptions, index)}
                                                                                                    renderInput={(params) => (
                                                                                                        <TextField {...params} label="State *" />
                                                                                                    )}
                                                                                                    disabled={readyOnlyValue}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                        <FormHelperText className="text-danger">
                                                                                            {errors.branches?.[index]?.state && errors.branches?.[index]?.state.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    {getValues(`branches.${index}.state`) === "Tamil Nadu" ? (

                                                                                        <>
                                                                                            <FormControl sx={{ width: "100%" }}>
                                                                                                <Controller
                                                                                                    name={`branches.${index}.district`}
                                                                                                    control={control}
                                                                                                    defaultValue=""
                                                                                                    rules={{ required: "District is required" }}
                                                                                                    render={({ field }) => (
                                                                                                        <Autocomplete
                                                                                                            disableClearable
                                                                                                            disablePortal
                                                                                                            {...field}
                                                                                                            // limitTags={2}
                                                                                                            options={cityList}
                                                                                                            onChange={(e, selectedOptions) =>
                                                                                                                handleSelectCity(selectedOptions, index)
                                                                                                            }
                                                                                                            renderInput={(params) => (
                                                                                                                <TextField {...params} label="District *" />
                                                                                                            )}
                                                                                                            disabled={readyOnlyValue}
                                                                                                        />
                                                                                                    )}
                                                                                                />
                                                                                                <FormHelperText className="text-danger">
                                                                                                    {errors.branches?.[index]?.district && errors.branches?.[index]?.district.message}
                                                                                                </FormHelperText>
                                                                                            </FormControl>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <FormControl sx={{ width: "100%" }}>
                                                                                                <Controller
                                                                                                    name={`branches.${index}.district`}
                                                                                                    control={control}
                                                                                                    defaultValue=""
                                                                                                    rules={{ required: "District is required" }}
                                                                                                    render={({ field }) => (
                                                                                                        <TextField
                                                                                                            {...field}
                                                                                                            variant="outlined"
                                                                                                            label="District *"
                                                                                                            placeholder="Enter District"
                                                                                                            fullWidth
                                                                                                            inputProps={{ readOnly: readyOnlyValue }}
                                                                                                        />
                                                                                                    )}
                                                                                                />
                                                                                                <FormHelperText className="text-danger">
                                                                                                    {errors.branches?.[index]?.district && errors.branches?.[index]?.district.message}
                                                                                                </FormHelperText>
                                                                                            </FormControl>
                                                                                        </>
                                                                                    )}
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: "100%" }}>
                                                                                        <Controller
                                                                                            name={`branches.${index}.pincode`}
                                                                                            control={control}
                                                                                            defaultValue=""
                                                                                            rules={{ required: "Pincode is required", validate: validatePincode }}
                                                                                            render={({ field }) => (
                                                                                                <TextField
                                                                                                    variant="outlined"
                                                                                                    label="Pincode *"
                                                                                                    placeholder="Enter Pincode"
                                                                                                    fullWidth
                                                                                                    multiline
                                                                                                    inputProps={{ readOnly: readyOnlyValue, maxLength: 6 }}
                                                                                                    {...field}
                                                                                                    onKeyDown={(e) => validateNumberonly(e)}
                                                                                                // value={valuePincode}
                                                                                                // onInput={(e) => {
                                                                                                //     const { value } = e.target;
                                                                                                //     if (value === "" || (/^\d{1,6}$/.test(value))) {
                                                                                                //         setValuePincode(value);
                                                                                                //     }
                                                                                                // }}

                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                        <FormHelperText className="text-danger">
                                                                                            {errors.branches?.[index]?.pincode && errors.branches?.[index]?.pincode.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center'>
                                                                                    <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteDirectorsInterested(index) : <></> }} />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                                <center>
                                                                    <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => adddirectorsFieldsBtn('')}><AddCircleIcon className='mr-2' />Add More</Button>
                                                                </center>
                                                            </TableContainer>
                                                            //     ) : (
                                                            // <div className='nodata_tag'>
                                                            //     <p>Fundraising history details is empty</p>
                                                            // </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className='col-lg-4 col-12 mt-2'>

                                <div className="form-group mt-2">
                                    <Controller name="incorporatedDate" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Date of incorporation"
                                                placeholder='Enter Date of incorporation'
                                                fullWidth
                                                {...field}
                                                disabled={nonEditAble}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.incorporatedDate && errors.incorporatedDate.message}</FormHelperText>
                                </div>

                            </div>
                            <div className='col-lg-4  col-12 mt-2'>
                                <FormLabel>Copy of latest MOA </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="moaDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleMoaDocName}
                                                    value={getValues('moaDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('moaDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('moaDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}
                                                                 style={{
                                                                    backgroundColor: nonEditAble 
                                                                        ? (getValues('moaDoc') !== '' ? 'gray' : 'gray') 
                                                                        : ''
                                                                }}
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('moaDoc') !== '' ? 'Uploaded (Click to Change)' : 'MOA Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={nonEditAble}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.moaDoc && errors.moaDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                        onClick={() => handleClickOpens(getValues('moaDocURL'))}
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-4  col-12 mt-2'>
                                <FormLabel>Copy of latest AOA </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="aoaDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleAoaDoc}
                                                    value={getValues('aoaDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('aoaDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('aoaDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}
                                                                 style={{
                                                                    backgroundColor: nonEditAble 
                                                                        ? (getValues('aoaDoc') !== '' ? 'gray' : 'gray') 
                                                                        : ''
                                                                }}
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('aoaDoc') !== '' ? 'Uploaded (Click to Change)' : 'AOA Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={nonEditAble}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.aoaDoc && errors.aoaDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                        onClick={() => handleClickOpens(getValues('aoaDocURL'))}
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group">
                                    <FormLabel>If there are any “non residents” who are shareholders, please provide the said details including any forms filed with RBI for the same.</FormLabel>
                                    <Controller name="nonResidentShareholder" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <RadioGroup
                                                row
                                                name="position"
                                                {...field}
                                            >
                                                <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { Shareholders(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                                                <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { Shareholders(e.target.value); setValue('nonResidentShareholderDoc', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                                            </RadioGroup>
                                        } />
                                    <FormHelperText className='text-danger'>{errors.nonResidentShareholder && errors.nonResidentShareholder.message}</FormHelperText>
                                </div>
                            </div>
                            {
                                (residentShareholder === 'true' || residentShareholder === true) && (
                                    <>
                                        <div className='col-lg-4  col-12 mt-2'>
                                            <div className="d-flex align-items-center">
                                                <div className='form-group my-1'>
                                                    <Controller name="nonResidentShareholderDoc" control={control} defaultValue="" rules={{ required: false }}
                                                        render={({ field }) =>
                                                            <FileUploader
                                                                {...field}
                                                                handleChange={handleDetailsCertificate}
                                                                value={getValues('nonResidentShareholderDoc')}
                                                                name="file"
                                                                types={fileTypesAll}
                                                                children={
                                                                    <div className={getValues('nonResidentShareholderDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                        <center>
                                                                            <p className={getValues('nonResidentShareholderDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                                <span>
                                                                                    <AttachFileIcon className="file-icon mr-2" />
                                                                                    {getValues('nonResidentShareholderDoc') !== '' ? 'Uploaded (Click to Change)' : 'Non Residents Document'}
                                                                                </span>
                                                                            </p>
                                                                        </center>
                                                                    </div>
                                                                }
                                                                disabled={readyOnlyValue}
                                                            />
                                                        } />
                                                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.nonResidentShareholderDoc && errors.nonResidentShareholderDoc.message}</FormHelperText>
                                                </div>
                                                <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                                    onClick={() => handleClickOpens(getValues('nonResidentShareHolderDocURL'))}
                                                    style={{ color: "#80809e" }}
                                                > Download
                                                </Link>
                                                </span>
                                            </div>
                                        </div>

                                    </>
                                )
                            }
                            <div className='col-lg-4 col-12'>
                                <div className="form-group">
                                    <FormLabel>Are there any FEMA compliances in relation to exports/imports -please confirm</FormLabel>
                                    <Controller name="femaCompliance" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <RadioGroup
                                                row
                                                name="position"
                                                {...field}
                                            >
                                                <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { FEMA(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                                                <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { FEMA(e.target.value); setValue('femaCompliance', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                                            </RadioGroup>
                                        } />
                                    <FormHelperText className='text-danger'>{errors.femaCompliance && errors.femaCompliance.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group">
                                    <FormLabel>Is the Company required to pay royalty / fees to anyone else for use of their IP/technology? Please provide details.</FormLabel>
                                    <Controller name="technologyUsageFees" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <RadioGroup
                                                row
                                                name="position"
                                                {...field}
                                            >
                                                <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { Technology(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                                                <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { Technology(e.target.value); setValue('nonResidentShareholderDoc', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                                            </RadioGroup>
                                        } />
                                    <FormHelperText className='text-danger'>{errors.technologyUsageFees && errors.technologyUsageFees.message}</FormHelperText>
                                </div>
                            </div>
                            {
                                (technology === 'true' || technology === true) && (
                                    <>
                                        <div className='col-lg-4  col-12 mt-2'>
                                            <div className="d-flex align-items-center">
                                                <div className='form-group my-1'>
                                                    <Controller name="technologyUsageFeesDoc" control={control} defaultValue="" rules={{ required: false }}
                                                        render={({ field }) =>
                                                            <FileUploader
                                                                {...field}
                                                                handleChange={handleTechnologyUsageFeesDoc}
                                                                value={getValues('technologyUsageFeesDoc')}
                                                                name="file"
                                                                types={fileTypesAll}
                                                                children={
                                                                    <div className={getValues('technologyUsageFeesDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                        <center>
                                                                            <p className={getValues('technologyUsageFeesDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                                <span>
                                                                                    <AttachFileIcon className="file-icon mr-2" />
                                                                                    {getValues('technologyUsageFeesDoc') !== '' ? 'Uploaded (Click to Change)' : 'Required Document'}
                                                                                </span>
                                                                            </p>
                                                                        </center>
                                                                    </div>
                                                                }
                                                                disabled={readyOnlyValue}
                                                            />
                                                        } />
                                                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.technologyUsageFeesDoc && errors.technologyUsageFeesDoc.message}</FormHelperText>
                                                </div>
                                                <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                                    onClick={() => handleClickOpens(getValues('technologyUsageFeesDocURL'))}
                                                    style={{ color: "#80809e" }}
                                                > Download
                                                </Link>
                                                </span>
                                            </div>
                                        </div>

                                    </>
                                )
                            }
                            <div className='col-lg-4  col-12 mt-2'>
                                <FormLabel>Details of Employee Stock Option Plan or Sweat Equity Shares issued or  proposed to be issued by the Company</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="employeeStockOptionPlanDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleEmployeeStockOptionPlanDoc}
                                                    value={getValues('employeeStockOptionPlanDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('employeeStockOptionPlanDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('employeeStockOptionPlanDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('employeeStockOptionPlanDoc') !== '' ? 'Uploaded (Click to Change)' : 'Proposed Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.employeeStockOptionPlanDoc && errors.employeeStockOptionPlanDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                        onClick={() => handleClickOpens(getValues('employeeStockOptionPlanDocURL'))}
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-4  col-12 mt-2'>
                                <FormLabel>Details of any inter-se shareholder’s agreement or arrangements or understandings between the Promoters; or the Company and any shareholder of the Company</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="shareholderAgreementsDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleShareholderAgreementsDoc}
                                                    value={getValues('shareholderAgreementsDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('shareholderAgreementsDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('shareholderAgreementsDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('shareholderAgreementsDoc') !== '' ? 'Uploaded (Click to Change)' : 'Shareholder Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.shareholderAgreementsDoc && errors.shareholderAgreementsDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                        onClick={() => handleClickOpens(getValues('shareholderAgreementsDocURL'))}
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            {/* <div className='col-lg-4  col-12 mt-2'>
                                <FormLabel>Whether there is an Employment Agreement with the Directors and if so please provide a copy of the same</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="employmentAgreeDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleEmployeeAgreementDoc}
                                                    value={getValues('employmentAgreeDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('employmentAgreeDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('employmentAgreeDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('employmentAgreeDoc') !== '' ? 'Uploaded (Click to Change)' : 'Employment Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={nonEditAble}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.employmentAgreeDoc && errors.employmentAgreeDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                        onClick={() => handleClickOpens(getValues('employmentAgreeDocURL'))}
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div> */}
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>Whether there is an Employment Agreement with the Directors and if so please provide a copy of the same</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller
                                            name="employmentAgreeDoc"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: false }}
                                            render={({ field }) =>
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleEmployeeAgreementDoc}
                                                    value={getValues('employmentAgreeDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('employmentAgreeDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}
                                                            style={{ backgroundColor: nonEditAble ? '#f0f0f0' : '' }}
                                                            // style={{
                                                            //     backgroundColor: nonEditAble 
                                                            //         ? (getValues('employmentAgreeDoc') !== '' ? 'green' : 'red') 
                                                            //         : ''
                                                            // }}
                                                        >
                                                            <center>
                                                                <p className={getValues('employmentAgreeDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}
                                                                    // style={{ color: nonEditAble ? '#a9a9a9' : '' }}
                                                                    style={{
                                                                        backgroundColor: nonEditAble 
                                                                            ? (getValues('employmentAgreeDoc') !== '' ? 'gray' : 'gray') 
                                                                            : ''
                                                                    }}
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('employmentAgreeDoc') !== '' ? 'Uploaded (Click to Change)' : 'Employment Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={nonEditAble}
                                                />
                                            }
                                        />
                                        <FormHelperText className="ml-2" style={{ color: nonEditAble ? '#a9a9a9' : '' }}>Maximum size: 5 MB. File format .pdf</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>
                                            {errors.employmentAgreeDoc && errors.employmentAgreeDoc.message}
                                        </FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit" style={{ color: nonEditAble ? '#a9a9a9' : '' }}>
                                        <VisibilityIcon className='icon-size mr-1' />
                                        <Link
                                            onClick={() => handleClickOpens(getValues('employmentAgreeDocURL'))}
                                            style={{ color: nonEditAble ? '#a9a9a9' : '#80809e' }}
                                        >
                                            Download
                                        </Link>
                                    </span>
                                </div>
                            </div>

                            <div className='col-lg-4  col-12 mt-2'>
                                <FormLabel>Financial statements of the Company (including balance sheets, profit and loss accounts, cash flow statements etc) for last 3 financial years</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="financialStatementDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleFinancialStatementDoc}
                                                    value={getValues('financialStatementDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('financialStatementDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('financialStatementDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('financialStatementDoc') !== '' ? 'Uploaded (Click to Change)' : 'Financial Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                disabled={nonEditAble}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.financialStatementDoc && errors.financialStatementDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                        onClick={() => handleClickOpens(getValues('financialStatementDocURL'))}
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-4  col-12 mt-2'>
                                <FormLabel>Details of insurance policies obtained by the Company for its business/ assets including any liability related insurance policies</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="insurancePolicyDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleInsurancePolicyDoc}
                                                    value={getValues('insurancePolicyDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('insurancePolicyDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('insurancePolicyDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('insurancePolicyDoc') !== '' ? 'Uploaded (Click to Change)' : 'Insurance Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.insurancePolicyDoc && errors.insurancePolicyDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                        onClick={() => handleClickOpens(getValues('insurancePolicyDocURL'))}
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group">
                                    <FormLabel>Whether all software used by the Company is licensed</FormLabel>
                                    <Controller name="licensedSoftware" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <RadioGroup
                                                row
                                                name="position"
                                                {...field}
                                            >
                                                <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { LicensedCheck(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                                                <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { LicensedCheck(e.target.value); setValue('licensedSoftware', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                                            </RadioGroup>
                                        } />
                                    <FormHelperText className='text-danger'>{errors.licensedSoftware && errors.licensedSoftware.message}</FormHelperText>
                                </div>
                            </div>






                            <div className="col-lg-12 col-12 mb-3 pl-3">
                                <Accordion className='Addfundacc-bor' expanded={isAccordionOpen2} onChange={() => setIsAccordionOpen2(!isAccordionOpen2)}>
                                    <AccordionSummary
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className='Addfundacc'
                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <Typography sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            margin: 0
                                        }}>
                                            <AddCircleIcon className='add-icon mr-3' />Copies of all material Agreements / MoUs / Contracts entered into by the Company with the vendors/ business associates of the Company which are currently in existence
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <div className='col-12 mb-3'>
                                                <div>
                                                    {
                                                        (companySecretaryFields.length >= 0) && (
                                                            <TableContainer component={Paper} className='table-back'>
                                                                <Table aria-label="caption table">
                                                                    <TableBody >
                                                                        {companySecretaryFields.map((field, index) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>{index + 1}</TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller name={`materialAgreements.${index}.partyName`} control={control} defaultValue="" rules={{ required: 'Party Name is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="Party Name *"
                                                                                                    placeholder='Enter Party Name'
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    inputProps={
                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                    }
                                                                                                />} />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.materialAgreements?.[index]?.partyName && errors.materialAgreements?.[index]?.partyName.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller name={`materialAgreements.${index}.contractType`} control={control} defaultValue="" rules={{ required: 'Type of contract is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="Type of contract *"
                                                                                                    placeholder='Enter Type of contract'
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    inputProps={
                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                    }
                                                                                                />} />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.materialAgreements?.[index]?.contractType && errors.materialAgreements?.[index]?.contractType.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller name={`materialAgreements.${index}.valuePerAnnum`} control={control} defaultValue="" rules={{ required: 'Value per Annum is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="Value per Annum *"
                                                                                                    placeholder='Enter Value per Annum'
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    inputProps={
                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                    }
                                                                                                />} />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.materialAgreements?.[index]?.valuePerAnnum && errors.materialAgreements?.[index]?.valuePerAnnum.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller name={`materialAgreements.${index}.validityPeriod`} control={control} defaultValue="" rules={{ required: 'Validity period is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="Validity period *"
                                                                                                    placeholder='Enter Validity period'
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    inputProps={
                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                    }
                                                                                                />} />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.materialAgreements?.[index]?.validityPeriod && errors.materialAgreements?.[index]?.validityPeriod.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>

                                                                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center'>
                                                                                    <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteCompanySecretaryBtn(index) : <></> }} />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                                <center>
                                                                    <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => addCompanySecretaryBtn('')}><AddCircleIcon className='mr-2' />Add More</Button>
                                                                </center>
                                                            </TableContainer>
                                                            //     ) : (
                                                            // <div className='nodata_tag'>
                                                            //     <p>Fundraising history details is empty</p>
                                                            // </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="col-lg-12 col-12 mb-3 pl-3">
                                <Accordion className='Addfundacc-bor' expanded={isAccordionOpen3} onChange={() => setIsAccordionOpen3(!isAccordionOpen3)}>
                                    <AccordionSummary
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className='Addfundacc'
                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <Typography sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            margin: 0
                                        }}>
                                            <AddCircleIcon className='add-icon mr-3' />List of Other Company (including any pending applications)
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <div className='col-12 mb-3'>
                                                <div>
                                                    {
                                                        (intellectualPropertyFields.length >= 0) && (
                                                            <TableContainer component={Paper} className='table-back'>
                                                                <Table aria-label="caption table">
                                                                    <TableBody >
                                                                        {intellectualPropertyFields.map((field, index) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>{index + 1}</TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        {/* <Controller name={`intellectualProperty.${index}.property`} control={control} defaultValue="" rules={{ required: 'Copyright/Trademark is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="Copyright/Trademark *"
                                                                                                    placeholder='Enter Copyright/Trademark'
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    inputProps={
                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                    }
                                                                                                />} /> */}
                                                                                        <Controller
                                                                                            name={`intellectualProperty.${index}.property`}
                                                                                            control={control}
                                                                                            defaultValue=""
                                                                                            rules={{ required: "Property is required" }}
                                                                                            render={({ field }) => (
                                                                                                <Autocomplete
                                                                                                    disableClearable
                                                                                                    disablePortal
                                                                                                    {...field}
                                                                                                    // limitTags={2}
                                                                                                    // value={stateName}
                                                                                                    options={propertyList}
                                                                                                    onChange={(e, selectedOptions) => handlepropertyList(selectedOptions, index)}
                                                                                                    renderInput={(params) => (
                                                                                                        <TextField {...params} label="Property *" />
                                                                                                    )}
                                                                                                    disabled={readyOnlyValue}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.intellectualProperty?.[index]?.property && errors.intellectualProperty?.[index]?.property.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller name={`intellectualProperty.${index}.details`} control={control} defaultValue="" rules={{ required: 'Details is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="Details *"
                                                                                                    placeholder='Enter Details'
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    inputProps={
                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                    }
                                                                                                />} />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.intellectualProperty?.[index]?.details && errors.intellectualProperty?.[index]?.details.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller name={`intellectualProperty.${index}.status`} control={control} defaultValue="" rules={{ required: 'Status is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="Status *"
                                                                                                    placeholder='Enter Status'
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    inputProps={
                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                    }
                                                                                                />} />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.intellectualProperty?.[index]?.status && errors.intellectualProperty?.[index]?.status.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>

                                                                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center'>
                                                                                    <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteIntellectualBtn(index) : <></> }} />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                                <center>
                                                                    <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => addIntellectualBtn('')}><AddCircleIcon className='mr-2' />Add More</Button>
                                                                </center>
                                                            </TableContainer>
                                                            //     ) : (
                                                            // <div className='nodata_tag'>
                                                            //     <p>Fundraising history details is empty</p>
                                                            // </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            {patentsShow === true && (
                                <div className="col-lg-12 col-12 mb-3 pl-3">
                                    <Accordion className='Addfundacc-bor' expanded={isAccordionOpen9} onChange={() => setIsAccordionOpen9(!isAccordionOpen9)}>
                                        <AccordionSummary
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            className='Addfundacc'
                                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        >
                                            <Typography sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                            }}>
                                                <AddCircleIcon className='add-icon mr-3' /> List of trademarks, copyrights, patents, designs
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <div className='col-lg-12 col-12'>
                                                    {
                                                        (patentsFields.length >= 0) && (
                                                            <TableContainer component={Paper} className='table-back' sx={{ overflowX: 'auto', maxWidth: '100%' }}>
                                                                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                                    <TableBody >
                                                                        {patentsFields.map((field, index, type) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell sx={{ borderBottom: 'none' }}>{index + 1}</TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none' }}>
                                                                                    <FormControl sx={{ width: '100%' }}>
                                                                                        <InputLabel id="dropdown-label">Choose *</InputLabel>
                                                                                        <Controller name={`patents.${index}.name`} control={control} defaultValue="" rules={{ required: 'Name is required' }}
                                                                                            render={({ field }) =>
                                                                                                <Select
                                                                                                    {...field}
                                                                                                    variant="outlined"
                                                                                                    onChange={(e) => {
                                                                                                        handleSelectChange(e);
                                                                                                        field.onChange(e);
                                                                                                    }}
                                                                                                    disabled={nonEditAble}
                                                                                                >
                                                                                                    <MenuItem value={'Patent'}>Patent</MenuItem>
                                                                                                    <MenuItem value={'Trademark'}>Trademark</MenuItem>
                                                                                                    <MenuItem value={'Copyright'}>Copyright</MenuItem>
                                                                                                </Select>
                                                                                            } />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.patents?.[index]?.name && errors.patents?.[index]?.name.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none' }} >
                                                                                    <FormControl sx={{ width: '100%' }}>
                                                                                        <InputLabel id="dropdown-label">Stage *</InputLabel>
                                                                                        <Controller name={`patents.${index}.stage`} control={control} defaultValue="" rules={{ required: 'Name is required' }}
                                                                                            render={({ field }) =>
                                                                                                <Select
                                                                                                    {...field}
                                                                                                    variant="outlined"
                                                                                                    disabled={nonEditAble}
                                                                                                >
                                                                                                    <MenuItem value={'0'}>Process of Applying</MenuItem>
                                                                                                    <MenuItem value={'1'}>Applied</MenuItem>
                                                                                                    <MenuItem value={'2'}>Granted</MenuItem>
                                                                                                    <MenuItem value={'3'}>Yes</MenuItem>
                                                                                                </Select>
                                                                                            } />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.patents?.[index]?.stage && errors.patents?.[index]?.stage.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: '25%', borderBottom: 'none' }} >
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller name={`patents.${index}.number`} control={control} defaultValue="" rules={{ required: 'Description is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="Description *"
                                                                                                    placeholder='Enter Description'
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    disabled={nonEditAble}

                                                                                                />} />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.patents?.[index]?.number && errors.patents?.[index]?.number.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: '25%', borderBottom: 'none', padding: '5px' }}>
                                                                                    {
                                                                                        getValues(`patents.${index}.docname`) !== '' && <span>Upload Receipt <span className='text-danger'>*</span></span>
                                                                                    }
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div className='form-group my-1'>
                                                                                            <Controller name={`patents.${index}.doc`} control={control} defaultValue="" rules={{ required: false }}
                                                                                                render={({ field }) =>

                                                                                                    <FileUploader
                                                                                                        {...field}
                                                                                                        handleChange={(e) => handleFileUpload(e,
                                                                                                            `patents.${index}.doc`,
                                                                                                            `patents.${index}.docName`,
                                                                                                            `patents.${index}.docNameURL`,
                                                                                                            'file'
                                                                                                        )}
                                                                                                        value={getValues(`patents.${index}.doc`)}
                                                                                                        name="file"
                                                                                                        types={fileTypes}
                                                                                                        children={

                                                                                                            <div className={getValues(`patents.${index}.doc`) !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                                                                <center>
                                                                                                                    <p className={getValues(`patents.${index}.doc`) !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                                                                        <span>
                                                                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                                                                            {getValues(`patents.${index}.doc`) !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                                                                                                        </span>
                                                                                                                    </p>
                                                                                                                </center>
                                                                                                            </div>
                                                                                                        }
                                                                                                        disabled={nonEditAble}
                                                                                                    />
                                                                                                } />
                                                                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                                                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.patents?.[index]?.doc && errors.patents?.[index]?.doc.message}</FormHelperText>

                                                                                        </div>
                                                                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link
                                                                                            onClick={() => handleClickOpens(getValues(`patents.${index}.docname`))}
                                                                                            style={{ color: "#80809e" }}
                                                                                        > Download
                                                                                        </Link>
                                                                                        </span>
                                                                                    </div>
                                                                                </TableCell>
                                                                                {/* <TableCell sx={{ border: 'none' }} align='center' disabled={nonEditAble}>
                                                                                    <DeleteIcon style={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} />
                                                                                    
                                                                                </TableCell> */}
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                                {/* <center>
                                                                    <Button className='add-more-btn' onClick={() => addPatentsDetailsBtn('')} disabled={nonEditAble}><AddCircleIcon className='mr-2' />Add More</Button>
                                                                </center> */}
                                                            </TableContainer>
                                                        )
                                                    }

                                                </div>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            )}

                            <div className="col-lg-12 col-12 mb-3 pl-3">
                                <Accordion className='Addfundacc-bor' expanded={isAccordionOpen4} onChange={() => setIsAccordionOpen4(!isAccordionOpen4)}>
                                    <AccordionSummary
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className='Addfundacc'
                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <Typography sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            margin: 0
                                        }}>
                                            <AddCircleIcon className='add-icon mr-3' />Details of all immovable properties leased by the Company for its business
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <div className='col-12 mb-3'>
                                                <div>
                                                    {
                                                        (immovablePropertiesFields.length >= 0) && (
                                                            <TableContainer component={Paper} className='table-back'>
                                                                <Table aria-label="caption table">
                                                                    <TableBody >
                                                                        {immovablePropertiesFields.map((field, index) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>{index + 1}</TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller name={`immovableProperty.${index}.address`} control={control} defaultValue="" rules={{ required: 'Address is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="Address *"
                                                                                                    placeholder='Enter Address'
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    inputProps={
                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                    }
                                                                                                />} />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.immovableProperty?.[index]?.address && errors.immovableProperty?.[index]?.address.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller name={`immovableProperty.${index}.lessor`} control={control} defaultValue="" rules={{ required: 'Lessor is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="Lessor *"
                                                                                                    placeholder='Enter Lessor'
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    inputProps={
                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                    }
                                                                                                />} />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.immovableProperty?.[index]?.lessor && errors.immovableProperty?.[index]?.lessor.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller name={`immovableProperty.${index}.lease`} control={control} defaultValue="" rules={{ required: 'Lease rent p.m & Lease Deposit is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="Lease rent p.m & Lease Deposit *"
                                                                                                    placeholder='Enter Lease rent p.m & Lease Deposit'
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    inputProps={
                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                    }
                                                                                                />} />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.immovableProperty?.[index]?.lease && errors.immovableProperty?.[index]?.lease.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller name={`immovableProperty.${index}.leaseTerm`} control={control} defaultValue="" rules={{ required: 'Lease term/duration is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="Lease term/duration *"
                                                                                                    placeholder='Enter Lease term/duration'
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    inputProps={
                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                    }
                                                                                                />} />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.immovableProperty?.[index]?.leaseTerm && errors.immovableProperty?.[index]?.leaseTerm.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center'>
                                                                                    <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteImmovableBtn(index) : <></> }} />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                                <center>
                                                                    <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => addImmovableBtn('')}><AddCircleIcon className='mr-2' />Add More</Button>
                                                                </center>
                                                            </TableContainer>
                                                            //     ) : (
                                                            // <div className='nodata_tag'>
                                                            //     <p>Fundraising history details is empty</p>
                                                            // </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="col-lg-12 col-12 mb-3 pl-3">
                                <Accordion className='Addfundacc-bor' expanded={isAccordionOpen5} onChange={() => setIsAccordionOpen5(!isAccordionOpen5)}>
                                    <AccordionSummary
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className='Addfundacc'
                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <Typography sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            margin: 0
                                        }}>
                                            <AddCircleIcon className='add-icon mr-3' />Details of any complaints and litigation in respect of the Company or its Promoters/Directors
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <div className='col-12 mb-3'>
                                                <div>
                                                    {
                                                        (complaintsAndLitigationFields.length >= 0) && (
                                                            <TableContainer component={Paper} className='table-back'>
                                                                <Table aria-label="caption table">
                                                                    <TableBody >
                                                                        {complaintsAndLitigationFields.map((field, index) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>{index + 1}</TableCell>
                                                                                <TableCell sx={{ width: '30%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller name={`complaintsAndLitigation.${index}.litigationNature`} control={control} defaultValue="" rules={{ required: 'Nature of litigation & Venue/ Forum is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="Nature of litigation & Venue/ Forum *"
                                                                                                    placeholder='Enter Nature of litigation & Venue/ Forum'
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    inputProps={
                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                    }
                                                                                                />} />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.complaintsAndLitigation?.[index]?.litigationNature && errors.complaintsAndLitigation?.[index]?.litigationNature.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller name={`complaintsAndLitigation.${index}.party`} control={control} defaultValue="" rules={{ required: 'Party is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="Party *"
                                                                                                    placeholder='Enter Party'
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    inputProps={
                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                    }
                                                                                                />} />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.complaintsAndLitigation?.[index]?.party && errors.complaintsAndLitigation?.[index]?.party.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller name={`complaintsAndLitigation.${index}.indicativeAmount`} control={control} defaultValue="" rules={{ required: 'Indicative Amount is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="Indicative Amount *"
                                                                                                    placeholder='Enter Indicative Amount'
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    onKeyDown={(e) => validateNumberonly(e)}
                                                                                                    inputProps={
                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                    }
                                                                                                />} />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.complaintsAndLitigation?.[index]?.indicativeAmount && errors.complaintsAndLitigation?.[index]?.indicativeAmount.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center'>
                                                                                    <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deletecomplaintsAndLitigationBtn(index) : <></> }} />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                                <center>
                                                                    <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => addcomplaintsAndLitigation('')}><AddCircleIcon className='mr-2' />Add More</Button>
                                                                </center>
                                                            </TableContainer>
                                                            //     ) : (
                                                            // <div className='nodata_tag'>
                                                            //     <p>Fundraising history details is empty</p>
                                                            // </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="col-lg-12 col-12 mb-3 pl-3">
                                <Accordion className='Addfundacc-bor' expanded={isAccordionOpen6} onChange={() => setIsAccordionOpen6(!isAccordionOpen6)}>
                                    <AccordionSummary
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className='Addfundacc'
                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <Typography sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            margin: 0
                                        }}>
                                            <AddCircleIcon className='add-icon mr-3' />Organizational Chart, Number of employees at each level & other details
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <div className='col-12 mb-3'>
                                                <div>
                                                    {
                                                        (startupTeamsFields.length >= 0) && (
                                                            <TableContainer component={Paper} className='table-back'>
                                                                <Table aria-label="caption table">
                                                                    <TableBody >
                                                                        {startupTeamsFields.map((field, index) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>{index + 1}</TableCell>
                                                                                <TableCell sx={{ width: '30%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller
                                                                                            name={`startupTeams.${index}.type`}
                                                                                            control={control}
                                                                                            defaultValue=""
                                                                                            rules={{ required: false }}
                                                                                            render={({ field }) => (
                                                                                                <Autocomplete
                                                                                                    disableClearable
                                                                                                    disablePortal
                                                                                                    {...field}
                                                                                                    // limitTags={2}
                                                                                                    // value={stateName}
                                                                                                    options={chartList}
                                                                                                    onChange={(e, selectedOptions) => handleChartList(selectedOptions, index)}
                                                                                                    renderInput={(params) => (
                                                                                                        <TextField {...params} label="Type *" />
                                                                                                    )}
                                                                                                    disabled={nonEditAble}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.startupTeams?.[index]?.type && errors.startupTeams?.[index]?.type.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                {/* <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: "100%" }}>
                                                                                        <Controller
                                                                                            name={`startupTeams.${index}.totalEmployees`}
                                                                                            control={control}
                                                                                            defaultValue=""
                                                                                            rules={{ required: "Total number of employees is required" }}
                                                                                            render={({ field }) => (
                                                                                                <TextField
                                                                                                    variant="outlined"
                                                                                                    label="Total number of employees *"
                                                                                                    placeholder="Enter Total number of employees"
                                                                                                    fullWidth
                                                                                                    inputProps={{ readOnly: readyOnlyValue, maxLength: 4 }}
                                                                                                    {...field}
                                                                                                    onKeyDown={(e) => validateNumberonly(e)}


                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                        <FormHelperText className="text-danger">
                                                                                            {errors.startupTeams?.[index]?.totalEmployees && errors.startupTeams?.[index]?.totalEmployees.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell> */}
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <FormLabel>Covered under PF/ESI</FormLabel>
                                                                                        <Controller name={`startupTeams.${index}.coveredUnderPF`} control={control} defaultValue="" rules={{ required: false }}
                                                                                            render={({ field }) =>
                                                                                                <RadioGroup
                                                                                                    row
                                                                                                    name="position"
                                                                                                    {...field}
                                                                                                >
                                                                                                    <FormControlLabel disabled={nonEditAble} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                                                                                                    <FormControlLabel disabled={nonEditAble} defaultValue='null' value='false' control={<Radio />} label="No" />

                                                                                                </RadioGroup>
                                                                                            } />
                                                                                        <FormHelperText className='text-danger'>{errors.startupTeams?.[index]?.coveredUnderPF && errors.startupTeams?.[index]?.coveredUnderPF.message}</FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                {/* <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center' >
                                                                                    <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} />
                                                                                </TableCell> */}
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                                {/* <center>
                                                                    <Button className='add-more-btn' onClick={() => addStartupTeamDetails('')} disabled={nonEditAble}><AddCircleIcon className='mr-2' />Add More</Button>
                                                                </center> */}
                                                            </TableContainer>
                                                            //     ) : (
                                                            // <div className='nodata_tag'>
                                                            //     <p>Fundraising history details is empty</p>
                                                            // </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="col-lg-12 col-12 mb-3 pl-3">
                                <Accordion className='Addfundacc-bor' expanded={isAccordionOpen7} onChange={() => setIsAccordionOpen7(!isAccordionOpen7)}>
                                    <AccordionSummary
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className='Addfundacc'
                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <Typography sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            margin: 0
                                        }}>
                                            <AddCircleIcon className='add-icon mr-3' />Status of compliance under Labour & related laws
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <div className='col-12 mb-3'>
                                                <div>
                                                    {
                                                        (startupLabourLawFields.length >= 0) && (
                                                            <TableContainer component={Paper} className='table-back'>
                                                                <Table aria-label="caption table">
                                                                    <TableBody >
                                                                        {startupLabourLawFields.map((field, index) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>{index + 1}</TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller
                                                                                            name={`startupLabourLaw.${index}.act`}
                                                                                            control={control}
                                                                                            defaultValue=""
                                                                                            rules={{ required: "Act is required" }}
                                                                                            render={({ field }) => (
                                                                                                <Autocomplete
                                                                                                    disableClearable
                                                                                                    disablePortal
                                                                                                    {...field}
                                                                                                    // limitTags={2}
                                                                                                    // value={stateName}
                                                                                                    options={actList}
                                                                                                    onChange={(e, selectedOptions) => handleActList(selectedOptions, index)}
                                                                                                    renderInput={(params) => (
                                                                                                        <TextField {...params} label="Act *" />
                                                                                                    )}
                                                                                                    disabled={readyOnlyValue}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.startupLabourLaw?.[index]?.act && errors.startupLabourLaw?.[index]?.act.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <FormLabel>Whether obtained / not</FormLabel>
                                                                                        <Controller name={`startupLabourLaw.${index}.obtained`} control={control} defaultValue="" rules={{ required: true }}
                                                                                            render={({ field }) =>
                                                                                                <RadioGroup
                                                                                                    row
                                                                                                    name="position"
                                                                                                    {...field}
                                                                                                >
                                                                                                    <FormControlLabel disabled={readyOnlyValue} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                                                                                                    <FormControlLabel disabled={readyOnlyValue} defaultValue='null' value='false' control={<Radio />} label="No" />

                                                                                                </RadioGroup>
                                                                                            } />
                                                                                        <FormHelperText className='text-danger'>{errors.startupLabourLaw?.[index]?.obtained && errors.startupLabourLaw?.[index]?.obtained.message}</FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller name={`startupLabourLaw.${index}.validity`} control={control} defaultValue="" rules={{ required: 'If obtained, validity period is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="If obtained, validity period *"
                                                                                                    placeholder='Enter If obtained, validity period'
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    inputProps={
                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                    }
                                                                                                />} />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.startupLabourLaw?.[index]?.validity && errors.startupLabourLaw?.[index]?.validity.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller name={`startupLabourLaw.${index}.status`} control={control} defaultValue="" rules={{ required: 'Status of compliance is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="Status of compliance *"
                                                                                                    placeholder='Enter Status of compliance'
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    inputProps={
                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                    }
                                                                                                />} />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.startupLabourLaw?.[index]?.status && errors.startupLabourLaw?.[index]?.status.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center'>
                                                                                    <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteStartupLabourLawBtn(index) : <></> }} />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                                <center>
                                                                    <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => addStartupLabourLaw('')}><AddCircleIcon className='mr-2' />Add More</Button>
                                                                </center>
                                                            </TableContainer>
                                                            //     ) : (
                                                            // <div className='nodata_tag'>
                                                            //     <p>Fundraising history details is empty</p>
                                                            // </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="col-lg-12 col-12 mb-3 pl-3">
                                <Accordion className='Addfundacc-bor' expanded={isAccordionOpen8} onChange={() => setIsAccordionOpen8(!isAccordionOpen8)}>
                                    <AccordionSummary
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className='Addfundacc'
                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <Typography sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            margin: 0
                                        }}>
                                            <AddCircleIcon className='add-icon mr-3' />Copies of the following policies
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <div className='col-12 mb-3'>
                                                <div>
                                                    {
                                                        (startupPolicyFields.length >= 0) && (
                                                            <TableContainer component={Paper} className='table-back'>
                                                                <Table aria-label="caption table">
                                                                    <TableBody >
                                                                        {startupPolicyFields.map((field, index) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>{index + 1}</TableCell>
                                                                                <TableCell sx={{ width: '30%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller
                                                                                            name={`startupPolicy.${index}.name`}
                                                                                            control={control}
                                                                                            defaultValue=""
                                                                                            rules={{ required: "Name is required" }}
                                                                                            render={({ field }) => (
                                                                                                <Autocomplete
                                                                                                    disableClearable
                                                                                                    disablePortal
                                                                                                    {...field}
                                                                                                    // limitTags={2}
                                                                                                    // value={stateName}
                                                                                                    options={nameList}
                                                                                                    onChange={(e, selectedOptions) => handleNameList(selectedOptions, index)}
                                                                                                    renderInput={(params) => (
                                                                                                        <TextField {...params} label="Name *" />
                                                                                                    )}
                                                                                                    disabled={readyOnlyValue}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.startupPolicy?.[index]?.name && errors.startupPolicy?.[index]?.name.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>


                                                                                <TableCell sx={{ width: '30%', borderBottom: 'none', padding: '5px' }}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <Controller name={`startupPolicy.${index}.status`} control={control} defaultValue="" rules={{ required: 'Status is required' }}
                                                                                            render={({ field }) =>
                                                                                                <TextField
                                                                                                    id="outlined-multiline-static"
                                                                                                    label="Status  *"
                                                                                                    placeholder='Enter Status '
                                                                                                    fullwidth
                                                                                                    {...field}
                                                                                                    inputProps={
                                                                                                        { readOnly: readyOnlyValue, }
                                                                                                    }
                                                                                                />} />
                                                                                        <FormHelperText className='text-danger'>
                                                                                            {errors.startupPolicy?.[index]?.status && errors.startupPolicy?.[index]?.status.message}
                                                                                        </FormHelperText>
                                                                                    </FormControl>
                                                                                </TableCell>
                                                                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center'>
                                                                                    <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteStartupPolicyBtn(index) : <></> }} />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                                <center>
                                                                    <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => addStartupPolicy('')}><AddCircleIcon className='mr-2' />Add More</Button>
                                                                </center>
                                                            </TableContainer>
                                                            //     ) : (
                                                            // <div className='nodata_tag'>
                                                            //     <p>Fundraising history details is empty</p>
                                                            // </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>


                        </div>
                        <div className="form-group d-flex justify-content-end mt-3 mr-2">
                            <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
                            {
                                (readyOnlyValue === false) ? (
                                    <Button
                                        type="submit"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        className="btn btn-primary submit px-3 ml-3"
                                    >
                                        <span>Save & Proceed Next</span>
                                    </Button>
                                ) : (<></>)
                            }

                        </div>
                    </form>
                </div>
                <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
            </section>
        </>
    )
}


export default PreliminaryDocumentPart1