import React, { useContext } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './scst.css'
import { NavLink } from 'react-router-dom';
import DataContext from '../../context/DataContext';

const Scstnavbar = () => {
  const { scstRemoveFiters } = useContext(DataContext)
  const handleClick = (event) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();      
    }
    scstRemoveFiters()
  };
  return (
    <nav>
      <div className='submenu_div'>
        <div className='container-fluid'>
          <Box sx={{ display: 'flex', paddingTop: '5px', textAlign: 'center', marginLeft: '25px', marginRight: '25px' }}>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to="/scst/hubdashboard" onClick={handleClick}>Dashboard</NavLink>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to="/scst/startups/validation" onClick={handleClick}>Startups</NavLink>
            </Typography>
            {/* <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link"  to="/scst/startups/evaluation" onClick={handleClick}>Evaluation</NavLink>
            </Typography> */}
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to="/scst/evaluator" onClick={handleClick}>Evaluator</NavLink>
            </Typography>
          </Box>
        </div>
      </div>
    </nav>
  )
}

export default Scstnavbar