import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, FormHelperText, FormLabel, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import DataContext from '../../../../context/DataContext';
import notifyService from '../../../../api/notifySerivce';
import { Link } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Viewimage from '../../../../api/Viewimage';
import { FaRegFilePdf } from "react-icons/fa";
import { BsFiletypeXls } from "react-icons/bs";
// import cryptodecryptJS from '../../../../hooks/cryptodecryptJS';
// import PreliminaryTextPart1 from './PreliminaryTextPart1';
const fileTypesPDF = ["pdf"];
const fileTypesExcel = ["XLSX",];
const fileTypesPDFandExcel = ["pdf", "XLSX",];
const fileTypesAll = ["pdf", "XLSX", "doc", "docx"];

const FDDAdditionalDoc1 = () => {
    const [formData, setFormData] = useState({});
    const { handleBackapp, setPageLoading } = useContext(DataContext)
    const { handleSubmit, setValue, getValues, clearErrors, control, formState: { errors } } = useForm({
        values: formData,
    });
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const [nonEditAble, setNonEditAble] = useState(false);
    useDidMountEffect(() => {
        getApi()
    }, [])
    const [fileUploadId, setFileUploadId] = useState(0)
    const getApi = async () => {
        setPageLoading(true)
        await apiService(`startup/fdd/section/one/get`, '', 'get').then((res) => {
            if (res && res.data) {
                var data = res.data
                if (data.editForm === false) {
                    setReadyOnlyValue(true)
                }
                setNonEditAble(true)
                if (res.data.id) {
                    setFileUploadId(res.data.id)
                }

                setFormData(res.data)
            }
            setPageLoading(false)
        })
    }
    const handleFileUpload = (event, filekey, fileName, fileURL, format) => {
        let logoselecetdFile;
        if (event !== null) {
            if (event.target === undefined) {
                logoselecetdFile = event
            } else {
                logoselecetdFile = event.target.files[0]
            }
            if (logoselecetdFile) {
                const fileSizeLimit = 5 * 1024 * 1024;
                if (logoselecetdFile.size > fileSizeLimit) {
                    notifyService(
                        "danger",
                        "File Size Exceeded",
                        "Please upload a file smaller than 5 MB."
                    );
                    return;
                }
                var reader = new FileReader();
                var imagetype = logoselecetdFile.type
                var imagedatatype = imagetype.split("/")
                var img_crt_type = imagedatatype[1]
                debugger
                if (
                    ((format === 'file') && (img_crt_type === "pdf")) ||
                    ((format === 'excel') && (img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet")) ||
                    ((format === 'pdfAndExcel') && (img_crt_type === "pdf" || img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet")) ||
                    ((format === 'excelAndDoc') && (img_crt_type === "vnd.openxmlformats-officedocument.wordprocessingml.document" || img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet")) ||
                    ((format === 'pdfwordexcel') && (img_crt_type === "pdf" || img_crt_type === "vnd.openxmlformats-officedocument.wordprocessingml.document" || img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
                ) {
                    setValue(filekey, "")
                    setValue(fileName, "")
                    debugger
                    var fileValue = logoselecetdFile
                    reader.readAsDataURL(logoselecetdFile);
                    reader.onload = () => {
                        var logourl1 = reader.result;
                        var spl = logourl1.split(',')
                        var ImageValue = spl[1]
                        var img_name = fileValue.name
                        setValue(filekey, ImageValue)
                        setValue(fileName, img_name)
                        setValue(fileURL, logourl1)
                        clearErrors(filekey);
                        clearErrors(fileName);
                        clearErrors(fileURL);
                        var req = {
                            [filekey]: ImageValue,
                            [fileName]: img_name,
                            id: fileUploadId
                        }
                        console.log(req);
                        saveData(req)
                    }
                } else {
                    notifyService('danger', 'File Format Invalided', 'Please check your file format')
                }
            }
        }
    }
    const saveData = (req) => {
        console.log(req);
        apiService('startup/fdd/section/one/save', req, 'post').then((res) => {
            setPageLoading(false);
            if (res && res.data) {
                if (res.data.responseStatus === "Success") {
                    getApi()
                }
            }
        })
    }
    const handleClickOpens = (url) => {
        debugger
        if (url) {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.rel = 'noopener noreferrer';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };
    const onSubmit = (data) => {
        handleBackapp()
        // setPageLoading(true);
       
        // apiService('startup/fdd/section/one/save', data, 'post').then((res) => {
        //     setPageLoading(false);
        //     if (res && res.data) {
        //         if (res.data.responseStatus === "Success") {
        //             notifyService('success', 'Success', 'Saved successfully !!')
        //             handleBackapp()
        //         }
        //     }
        // })
    }
  return (
    <section className='container-fluid'>
    <div className='form_div'>
        <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackapp}>
                <img src='/images/Fig-back.png' className='img-fluid' alt='' />
            </span>
            <h6 className="ml-2 mb-0">Additional Document - 1</h6>
        </div>
        <hr />
        <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
           
                <div className="row doc-row">
                    <div className='col-lg-4 col-12 mt-2'>
                        <FormLabel>Access to alternative software through which the operations are tracked. Say for instance software to place orders/ invoicing/ inventory tracking likewise</FormLabel>
                        <div className="d-flex align-items-center">
                            <div className='form-group my-1'>
                                <Controller name="alternateSoftwareDoc" control={control} defaultValue="" rules={{ required: false }}
                                    render={({ field }) =>

                                        <FileUploader
                                            {...field}
                                            handleChange={(e) => handleFileUpload(e,
                                                "alternateSoftwareDoc",
                                                "alternateSoftwareDocName",
                                                "alternateSoftwareDocURL",
                                                'pdfwordexcel')}
                                            value={getValues('alternateSoftwareDoc')}
                                            name="file"
                                            types={fileTypesAll}
                                            children={
                                                <div className={getValues('alternateSoftwareDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                    <center>
                                                        <p className={getValues('alternateSoftwareDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                            <span>
                                                                <AttachFileIcon className="file-icon mr-2" />
                                                                {getValues('alternateSoftwareDoc') !== '' ? 'Uploaded (Click to Change)' : 'Alternative Document'}
                                                            </span>
                                                        </p>
                                                    </center>
                                                </div>
                                            }
                                            disabled={readyOnlyValue}
                                        />
                                    } />
                                <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.alternateSoftwareDoc && errors.alternateSoftwareDoc.message}</FormHelperText>
                            </div>
                            <span className="pre-btn-dpiit"  onClick={() => handleClickOpens(getValues('alternateSoftwareDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                               
                                style={{ color: "#80809e" }}
                            > Download
                            </Link>
                            </span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-12 mt-2'>
                        <FormLabel>Tie ups with Main Clients if any.</FormLabel>
                        <div className="d-flex align-items-center">
                            <div className='form-group my-1'>
                                <Controller name="tieUpDoc" control={control} defaultValue="" rules={{ required: false }}
                                    render={({ field }) =>

                                        <FileUploader
                                            {...field}
                                            handleChange={(e) => handleFileUpload(e,
                                                "tieUpDoc",
                                                "tieUpDocName",
                                                "tieUpDocURL",
                                                'pdfwordexcel')}
                                            value={getValues('tieUpDoc')}
                                            name="file"
                                            types={fileTypesAll}
                                            children={
                                                <div className={getValues('tieUpDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                    <center>
                                                        <p className={getValues('tieUpDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                            <span>
                                                                <AttachFileIcon className="file-icon mr-2" />
                                                                {getValues('tieUpDoc') !== '' ? 'Uploaded (Click to Change)' : 'Tie ups Document'}
                                                            </span>
                                                        </p>
                                                    </center>
                                                </div>
                                            }
                                            disabled={readyOnlyValue}
                                        />
                                    } />
                                <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.tieUpDoc && errors.tieUpDoc.message}</FormHelperText>
                            </div>
                            <span className="pre-btn-dpiit" onClick={() => handleClickOpens(getValues('tieUpDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                                
                                style={{ color: "#80809e" }}
                            > Download
                            </Link>
                            </span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-12 mt-2'>
                        <FormLabel>Customer level profitabilty, if applicable</FormLabel>
                        <div className="d-flex align-items-center">
                            <div className='form-group my-1'>
                                <Controller name="customerProfitDoc" control={control} defaultValue="" rules={{ required: false }}
                                    render={({ field }) =>

                                        <FileUploader
                                            {...field}
                                            handleChange={(e) => handleFileUpload(e,
                                                "customerProfitDoc",
                                                "customerProfitDocName",
                                                "customerProfitDocURL",
                                                'pdfwordexcel')}
                                            value={getValues('customerProfitDoc')}
                                            name="file"
                                            types={fileTypesAll}
                                            children={
                                                <div className={getValues('customerProfitDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                    <center>
                                                        <p className={getValues('customerProfitDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                            <span>
                                                                <AttachFileIcon className="file-icon mr-2" />
                                                                {getValues('customerProfitDoc') !== '' ? 'Uploaded (Click to Change)' : 'Profitabilty Document'}
                                                            </span>
                                                        </p>
                                                    </center>
                                                </div>
                                            }
                                            disabled={readyOnlyValue}
                                        />
                                    } />
                                <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.customerProfitDoc && errors.customerProfitDoc.message}</FormHelperText>
                            </div>
                            <span className="pre-btn-dpiit"  onClick={() => handleClickOpens(getValues('customerProfitDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                               
                                style={{ color: "#80809e" }}
                            > Download
                            </Link>
                            </span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-12 mt-2'>
                        <FormLabel>Details of Directors resigned from the inception to till date & the reasons thereof</FormLabel>
                        <div className="d-flex align-items-center">
                            <div className='form-group my-1'>
                                <Controller name="resignedDirectorsDoc" control={control} defaultValue="" rules={{ required: false }}
                                    render={({ field }) =>

                                        <FileUploader
                                            {...field}
                                            handleChange={(e) => handleFileUpload(e,
                                                "resignedDirectorsDoc",
                                                "resignedDirectorsDocName",
                                                "resignedDirectorsDocURL",
                                                'pdfwordexcel')}
                                            value={getValues('resignedDirectorsDoc')}
                                            name="file"
                                            types={fileTypesAll}
                                            children={
                                                <div className={getValues('resignedDirectorsDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                    <center>
                                                        <p className={getValues('resignedDirectorsDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                            <span>
                                                                <AttachFileIcon className="file-icon mr-2" />
                                                                {getValues('resignedDirectorsDoc') !== '' ? 'Uploaded (Click to Change)' : 'Resigned  Document'}
                                                            </span>
                                                        </p>
                                                    </center>
                                                </div>
                                            }
                                            disabled={readyOnlyValue}
                                        />
                                    } />
                                <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.resignedDirectorsDoc && errors.resignedDirectorsDoc.message}</FormHelperText>
                            </div>
                            <span className="pre-btn-dpiit" onClick={() => handleClickOpens(getValues('resignedDirectorsDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                                
                                style={{ color: "#80809e" }}
                            > Download
                            </Link>
                            </span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-12 mt-2'>
                        <FormLabel>Business Plan submitted to the investors</FormLabel>
                        <div className="d-flex align-items-center">
                            <div className='form-group my-1'>
                                <Controller name="businessPlanDoc" control={control} defaultValue="" rules={{ required: false }}
                                    render={({ field }) =>

                                        <FileUploader
                                            {...field}
                                            handleChange={(e) => handleFileUpload(e,
                                                "businessPlanDoc",
                                                "businessPlanDocName",
                                                "businessPlanDocURL",
                                                'pdfwordexcel')}
                                            value={getValues('businessPlanDoc')}
                                            name="file"
                                            types={fileTypesAll}
                                            children={
                                                <div className={getValues('businessPlanDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                    <center>
                                                        <p className={getValues('businessPlanDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                            <span>
                                                                <AttachFileIcon className="file-icon mr-2" />
                                                                {getValues('businessPlanDoc') !== '' ? 'Uploaded (Click to Change)' : 'Business Plan  Document'}
                                                            </span>
                                                        </p>
                                                    </center>
                                                </div>
                                            }
                                            disabled={readyOnlyValue}
                                        />
                                    } />
                                <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.businessPlanDoc && errors.businessPlanDoc.message}</FormHelperText>
                            </div>
                            <span className="pre-btn-dpiit" onClick={() => handleClickOpens(getValues('businessPlanDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                                
                                style={{ color: "#80809e" }}
                            > Download
                            </Link>
                            </span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-12 mt-2'>
                        <FormLabel>Simple cash flow statement for the next 12 months, without including the current round investment amount.  ( This should show revenue, collection, expenses and the bank balance)</FormLabel>
                        <div className="d-flex align-items-center">
                            <div className='form-group my-1'>
                                <Controller name="cashFlowStatementDoc" control={control} defaultValue="" rules={{ required: false }}
                                    render={({ field }) =>

                                        <FileUploader
                                            {...field}
                                            handleChange={(e) => handleFileUpload(e,
                                                "cashFlowStatementDoc",
                                                "cashFlowStatementDocName",
                                                "cashFlowStatementDocURL",
                                                'pdfwordexcel')}
                                            value={getValues('cashFlowStatementDoc')}
                                            name="file"
                                            types={fileTypesAll}
                                            children={
                                                <div className={getValues('cashFlowStatementDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                    <center>
                                                        <p className={getValues('cashFlowStatementDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                            <span>
                                                                <AttachFileIcon className="file-icon mr-2" />
                                                                {getValues('cashFlowStatementDoc') !== '' ? 'Uploaded (Click to Change)' : 'Cash flow Document'}
                                                            </span>
                                                        </p>
                                                    </center>
                                                </div>
                                            }
                                            disabled={readyOnlyValue}
                                        />
                                    } />
                                <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.cashFlowStatementDoc && errors.cashFlowStatementDoc.message}</FormHelperText>
                            </div>
                            <span className="pre-btn-dpiit"  onClick={() => handleClickOpens(getValues('cashFlowStatementDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                               
                                style={{ color: "#80809e" }}
                            > Download
                            </Link>
                            </span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-12 mt-2'>
                        <FormLabel>Financial Due Diligence (FDD) report, if issued earlier.</FormLabel>
                        <div className="d-flex align-items-center">
                            <div className='form-group my-1'>
                                <Controller name="existingFddReportDoc" control={control} defaultValue="" rules={{ required: false }}
                                    render={({ field }) =>

                                        <FileUploader
                                            {...field}
                                            handleChange={(e) => handleFileUpload(e,
                                                "existingFddReportDoc",
                                                "existingFddReportDocName",
                                                "existingFddReportDocURL",
                                                'pdfwordexcel')}
                                            value={getValues('existingFddReportDoc')}
                                            name="file"
                                            types={fileTypesAll}
                                            children={
                                                <div className={getValues('existingFddReportDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                    <center>
                                                        <p className={getValues('existingFddReportDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                            <span>
                                                                <AttachFileIcon className="file-icon mr-2" />
                                                                {getValues('existingFddReportDoc') !== '' ? 'Uploaded (Click to Change)' : 'Financial Document'}
                                                            </span>
                                                        </p>
                                                    </center>
                                                </div>
                                            }
                                            disabled={readyOnlyValue}
                                        />
                                    } />
                                <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.existingFddReportDoc && errors.existingFddReportDoc.message}</FormHelperText>
                            </div>
                            <span className="pre-btn-dpiit"  onClick={() => handleClickOpens(getValues('existingFddReportDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                               
                                style={{ color: "#80809e" }}
                            > Download
                            </Link>
                            </span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-12 mt-2'>
                        <FormLabel>SHA for prior round investments</FormLabel>
                        <div className="d-flex align-items-center">
                            <div className='form-group my-1'>
                                <Controller name="shaDoc" control={control} defaultValue="" rules={{ required: false }}
                                    render={({ field }) =>

                                        <FileUploader
                                            {...field}
                                            handleChange={(e) => handleFileUpload(e,
                                                "shaDoc",
                                                "shaDocName",
                                                "shaDocURL",
                                                'pdfwordexcel')}
                                            value={getValues('shaDoc')}
                                            name="file"
                                            types={fileTypesAll}
                                            children={
                                                <div className={getValues('shaDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                    <center>
                                                        <p className={getValues('shaDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                            <span>
                                                                <AttachFileIcon className="file-icon mr-2" />
                                                                {getValues('shaDoc') !== '' ? 'Uploaded (Click to Change)' : 'SHA Document'}
                                                            </span>
                                                        </p>
                                                    </center>
                                                </div>
                                            }
                                            disabled={readyOnlyValue}
                                        />
                                    } />
                                <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.shaDoc && errors.shaDoc.message}</FormHelperText>
                            </div>
                            <span className="pre-btn-dpiit" onClick={() => handleClickOpens(getValues('shaDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                                
                                style={{ color: "#80809e" }}
                            > Download
                            </Link>
                            </span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-12 mt-2'>
                        <FormLabel>Valuation Certificate</FormLabel>
                        <div className="d-flex align-items-center">
                            <div className='form-group my-1'>
                                <Controller name="valuationCertificateDoc" control={control} defaultValue="" rules={{ required: false }}
                                    render={({ field }) =>

                                        <FileUploader
                                            {...field}
                                            handleChange={(e) => handleFileUpload(e,
                                                "valuationCertificateDoc",
                                                "valuationCertificateDocName",
                                                "valuationCertificateDocURL",
                                                'pdfwordexcel')}
                                            value={getValues('valuationCertificateDoc')}
                                            name="file"
                                            types={fileTypesAll}
                                            children={
                                                <div className={getValues('valuationCertificateDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                    <center>
                                                        <p className={getValues('valuationCertificateDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                            <span>
                                                                <AttachFileIcon className="file-icon mr-2" />
                                                                {getValues('valuationCertificateDoc') !== '' ? 'Uploaded (Click to Change)' : 'Valuation Document'}
                                                            </span>
                                                        </p>
                                                    </center>
                                                </div>
                                            }
                                            disabled={readyOnlyValue}
                                        />
                                    } />
                                <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.valuationCertificateDoc && errors.valuationCertificateDoc.message}</FormHelperText>
                            </div>
                            <span className="pre-btn-dpiit" onClick={() => handleClickOpens(getValues('valuationCertificateDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                                
                                style={{ color: "#80809e" }}
                            > Download
                            </Link>
                            </span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-12 mt-2'>
                        <FormLabel>Physical Verification Statement of the fixed asset available as on any latest date issued prior to the commencement of the Due Diligence.</FormLabel>
                        <div className="d-flex align-items-center">
                            <div className='form-group my-1'>
                                <Controller name="physicalVerificationDoc" control={control} defaultValue="" rules={{ required: false }}
                                    render={({ field }) =>

                                        <FileUploader
                                            {...field}
                                            handleChange={(e) => handleFileUpload(e,
                                                "physicalVerificationDoc",
                                                "physicalVerificationDocName",
                                                "physicalVerificationDocURL",
                                                'pdfwordexcel')}
                                            value={getValues('physicalVerificationDoc')}
                                            name="file"
                                            types={fileTypesAll}
                                            children={
                                                <div className={getValues('physicalVerificationDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                    <center>
                                                        <p className={getValues('physicalVerificationDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                            <span>
                                                                <AttachFileIcon className="file-icon mr-2" />
                                                                {getValues('physicalVerificationDoc') !== '' ? 'Uploaded (Click to Change)' : 'Verification Document'}
                                                            </span>
                                                        </p>
                                                    </center>
                                                </div>
                                            }
                                            disabled={readyOnlyValue}
                                        />
                                    } />
                                <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.physicalVerificationDoc && errors.physicalVerificationDoc.message}</FormHelperText>
                            </div>
                            <span className="pre-btn-dpiit" onClick={() => handleClickOpens(getValues('physicalVerificationDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                                
                                style={{ color: "#80809e" }}
                            > Download
                            </Link>
                            </span>
                        </div>
                    </div>
            </div>
            <div className="form-group d-flex justify-content-end mt-3 mr-2">
                <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
                {
                    (readyOnlyValue === false) ? (
                        <Button
                            type="submit"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            className="btn btn-primary submit px-3 ml-3"
                        >
                            <span>Save & Proceed Next</span>
                        </Button>
                    ) : (<></>)
                }

            </div>
        </form>
    </div>
    {/* <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} /> */}
</section>
  )
}

export default FDDAdditionalDoc1
