import React, { useContext, useEffect } from 'react'
import Layout from './home-module/Layout';
import Adminlayout from './admin-module/Adminlayout';
import Home from './home-module/home-page/Home'
import SignIn from './home-module/signin-page/SignIn'
import Navbar from './nav-page/Navbar';
import Partnerlayout from './partner-module/Partnerlayout';
import Investorlayout from './investor-module/Investorlayout'
import Jurylist from './partner-module/jury-details/Jurylist';
import Roundlist from './partner-module/round-details/round-page/Roundlist';
import Roundadd from './partner-module/round-details/round-page/round-add-page/Roundadd';
import Jurysave from './partner-module/jury-details/Jurysave';
import Startuplayout from './startup-module/Startuplayout';
import Partnerlist from './admin-module/partner-page/Partnerlist';
import Startuplist from './partner-module/startup-page/startup-list-page/Startuplist';
import Investorslist from './admin-module/investors-page/Investorslist';
import Investorprofile from './admin-module/investors-page/Investorprofile';
import Partnerprofile from './partner-module/profile-page/Partnerprofile';
import Roundroute from './partner-module/round-details/round-page/Roundroute';
import Adminjurylist from './admin-module/jury-page/Adminjurylist';
import Startuproute from './partner-module/startup-page/Startuproute';
import Startupdetails from './partner-module/startup-page/startup-details-page/Startupdetails';
import Progresslist from './startup-module/progress-page/Progresslist';
import Jurylayout from './jury-module/Jurylayout';
import Selfdeclarmsg from './startup-module/Selfdeclarmsg';
import Startupsignup from './home-module/signup-page/startup-signup/Startupsignup';
import Investorsignup from './home-module/signup-page/investor-signup/Investorsignup';
import Pagenotfound from './home-module/page-not-found/Pagenotfound';
import Firstlogin from './home-module/first-login-page/Firstlogin';
import Signupverify from './home-module/signup-page/startup-signup/Signupverify';
import Signupinfo from './home-module/signup-page/startup-signup/Signupinfo';
import Dashboard from './startup-module/dashboard-page/Dashboard';
import Termsandcondition from './startup-module/dashboard-page/Termsandcondition';
import Adminstartuplist from './admin-module/startup-page/Adminstartuplist';
import Forgotpassword from './home-module/signin-page/forgot-page/Forgotpassword';
import Investorverify from './home-module/signup-page/investor-signup/Investorverify';
import { RingLoader } from 'react-spinners';
import Fundingfinancials from './startup-module/dashboard-page/form-page/Fundingfinancials';
import Team from './startup-module/dashboard-page/form-page/Team';
import Incubationaccprogram from './startup-module/dashboard-page/form-page/Incubationaccprogram';
import Productandmarket from './startup-module/dashboard-page/form-page/Productandmarket';
import Businessstrategy from './startup-module/dashboard-page/form-page/Businessstrategy';
import Tractionandachievements from './startup-module/dashboard-page/form-page/Tractionandachievements';
import Fundingutilization from './startup-module/dashboard-page/form-page/Fundingutilization';
import Documentsupload from './startup-module/dashboard-page/form-page/Documentsupload';
import Admindashboard from './admin-module/Admindashboard';
import Application from './startup-module/dashboard-page/form-page/Application';
import Undermaintenance from './home-module/under-maintenance-page/Undermaintenance';
import Associatedroute from './investor-module/associated-startups-page/Associatedroute';
import Associateddetails from './investor-module/associated-startups-page/associated-details-page/Associateddetails';
import Adminassociated from './admin-module/associated-startup-page/Adminassociated';
import Admininvestorroute from './admin-module/investors-page/Admininvestorroute';
import Investorroute from './investor-module/profile-page/Investorroute';
import Investorprofiledetails from './investor-module/profile-page/Investorprofiledetails';
import Investorpreference from './investor-module/profile-page/Investorpreference';
import Investoreoi from './investor-module/profile-page/Investoreoi';
import Additionalinfo from './startup-module/additional-info-page/Additionalinfo';
import Aboutus from './home-module/about-us-page/Aboutus';
import Fundingpartners from './home-module/funding-partners-page/Fundingpartners';
import Tanseed from './home-module/tanseed/Tanseed';
import Tanseedpage from './startup-module/tanseed-page/Tanseedpage';
import Sesstiontimeout from './home-module/sesstion-time-out-page/Sesstiontimeout';
import Admintanseed from './admin-module/tanseed-page/Admintanseed';
import Tanseedlayout from './tanseed-module/Tanseedlayout';
import Tanseedstartuplist from './tanseed-module/tanseed-startups-page/Tanseedstartuplist';
import Associatedlist from './investor-module/associated-startups-page/associated-list-page/Associatedlist';
import Associatedlisttab from './investor-module/associated-startups-page/associated-list-page/Associatedlisttab';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import DataContext from '../context/DataContext';
import Juryassociatedlist from './jury-module/associated-startup-page/Juryassociatedlist';
import Tanseedroute from './investor-module/Tanseed-page/Tanseedroute';
import Dashboardscst from './startup-module/dashboard-scst-page/Dashboardscst';
import Applicationscst from './startup-module/dashboard-scst-page/Applicationscst';
import Fundingscst from './startup-module/dashboard-scst-page/Fundingscst';
import Founderscst from './startup-module/dashboard-scst-page/Founderscst';
import Termsconditionscst from './startup-module/dashboard-scst-page/Termsconditionscst';
import Scstlayout from './scst-page/Scstlayout';
import Scstlist from './scst-page/Scstlist';
import ScstDetails from './scst-page/ScstDetails';
import Scsthubavplayout from './scsthubavp-module/Scsthubavplayout';
import Scsthubavplist from './scsthubavp-module/Scsthubavplist';
import Assignjurylist from './scsthubavp-module/assignjurypage/Assignjurylist';
import Progresslistscst from './startup-module/dashboard-scst-page/Progresslistscst';
import Scstjurylist from './scst-page/Scstjurylist';
import Scsthubavpdetails from './scsthubavp-module/scsthubavp-details/Scsthubavpdetails';
import Scsthubavproute from './scsthubavp-module/Scsthubavproute';
import Scstevaluationdetails from './scst-page/Scstevaluationdetails';
import Zoomroute from './jury-module/zoom-page/Zoomroute';
import Zoomlist from './jury-module/zoom-page/Zoomlist';
import Zoomscheduler from './jury-module/zoom-page/Zoomscheduler';
import TanseedDashboard from './tanseed-module/tanseed-startups-page/TanseedDashboard';
import Dashboardtanseed from './startup-module/dashboard-tanseed-page/Dashboardtanseed';
import Applicationtanseed from './startup-module/dashboard-tanseed-page/Applicationtanseed';
import Teaminfotanseed from './startup-module/dashboard-tanseed-page/Teaminfotanseed';
import FundingTanseed from './startup-module/dashboard-tanseed-page/FundingTanseed';
import AdvisoryMentor from './startup-module/dashboard-tanseed-page/AdvisoryMentor';
import Fundraisedhistory from './startup-module/dashboard-tanseed-page/Fundraisedhistory';
import Tractionachievements from './investor-module/associated-startups-page/associated-details-page/pages/Tractionachievements';
import Tractionandacheivementtanseed from './startup-module/dashboard-tanseed-page/Tractionandacheivementtanseed';
import Achievementstanseed from './startup-module/dashboard-tanseed-page/Achievementstanseed';
import DocumentsUploadtanseed from './startup-module/dashboard-tanseed-page/DocumentsUploadtanseed';
import Partnerslist from './tanseed-module/tanseed-startups-page/Partnerslist';
import TanseedPartnerLayout from './tanseed-module/tanseed-startups-page/TanseedPartnerLayout';
import TanseedAssociateddetails from './tanseed-module/tanseed-startups-page/TanseedAssociateddetails';
import AdminTanseedDetails from './admin-module/tanseed-page/AdminTanseedDetails';
import AllStartupDashboardLayout from './startup-module/all-dashborad-details/AllStartupDashboardLayout';
import InvestorConnectDashboard from './startup-module/investor-connect-page/InvestorConnectDashboard';
import InvestorApplication from './startup-module/investor-connect-page/form-page/InvestorApplication';
import InvestorFunding from './startup-module/investor-connect-page/form-page/InvestorFunding';
import InvestorTeaminfo from './startup-module/investor-connect-page/form-page/InvestorTeaminfo';
import InvestorDocuments from './startup-module/investor-connect-page/form-page/InvestorDocuments';
import Termsandconditioninvestor from './startup-module/investor-connect-page/Termsandconditioninvestor';
import ICAdminLayout from './ic-admin-module/ICAdminLayout';
import ICStartupList from './ic-admin-module/startup-list-page/ICStartupList';
import Incubationaccprogramscst from './startup-module/dashboard-scst-page/Incubationaccprogramscst';
import ProductMarketscst from './startup-module/dashboard-scst-page/ProductMarketscst';
import BusinessStrategyScst from './startup-module/dashboard-scst-page/BusinessStrategyScst';
import TeamInfoscst from './startup-module/dashboard-scst-page/TeamInfoscst';
import MarketCompetitorsScst from './startup-module/dashboard-scst-page/MarketCompetitorsScst';
import TnScstStartupFund from './home-module/tnscststartupfund/TnScstStartupFund';
import ScstBddLayout from './scstBdd-page/ScstBddLayout';
import ScstBddroute from './scstBdd-page/ScstBddroute';
import ScstBddlist from './scstBdd-page/ScstBddlist';
import ScstMentorshipLayout from './scstMentorship-page/ScstMentorshipLayout';
import Scstmentorshiproute from './scstMentorship-page/Scstmentorshiproute';
import Scstmentorshiplist from './scstMentorship-page/Scstmentorshiplist';
import ScstBdddetails from './scstBdd-page/scstBdd-details/ScstBdddetails';
import Scstmentorshipdetails from './scstMentorship-page/Scstmentorship-details/Scstmentorshipdetails';
import ScsthubDashboard from './scsthubavp-module/ScsthubDashboard';
import ScsthubavpEvalList from './scsthubavp-module/ScsthubavpEvalList';
import Tanfundjurydashboard from './tanseed-module/TanfundJury/Tanfundjurydashboard';
import Partnersjurylist from './tanseed-module/TanfundJury/Partnersjurylist';
import TanseedJuryLayout from './tanseed-module/TanfundJury/TanseedJuryLayout';
import Documentscst from './startup-module/dashboard-scst-page/Documentscst';
import Reports from './scsthubavp-module/reports-details/Reports';
import Scsthubdashboard from './scst-page/Scsthubdashboard';
import PreliminaryDocumentPart1 from './startup-module/dashboard-scst-page/preliminary-document/PreliminaryDocumentPart1';
import PreliminaryDocumentPart2 from './startup-module/dashboard-scst-page/preliminary-document/PreliminaryDocumentPart2';
import FddSectionA from './startup-module/dashboard-scst-page/fdd-documents/FddSectionA';
import FDDPriorityDocu from './startup-module/dashboard-scst-page/fdd-documents/FDDPriorityDocu';
import FddSectionC from './startup-module/dashboard-scst-page/fdd-documents/FddSectionC';
import FddSectionD from './startup-module/dashboard-scst-page/fdd-documents/FddSectionD';
import FddSectionE from './startup-module/dashboard-scst-page/fdd-documents/FddSectionE';
import FddSectionF from './startup-module/dashboard-scst-page/fdd-documents/FddSectionF';
import FddSectionG from './startup-module/dashboard-scst-page/fdd-documents/FddSectionG';
import FddSectionBB from './startup-module/dashboard-scst-page/fdd-documents/FddSectionBB';
import FddSectionH from './startup-module/dashboard-scst-page/fdd-documents/FddSectionH';
import FddSectionI from './startup-module/dashboard-scst-page/fdd-documents/FddSectionI';
import FddSectionJ from './startup-module/dashboard-scst-page/fdd-documents/FddSectionJ';
import FddSectionK from './startup-module/dashboard-scst-page/fdd-documents/FddSectionK';
import FddSectionL from './startup-module/dashboard-scst-page/fdd-documents/FddSectionL';
import FddSectionM from './startup-module/dashboard-scst-page/fdd-documents/FddSectionM';
import FddSectionN from './startup-module/dashboard-scst-page/fdd-documents/FddSectionN';
import FddSectionO from './startup-module/dashboard-scst-page/fdd-documents/FddSectionO';
import FddSectionP from './startup-module/dashboard-scst-page/fdd-documents/FddSectionP';
import FddSectionQ from './startup-module/dashboard-scst-page/fdd-documents/FddSectionQ';
import FddSectionR from './startup-module/dashboard-scst-page/fdd-documents/FddSectionR';
import FddSectionS from './startup-module/dashboard-scst-page/fdd-documents/FddSectionS';
import FddSectionT from './startup-module/dashboard-scst-page/fdd-documents/FddSectionT';
import FddSectionU from './startup-module/dashboard-scst-page/fdd-documents/FddSectionU';
import FddSectionV from './startup-module/dashboard-scst-page/fdd-documents/FddSectionV';
import FddSectionW from './startup-module/dashboard-scst-page/fdd-documents/FddSectionW';
import FddSectionX from './startup-module/dashboard-scst-page/fdd-documents/FddSectionX';
import FddSectionY from './startup-module/dashboard-scst-page/fdd-documents/FddSectionY';
import FddSectionZ from './startup-module/dashboard-scst-page/fdd-documents/FddSectionZ';
import FddSectionAA from './startup-module/dashboard-scst-page/fdd-documents/FddSectionAA';
import FddSectionAB from './startup-module/dashboard-scst-page/fdd-documents/FddSectionAB';

import Scstimportpage from './scsthubavp-module/Scstimportpage';

import FDDAdditionalDoc1 from './startup-module/dashboard-scst-page/fdd-documents/FDDAdditionalDoc1';
import FDDAdditionalDoc2 from './startup-module/dashboard-scst-page/fdd-documents/FDDAdditionalDoc2';
import { Dialog, DialogContent } from '@mui/material';

const override = {
  display: "flex",
  margin: "0 auto",
  borderColor: "red",
};
const Baseroute = () => {
  const { navigator,pageLoading } = useContext(DataContext)
  const [tokOpen, setTokOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  // useEffect(() => {
  //   checkAuthToken();
  // }, []);
  // const handleClickOpen = () => {
  //   setTokOpen(true);
  // };
  // const handleModalClose = () => {
  //   setTokOpen(false);
  // };
  //   const extractAuthToken = (token) => {
  //     debugger
  //     try {
  //       return JSON.parse(atob(token.split('.')[1]));
  //     } catch (e) {
  //       return null;
  //     }
  //   };
  //   const checkAuthToken = () => {
  //     const authToken = localStorage.getItem('jwttoken');
  //     const tokenData = extractAuthToken(authToken);
      
  //     if (authToken && tokenData) {
        
  //       const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds
  //       debugger
  //       if (currentTime <= tokenData.exp) {
  //         debugger
  //         console.log("Token expired, redirecting to login...");
  //         setTokOpen(true);
  //         localStorage.clear();
  //         setTimeout(() => {
  //           handleModalClose()
  //         }, 3000);
  //         navigator('/login');
  //       }
  //     }
  //   }

  // const [pageLoading, setPageLoading] = useState(false); 
  return (
    <div>
      {pageLoading && (
        <div className='loader_div'>
          <div className="ring"></div>
          <div className="ring"></div>
          <div className="ring"></div>
        </div>
      )}
      <Navbar />
      <main>
        <Routes>
          <Route path="/" element={<Layout />} >
            <Route index element={<Home />} />
            <Route path='/about-us' element={<Aboutus />} />
            <Route path='/fundingpartners/:name' element={<Fundingpartners />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<Home />} />
            <Route path="/tanseed" element={<Tanseed />} />
            <Route path="/tnscststartupfund" element={<TnScstStartupFund />} />
            <Route path="/login" element={<Firstlogin />} />
            <Route path="/forgot" element={<Forgotpassword />} />
            <Route path="/startup/sign-up" element={<Startupsignup />} />
            <Route path='/sign-up/verify' element={<Signupverify />} />
            <Route path='/startup/info' element={<Signupinfo />} />
            <Route path="/investor/sign-up" element={<Investorsignup />} />
            <Route path='/investor/verify' element={<Investorverify />} />
          </Route>

          <Route path='/admin' element={<Adminlayout />}>
            <Route path='/admin/dashboard' element={<Admindashboard />} />
            <Route path='/admin/partner' element={<Partnerlist />} />
            {/* <Route path='/admin/investor' element={<Investorslist />} /> */}
            <Route path='/admin/jury' element={<Adminjurylist path={'admin'} />} />
            <Route path='/admin/startup' element={<Adminstartuplist />} />
            <Route path='/admin/startup/details/:startupId' element={<TanseedAssociateddetails name={'tanseedadmin'} path={'tanseed/partner'} endpoint={'startups'} />} />
            <Route path='/admin/associated-startups' element={<Adminassociated name={'admin'} path={'admin'} endpoint={'startups'} />} />
            <Route path='/admin/associated-startups/details/:startupId/:investorId' element={<TanseedAssociateddetails name={'tanseedadmin'} path={'tanseed/partner'} endpoint={'startups'} />} />
            <Route path='/admin/tanseed' element={<Admintanseed />} />
            <Route path='/admin/tanseed/details/:startupId' element={<AdminTanseedDetails name={'tanseedadmin'} path={'tanseed/partner'} endpoint={'startups'} />} />
            <Route path='/admin/investor' element={<Admininvestorroute />}>
              <Route path="/admin/investor" element={<Navigate replace to="/admin/investor/list" />} />
              <Route path='/admin/investor/list' element={<Investorslist />} />
              <Route path='/admin/investor/add' element={<Investorprofile />} />
              <Route path='/admin/investor/update/:id' element={<Investorprofile />} />
            </Route>
          </Route>

          <Route path='/partner' element={<Partnerlayout />}>
            <Route path='/partner/profile' element={<Partnerprofile />} />
            <Route path='/partner/jury' element={<Jurylist path={'partner'} name={'Jury'} />} />
            <Route path='/partner/jury-add' element={<Jurysave />} />
            <Route path='/partner/round' element={<Roundroute />}>
              <Route path="/partner/round" element={<Navigate replace to="/partner/round/list" />} />
              <Route path='/partner/round/list' element={<Roundlist name={'partner'} path={'partner'} />} />
              <Route path='/partner/round/add' element={<Roundadd name={'partner'} path={'partner'} />} />
              <Route path='/partner/round/update' element={<Roundadd name={'partner'} path={'partner'} />} />
            </Route>
            <Route path='/partner/startup' element={<Startuproute />}>
              <Route path="/partner/startup" element={<Navigate replace to="/partner/startup/list" />} />
              <Route path='/partner/startup/list' element={<Startuplist name={'partner'} path={'partner'} endpoint={'startups'} />} />
              <Route path='/partner/startup/details/:startupId/:id' element={<Startupdetails name={'partner'} path={'partner'} endpoint={'startups'} />} />
            </Route>
          </Route>

          <Route path='/investor' element={<Investorlayout />}>
            <Route path='/investor/profile' element={<Investorroute />}>
              <Route path="/investor/profile" element={<Navigate replace to="/investor/profile/details" />} />
              <Route path='/investor/profile/details' element={<Investorprofiledetails />} />
              <Route path='/investor/profile/preference' element={<Investorpreference />} />
              <Route path='/investor/profile/eoi' element={<Investoreoi />} />
            </Route>
            <Route path='/investor/tanseed' element={<Tanseedroute />} />
            <Route path='/investor/jury' element={<Jurylist path={'investor'} name={'Jury'} />} />
            <Route path='/investor/jury-add' element={<Jurysave />} />
            <Route path='/investor/round' element={<Roundroute />}>
              <Route path="/investor/round" element={<Navigate replace to="/investor/round/list" />} />
              <Route path='/investor/round/list' element={<Roundlist name={'partner'} path={'investor'} />} />
              <Route path='/investor/round/add' element={<Roundadd name={'partner'} path={'investor'} />} />
              <Route path='/investor/round/update' element={<Roundadd name={'partner'} path={'investor'} />} />
            </Route>
            <Route path='/investor/associated-startups' element={<Associatedroute />}>
              <Route path="/investor/associated-startups" element={<Navigate replace to="/investor/associated-startups/list" />} />
              <Route path='/investor/associated-startups/list' element={<Associatedlisttab name={'partner'} path={'investor'} endpoint={'startups'} />} />
              <Route path='/investor/associated-startups/details/:startupId/:id' element={<Associateddetails name={'partner'} path={'investor/associated-startups'} endpoint={'startups'} />} />
            </Route>
          </Route>

          <Route path='/scst' element={<Scstlayout />}>
            <Route path='/scst/hubdashboard' element={<Scsthubdashboard />} />
            <Route path="/scst/startups" element={<Navigate replace to="/scst/startups/validation" />} />
            <Route path='/scst/startups/validation' element={<Scstlist round={'1'} path={'validation'} />} />
            <Route path='/scst/startups/validation/details/:startupId/:id' element={<ScstDetails path={'validation'} />} />
            <Route path='/scst/startups/evaluation' element={<Scstlist round={'2'} path={'evaluation'} />} />
            <Route path='/scst/startups/evaluation/details/:startupId/:id' element={<Scstevaluationdetails path={'evaluation'} />} />
            <Route path='/scst/evaluator' element={<Scstjurylist path={'scst'} />} />
          </Route>

          <Route path='/scstadmin' element={<Scsthubavplayout role={'scstadmin'} />}>
            <Route path='/scstadmin/dashboard' element={<ScsthubDashboard />} />
            <Route path='/scstadmin/startups' element={<Scsthubavproute />}>
              <Route path="/scstadmin/startups" element={<Navigate replace to="/scstadmin/startups/list" />} />
              <Route path='/scstadmin/startups/list' element={<Scsthubavplist role={'scstadmin'} />} />
              <Route path='/scstadmin/startups/:startupId/:id' element={<Scsthubavpdetails role={'scstadmin'} />} />
            </Route>
            <Route path='/scstadmin/report' element={<Reports />} />
            <Route path='/scstadmin/import-page' element={<Scstimportpage />} />
            {/* <Route path='/scstadmin/evaluator' element={<Jurylist path={'scstadmin'} name={'Evaluator'} />} />*/}
            {/* <Route path='/scstadmin/assign-evaluator' element={<Assignjurylist name={'scstadmin'} />} /> */}
          </Route>
          <Route path='/ic' element={<ScstBddLayout role={'ic'} />}>
            <Route path='/ic/startups' element={<ScstBddroute />}>
              <Route path="/ic/startups" element={<Navigate replace to="/ic/startups/list" />} />
              <Route path='/ic/startups/list' element={<ScstBddlist role={'ic'} name={'IC Startup'} />} />
              <Route path='/ic/startups/:startupId/:id' element={<ScstDetails path={'ic'} />} />
            </Route>
          </Route>
          <Route path='/psc' element={<ScstBddLayout role={'psc'} />}>
            <Route path='/psc/startups' element={<ScstBddroute />}>
              <Route path="/psc/startups" element={<Navigate replace to="/psc/startups/list" />} />
              <Route path='/psc/startups/list' element={<ScstBddlist role={'psc'} name={'PSC Startup'} />} />
              <Route path='/psc/startups/:startupId/:id' element={<ScstDetails path={'psc'} />} />
            </Route>
          </Route>

          <Route path='/scsthubavp' element={<Scsthubavplayout role={'scsthubavp'} />}>
            <Route path='/scsthubavp/dashboard' element={<ScsthubDashboard role={'scsthubavp'} />} />
            <Route path='/scsthubavp/startups' element={<Scsthubavproute />}>
              <Route path="/scsthubavp/startups" element={<Navigate replace to="/scsthubavp/startups/list" />} />

              <Route path='/scsthubavp/startups/list' element={<Scsthubavplist role={'scsthubavp'} />} />

              <Route path='/scsthubavp/startups/evaluators/list' element={<ScsthubavpEvalList role={'scsthubavp'} />} />
              <Route path='/scsthubavp/startups/:startupId/:id' element={<Scsthubavpdetails role={'scsthubavp'} />} />
              {/* <ScstDetails path={'scsthubavp'} /> */}
            </Route>
            <Route path='/scsthubavp/evaluator' element={<Jurylist path={'scsthubavp'} name={'Evaluator'} />} />
            {/* <Route path='/scsthubavp/evaluator-add' element={<Jurysave />} /> */}
            <Route path='/scsthubavp/assign-evaluator' element={<Assignjurylist name={'scsthubavp'} />} />
            <Route path='/scsthubavp/report' element={<Reports />} />
          </Route>



          <Route path='/scstbdd' element={<ScstBddLayout role={'scstbdd'} />}>
            <Route path='/scstbdd/startups' element={<ScstBddroute />}>
              <Route path="/scstbdd/startups" element={<Navigate replace to="/scstbdd/startups/list" />} />
              <Route path='/scstbdd/startups/list' element={<ScstBddlist role={'scstbdd'} name={'BDD Startup'} />} />
              {/* <ScstBdddetails role={'scstbdd'} /> */}
              <Route path='/scstbdd/startups/:startupId/:id' element={<ScstDetails path={'scstbdd'} />} />
            </Route>
            {/* <Route path='/scsthubavp/evaluator' element={<Jurylist path={'scsthubavp'} name={'Evaluator'} />} />
            <Route path='/scsthubavp/evaluator-add' element={<Jurysave />} />
            <Route path='/scsthubavp/assign-evaluator' element={<Assignjurylist name={'scsthubavp'} />} /> */}
          </Route>
          <Route path='/scstldd' element={<ScstBddLayout role={'scstldd'} />}>
            <Route path='/scstldd/startups' element={<ScstBddroute />}>
              <Route path="/scstldd/startups" element={<Navigate replace to="/scstldd/startups/list" />} />
              <Route path='/scstldd/startups/list' element={<ScstBddlist role={'scstldd'} name={'LDD Startup'} />} />
              <Route path='/scstldd/startups/:startupId/:id' element={<ScstDetails path={'scstldd'} />} />
            </Route>
          </Route>
          <Route path='/scstfdd' element={<ScstBddLayout role={'scstfdd'} />}>
            <Route path='/scstfdd/startups' element={<ScstBddroute />}>
              <Route path="/scstfdd/startups" element={<Navigate replace to="/scstfdd/startups/list" />} />
              <Route path='/scstfdd/startups/list' element={<ScstBddlist role={'scstfdd'} name={'FDD Startup'} />} />
              <Route path='/scstfdd/startups/:startupId/:id' element={<ScstDetails path={'scstfdd'} />} />
            </Route>
          </Route>
          <Route path='/scstmentorship' element={<ScstMentorshipLayout role={'scstmentorship'} />}>
            <Route path='/scstmentorship/startups' element={<Scstmentorshiproute />}>
              <Route path="/scstmentorship/startups" element={<Navigate replace to="/scstmentorship/startups/list" />} />
              <Route path='/scstmentorship/startups/list' element={<Scstmentorshiplist role={'scstmentorship'} />} />
              <Route path='/scstmentorship/startups/:startupId/:id' element={<Scstmentorshipdetails role={'scstmentorship'} />} />
            </Route>
            {/* <Route path='/scsthubavp/evaluator' element={<Jurylist path={'scsthubavp'} name={'Evaluator'} />} />
            <Route path='/scsthubavp/evaluator-add' element={<Jurysave />} />
            <Route path='/scsthubavp/assign-evaluator' element={<Assignjurylist name={'scsthubavp'} />} /> */}
          </Route>

          <Route path='/jury' element={<Jurylayout />}>
            <Route path='/jury/tanfund' element={<Associatedroute />}>
              <Route path="/jury/tanfund" element={<Navigate replace to="/jury/tanfund/list" />} />
              <Route path='/jury/tanfund/list' element={<Juryassociatedlist name={'jury'} path={'jury/tanfund'} endpoint={'startup'} value={'tanfund'} />} />
              <Route path='/jury/tanfund/details/:startupId/:id' element={<Associateddetails name={'jury'} path={'jury/tanfund'} endpoint={'startup'} />} />
            </Route>
            <Route path='/jury/scst' element={<Associatedroute />}>
              <Route path="/jury/scst" element={<Navigate replace to="/jury/scst/list" />} />
              <Route path='/jury/scst/list' element={<Juryassociatedlist name={'jury'} path={'jury/scst'} endpoint={'startup'} value={'scst'} />} />
              <Route path='/jury/scst/details/:startupId/:id' element={<Associateddetails name={'jury'} path={'jury/scst'} endpoint={'startup'} />} />
            </Route>
            <Route path='/jury/tanseed' element={<Associatedroute />}>
              <Route path="/jury/tanseed" element={<Navigate replace to="/jury/tanseed/list" />} />
              <Route path='/jury/tanseed/list' element={<Juryassociatedlist name={'jury'} path={'jury/tanseed'} endpoint={'startup'} value={'tanseed'} />} />
              <Route path='/jury/tanseed/details/:startupId/:id' element={<Associateddetails name={'jury'} path={'jury/tanseed'} endpoint={'startup'} />} />
            </Route>
            {/* <Route path='/jury/zoom' element={<Zoomroute />}>
                  <Route path="/jury/zoom" element={<Navigate replace to="/jury/zoom/list" />} />
                  <Route path='/jury/zoom/list' element={<Zoomlist />} />
                  <Route path='/jury/zoom/scheduler' element={<Zoomscheduler />} />
                </Route> */}
            {/* <Route path='/jury/scst/list' element={<Juryassociatedlist name={'jury'} path={'jury'} endpoint={'startup'} value={'scst'} />} /> */}
          </Route>

          <Route path='/tanseed' element={<Tanseedlayout />}>
            <Route path='/tanseed/partner' element={<TanseedPartnerLayout />}>
              <Route path="/tanseed/partner" element={<Navigate replace to="/tanseed/partner/dashboard" />} />
              <Route path='/tanseed/partner/dashboard' element={<TanseedDashboard />} />
              <Route path='/tanseed/partner/:id' element={<Partnerslist />} />
              <Route path='/tanseed/partner/details/:startupId/:id/:investorId' element={<TanseedAssociateddetails name={'tanseedadmin'} path={'tanseed/partner'} endpoint={'startups'} />} />
            </Route>
            <Route path='/tanseed/startups' element={<Tanseedstartuplist />} />
            {/* <Route path='/tanseed/juries' element={<Tanseedstartuplist />} /> */}
            <Route path='/tanseed/juries' element={<TanseedJuryLayout />} >
              <Route path="/tanseed/juries" element={<Navigate replace to="/tanseed/juries/dashboard" />} />
              <Route path='/tanseed/juries/dashboard' element={<Tanfundjurydashboard />} />
              <Route path='/tanseed/juries/:id' element={<Partnersjurylist />} />
              <Route path='/tanseed/juries/details/:startupId/:id/:investorId' element={<TanseedAssociateddetails name={'tanseedadmin'} path={'tanseed/juries'} endpoint={'startups'} />} />
            </Route>
          </Route>

          <Route path='/startup' element={<Startuplayout />}>
            <Route path='/startup/dashboard' element={<Dashboard />} />
            <Route path='/startup/application' element={<Application />} />
            <Route path='/startup/funding-financials' element={<Fundingfinancials />} />
            <Route path='/startup/team' element={<Team />} />
            <Route path='/startup/incubation-accelaration' element={<Incubationaccprogram />} />
            <Route path='/startup/product-market' element={<Productandmarket />} />
            <Route path='/startup/business-strategy' element={<Businessstrategy />} />
            <Route path='/startup/traction-achievements' element={<Tractionandachievements />} />
            <Route path='/startup/funding-utilisation' element={<Fundingutilization />} />
            <Route path='/startup/funding-utilisation' element={<Fundingutilization />} />
            <Route path='/startup/documents-upload' element={<Documentsupload />} />
            <Route path='/startup/self-declaration' element={<Termsandcondition />} />
            {/* <Route path='/startup/progress' element={<Progresslist />} /> */}
            <Route path='/startup/form-submitted' element={<Selfdeclarmsg />} />
            <Route path='/startup/all-dashboard' element={<AllStartupDashboardLayout />}>
              <Route path="/startup/all-dashboard" element={<Navigate replace to="/startup/all-dashboard/scst" />} />
              <Route path='/startup/all-dashboard/tanfund' element={<Progresslist name={'tanfund'} />} />
              <Route path='/startup/all-dashboard/tanseed' element={<Progresslist name={'tanseed'} />} />
              <Route path='/startup/all-dashboard/scst' element={<Progresslist name={'scst'} />} />
            </Route>
            <Route path='/startup/tanseed' element={<Tanseedpage />} />
            <Route path='/startup/dashboard/scst' element={<Dashboardscst />} />
            <Route path='/startup/application/scst' element={<Applicationscst />} />
            <Route path='/startup/funding-financials/scst' element={<Fundingscst />} />
            <Route path='/startup/founder/scst' element={<Founderscst />} />
            <Route path='/startup/documents-upload/scst' element={<Documentscst />} />
            <Route path='/startup/incubation-accelaration/scst' element={<Incubationaccprogramscst />} />
            <Route path='/startup/product-market/scst' element={<ProductMarketscst />} />
            <Route path='/startup/business-strategy/scst' element={<BusinessStrategyScst />} />
            <Route path='/startup/team/scst' element={<TeamInfoscst />} />
            <Route path='/startup/market&competitors/scst' element={<MarketCompetitorsScst />} />
            <Route path='/startup/ldd-document/scst' element={<PreliminaryDocumentPart1 />} />
            <Route path='/startup/ldd-document1/scst' element={<PreliminaryDocumentPart2 />} />
            {/* FDD  */}
            <Route path='/startup/scst/fdd-document/priority' element={<FDDPriorityDocu />} />
            <Route path='/startup/scst/fdd-document/additionaldoc-1' element={<FDDAdditionalDoc1 />} />
            <Route path='/startup/scst/fdd-document/additionaldoc-2' element={<FDDAdditionalDoc2 />} />
            <Route path='/startup/scst/fdd-document/section-a' element={<FddSectionA />} />
            <Route path='/startup/scst/fdd-document/section-b' element={<FddSectionBB />} />
            <Route path='/startup/scst/fdd-document/section-c' element={<FddSectionC />} />
            <Route path='/startup/scst/fdd-document/section-d' element={<FddSectionD />} />
            <Route path='/startup/scst/fdd-document/section-e' element={<FddSectionE />} />
            <Route path='/startup/scst/fdd-document/section-f' element={<FddSectionF />} />
            <Route path='/startup/scst/fdd-document/section-g' element={<FddSectionG />} />
            <Route path='/startup/scst/fdd-document/section-h' element={<FddSectionH />} />
            <Route path='/startup/scst/fdd-document/section-i' element={<FddSectionI />} />
            <Route path='/startup/scst/fdd-document/section-j' element={<FddSectionJ />} />
            <Route path='/startup/scst/fdd-document/section-k' element={<FddSectionK />} />
            <Route path='/startup/scst/fdd-document/section-l' element={<FddSectionL />} />
            <Route path='/startup/scst/fdd-document/section-m' element={<FddSectionM />} />
            <Route path='/startup/scst/fdd-document/section-n' element={<FddSectionN />} />
            <Route path='/startup/scst/fdd-document/section-o' element={<FddSectionO />} />
            <Route path='/startup/scst/fdd-document/section-p' element={<FddSectionP />} />
            <Route path='/startup/scst/fdd-document/section-q' element={<FddSectionQ />} />
            <Route path='/startup/scst/fdd-document/section-r' element={<FddSectionR />} />
            <Route path='/startup/scst/fdd-document/section-s' element={<FddSectionS />} />
            <Route path='/startup/scst/fdd-document/section-t' element={<FddSectionT />} />
            <Route path='/startup/scst/fdd-document/section-u' element={<FddSectionU />} />
            <Route path='/startup/scst/fdd-document/section-v' element={<FddSectionV />} />
            <Route path='/startup/scst/fdd-document/section-w' element={<FddSectionW />} />
            <Route path='/startup/scst/fdd-document/section-x' element={<FddSectionX />} />
            <Route path='/startup/scst/fdd-document/section-y' element={<FddSectionY />} />
            <Route path='/startup/scst/fdd-document/section-z' element={<FddSectionZ />} />
            <Route path='/startup/scst/fdd-document/section-aa' element={<FddSectionAA />} />
            <Route path='/startup/scst/fdd-document/section-ab' element={<FddSectionAB />} />

            <Route path='/startup/self-declaration/scst' element={<Termsconditionscst />} />
            {/* <Route path='/startup/associated-hub' element={<Progresslistscst />} /> */}
            <Route path='/startup/dashboard/tanseed' element={<Dashboardtanseed />} />
            <Route path='/startup/application/tanseed' element={<Applicationtanseed />} />
            <Route path='/startup/funding-financials/tanseed' element={<FundingTanseed />} />
            <Route path='/startup/fundraisedhistory/tanseed' element={<Fundraisedhistory />} />
            <Route path='/startup/Teaminfo/tanseed' element={<Teaminfotanseed />} />
            <Route path='/startup/mentor/tanseed' element={<AdvisoryMentor />} />
            <Route path='/startup/traction-achievements/tanseed' element={<Tractionandacheivementtanseed />} />
            <Route path='/startup/achievements/tanseed' element={<Achievementstanseed />} />
            <Route path='/startup/documents-upload/tanseed' element={<DocumentsUploadtanseed />} />
            {/* <Route path='/startup/self-declaration/scst' element={<Termsconditionscst />} />
            <Route path='/startup/associated-hub' element={<Progresslistscst />} /> */}
            <Route path='/startup/investor/tanfund' element={<InvestorConnectDashboard />} />
            <Route path='/startup/investor/application' element={<InvestorApplication />} />
            <Route path='/startup/investor/funding' element={<InvestorFunding />} />
            <Route path='/startup/investor/Team' element={<InvestorTeaminfo />} />
            <Route path='/startup/investor/Documents' element={<InvestorDocuments />} />
            <Route path='/startup/self-declaration/investor' element={<Termsandconditioninvestor />} />
          </Route>

          <Route path='/ic-admin' element={<ICAdminLayout />}>
            <Route path="/ic-admin/startup" element={<Navigate replace to="/ic-admin/startup/list" />} />
            <Route path='/ic-admin/startup/list' element={<ICStartupList />} />
            {/*  */}
          </Route>

          <Route path='/under-maintenance' element={<Undermaintenance />} />
          <Route path='/sesstion-timeout' element={<Sesstiontimeout />} />
          <Route path='*' element={<Pagenotfound />} />
        </Routes>
      </main>
      {/* <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={tokOpen}
          onClose={handleModalClose}
        >
          <DialogContent>
          <div className="text-center">
              <img src={'/images/session_exp/expired.gif'} alt="" className="sucess-image" />
              <h4>Whoops, Your session has expired.</h4>
              <p>No Worry,simply login again</p>
            </div>
          </DialogContent>
        </Dialog> */}
    </div>
  )
}

export default Baseroute