import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import Viewimage from '../../../../../api/Viewimage';
import Paper from '@mui/material/Paper';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import Viewimage from '../../api/Viewimage';

const Applicationscst = ({ details, detail }) => {
    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState('')
    const handleClickOpen = (value) => {
        setViewImage(value)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <section>

                {/* <div className='rec-info1 mt-4'>
                    <span className='d-flex'><img src='/images/purple-icon.png' alt='' className='purple-star mr-3' /><b><span className='grey-clr'>Incubated from </span><span className='purple-clr'>TAMILNADU AGRICULTURE COLLEGE</span></b></span>
                </div>
                <div className='rec-info2 mt-3'>
                    <span className='d-flex'><img src='/images/blue-icon.png' alt='' className='purple-star mr-3' /><b><span className='blue-clr'>IAF award, Recognized by TNAU</span></b></span>
                </div> */}
                <div className=''>
                    {/* <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Application Overview" value="1" />
                                </TabList>
                            </Box>
                            <TabPanel value="1" className='application '>
                                
                            </TabPanel>
                        </TabContext>
                    </Box> */}


                    {/* <div>
                        <h4 className='font-weight-bold my-4' style={{ fontFamily: "Inter, sans-serif" }}>Personal Information</h4>
                        <div className='rows'>
                            <div className='row'>
                                <div className='col-lg-4 mb-3'>
                                    <p><b>Legal Entity :</b></p>
                                    <p>{details.entityType ? (details.entityType) : (`-`)}</p>
                                </div>
                                <div className='col-lg-4 mb-3'>
                                    <p><b>Are you DPIIT Registered? :</b></p>
                                    <p>{details.dpiitRegNumber === true ? (`Yes`) : ('No')}</p>
                                </div>
                                <div className='col-lg-4 mb-3'>
                                    <p ><b>DPIIT Registred No :</b></p>
                                    <p>{details.dpiitRegNumber ? (details.dpiitRegNumber) : (`-`)}</p>
                                </div>
                                <div className='col-lg-4 mb-3'>
                                    <p ><b>DPIIT Registration Certificate :</b></p>
                                    {
                                        details.dpiitCertificateName ? (
                                            <div className='ml-auto'>
                                                <span className='viewdoc' onClick={() => handleClickOpen(details.dpiitCertificateName)}>View Document</span>
                                            </div>
                                        ) : (`-`)
                                    }

                                </div>
                                <div className='col-lg-4 mb-3'>
                                    <p ><b>CIN/PAN/Udyam Registration No :</b></p>
                                    <p>{details.certificateNumber ? (details.certificateNumber) : `-`}</p>
                                </div>
                                <div className='col-lg-4 mb-3'>
                                    <p ><b>CIN/PAN/Udayam Registration Certificate :</b></p>
                                    {
                                        details.inCorpCertificateName ? (
                                            <div className='ml-auto'>
                                                <span className='viewdoc' onClick={() => handleClickOpen(details.inCorpCertificateName)}>View Document</span>
                                            </div>
                                        ) : (`-`)
                                    }

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4 mb-3'>
                                    <p><b>Address :</b></p>
                                    <p>{details.address ? (details.address) : (`-`)}</p>
                                </div>
                                <div className='col-lg-4 mb-3'>
                                    <p><b>City :</b></p>
                                    <p>{details.city ? (details.city) : (`-`)}</p>
                                </div>
                                <div className='col-lg-4 mb-3'>
                                    <p><b>State :</b></p>
                                    <p>{details.state ? (details.state) : (`-`)}</p>
                                </div>
                                <div className='col-lg-4 mb-3'>
                                    <p><b>Country :</b></p>
                                    <p>{details.country ? (details.country) : (`-`)}</p>
                                </div>
                                <div className='col-lg-4 mb-3'>
                                    <p><b>Pincode :</b></p>
                                    <p>{details.pincode ? (details.pincode) : (`-`)}</p>
                                </div>
                            </div>
                        </div>
                        <h4 className='font-weight-bold my-4' style={{ fontFamily: "Inter, sans-serif" }}>Brief Description of your Startup :</h4>
                        <p className='mt-4'>
                            {details.briefStartupDescription ? (details.briefStartupDescription) : (`-`)}
                        </p>
                        <h4 className='font-weight-bold my-4' style={{ fontFamily: "Inter, sans-serif" }}>How Unique You Are  :</h4>
                        <p className='mt-4'>
                            {details.uniqueDesc ? (details.uniqueDesc) : (`-`)}
                        </p>
                    </div> */}


                    {/* <h4 className='font-weight-bold my-4' style={{ fontFamily: "Inter, sans-serif" }}>Brief about your problem statement :</h4>
                    <p className='mt-4'>
                        {details.briefProblemStatement}
                    </p>
                    <h4 className='font-weight-bold my-4' style={{ fontFamily: "Inter, sans-serif" }}>Brief about your proposed solution :</h4>
                    <p className='mt-4'>
                        {details.briefProposedSolution}
                    </p> */}
                </div>
            </section>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Grid item sm={12} md={4} lg={4}>
                    <p><b>Legal Entity :</b></p>
                    <p>{details.entityType ? (details.entityType) : (`NA`)}</p>

                    <p ><b>Hub :</b></p>
                    <p>{detail.partnerName ? (detail.partnerName) : `NA`}</p>


                    <p ><b>Address :</b></p>
                    <p className='address-mar'>{details.address ? (details.address) : (`NA`)}</p>


                    {/* <p><b>Country :</b></p>
                    <p>{details.country ? (details.country) : (`NA`)}</p> */}


                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                    {
                        details.entityType === 'Private Limited' && (
                            <>
                                <p className='fontchange'><b>CIN Certificate :</b></p>
                                <div>
                                    <p className='doc-flex'>{details.certificateNumber ? (details.certificateNumber) : (
                                        <span style={{ color: 'red' }}>Document NA</span>
                                    )}{
                                            details.inCorpCertificateName && (
                                                <div className='ml-3'>
                                                    <span className='viewdoc1' onClick={() => handleClickOpen(details.inCorpCertificateName)}>View Document</span>
                                                </div>
                                            )
                                        }</p>
                                </div>
                                {/* <div>
                                    <p className='doc-flex'>
                                        {
                                            details.moaDocName && (
                                                <div className='ml-3'>
                                                    <span className='viewdoc1' onClick={() => handleClickOpen(details.moaDocName)}>View Document</span>
                                                </div>
                                            )
                                        }
                                    </p>
                                </div> */}
                            </>
                        )
                    }
                    {
                        details.entityType === 'Private Limited' && (
                            <>
                                <p className='fontchange'><b>MOA Certificate :</b></p>
                                <div>
                                    <p className='doc-flex'>{
                                        details.moaDocName ? (
                                            <div className=''>
                                                <span className='viewdoc1' onClick={() => handleClickOpen(details.moaDocName)}>View Document</span>
                                            </div>
                                        ) : (
                                            (
                                                <span style={{ color: 'red' }}>Document NA</span>
                                            )
                                        )
                                    }</p>
                                </div>
                            </>
                        )
                    }
                    {
                        details.entityType === 'Proprietorship' && (
                            <>
                                <p className='fontchange'><b>Udyam Certificate :</b></p>
                                <div>
                                    <p className='doc-flex'>
                                        {/* {details.certificateNumber ? (details.certificateNumber) : `NA`} */}
                                        {
                                            details.udayamCertificateName ? (
                                                <div className=''>
                                                    <span className='viewdoc1' onClick={() => handleClickOpen(details.udayamCertificateName)}>View Document</span>
                                                </div>
                                            ) : (<>
                                                (
                                                <span style={{ color: 'red' }}>Document NA</span>
                                                )
                                            </>)
                                        }
                                    </p>
                                </div>
                            </>
                        )
                    }
                    {
                        details.entityType === 'Registered Partnership' && (
                            <>
                                <p className='fontchange'><b>Partnership Deed Certificate :</b></p>
                                <div>
                                    <p className='doc-flex'>
                                        {/* {details.certificateNumber ? (details.certificateNumber) : `NA`} */}
                                        {
                                            details.partnershipDeedName ? (
                                                <div className=''>
                                                    <span className='viewdoc1' onClick={() => handleClickOpen(details.partnershipDeedName)}>View Document</span>
                                                </div>
                                            ) : (<>
                                                (
                                                <span style={{ color: 'red' }}>Document NA</span>
                                                )
                                            </>)
                                        }
                                    </p>
                                </div>
                            </>
                        )
                    }
                    {/* {
                        details.entityType === 'LLP' && (
                            <>
                                <p className='fontchange'><b>CIN/PAN/Udyam/Partnership Deed Certificate :</b></p>
                                <div>
                                    <p className='doc-flex'>
                                        {details.certificateNumber ? (details.certificateNumber) : `NA`}
                                    </p>
                                </div>
                            </>
                        )
                    } */}
                    {/* {
                        details.entityType === 'Other' && (
                            <>
                                <p className='fontchange'><b>CIN/PAN/Udyam Registration No & Certificate :</b></p>
                                <div>
                                    <p className='doc-flex'>
                                        {details.certificateNumber ? (details.certificateNumber) : `NA`}
                                    </p>
                                </div>
                            </>
                        )
                    } */}
                    {/* <p className='fontchange'><b>CIN/PAN/Udyam Registration No & Certificate :</b></p> */}


                    <p ><b>Email :</b></p>
                    <p>{detail.startupEmail ? (detail.startupEmail) : (`NA`)}</p>


                    {/* <p><b>State :</b></p>
                    <p>{details.state ? (details.state) : (`NA`)}</p>
                    <p ><b>City :</b></p>
                    <p>{details.city ? (details.city) : (`NA`)}</p> */}

                    <p><b>City, State :</b></p>
                    <p>{details.city ? (details.city) : (`NA`)}, {details.state ? (details.state) : (`NA`)}</p>

                    {/* <p><b>Date of Incorporation :</b></p>
                    <p>{details.incorporatedDate ? (details.incorporatedDate) : (`NA`)}</p> */}

                    <p><b>Date of Incorporation :</b></p>
                    <p>{details.incorporatedDate ? details.incorporatedDate  : 'NA'}</p>
                    {/* <p>{details.incorporatedDate ? new Date(details.incorporatedDate).toLocaleDateString('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric' }) : 'NA'}</p> */}



                </Grid>
                <Grid item sm={12} md={4} lg={4}>

                    <p ><b>DPIIT Registration Number & Certificate :</b></p>
                    <p className='doc-flex'>{details.dpiitRegNumber ? (details.dpiitRegNumber) : (
                        <span style={{ color: 'red' }}>Document NA</span>
                    )}{
                            details.dpiitCertificateName && (
                                <span className='ml-3'>
                                    <span className='viewdoc1' onClick={() => handleClickOpen(details.dpiitCertificateName)}>View Document</span>
                                </span>
                            )
                        }</p>

                    <p ><b>Number :</b></p>
                    <p>{detail.startupPhone ? (detail.startupPhone) : (`NA`)}</p>

                    <p ><b>PAN :</b></p>
                    <p>{details.panNumber ? (details.panNumber) : (`NA`)}</p>

                    <p><b>Pincode :</b></p>
                    <p>{details.pincode ? (details.pincode) : (`NA`)}</p>

                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Grid item sm={12} md={12} lg={12}>
                    <p><b>Brief Description of your Startup:</b> </p>
                    <p className='mt-4'>
                        {details.briefStartupDescription ? (details.briefStartupDescription) : (`NA`)}
                    </p>

                    <p><b>How Unique You Are:</b> </p>
                    <p className='mt-4'>
                        {details.uniqueDesc ? (details.uniqueDesc) : (`NA`)}
                    </p>
                </Grid>
            </Grid>
            <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
        </div>
    )
}

export default Applicationscst
