import React, { useContext, useState } from "react";
import apiService from "../../../api/apiService";
import DataContext from "../../../context/DataContext";
import {
  Box,
  Accordion,
  AccordionDetails,
  style,
  Switch,
  AccordionSummary,
  Button,
  styled,
  FormControl,
  FormControlLabel,
  Paper,
  MenuItem,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Autocomplete,
  Container,
} from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import notifyService from "../../../api/notifySerivce";
import cryptoJS from "../../../hooks/cryptoJS";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
const DocumentTextscst = ({
  entityType,
  setFormData,
  formData,
  readyOnlyValue,
}) => {
  // const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [readyOnlyBDDValue, setReadyOnlyBDDValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    handleBackapp,
    navigator,
    VisuallyHiddenInput,
    validateNumberonly,
    setPageLoading,
  } = useContext(DataContext);

  const {
    handleSubmit,
    setValue,
    watch,
    getValues,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    values: formData,
  });

  const {
    fields: businessCustomersFields,
    append: businessCustomersAppend,
    remove: businessCustomersRemove,
  } = useFieldArray({
    control,
    name: "vendorDetails",
  });

  const existingCustomerPresent = watch("existingCustomerPresent", false);
  const handleFilter = (isExistingCustomerPresent) => {
    setValue("existingCustomerPresent", isExistingCustomerPresent);
    if (!isExistingCustomerPresent) {
      setValue("existingCustomerPresent", "");
      businessCustomersRemove();
    } else {
      addBusinessCustomersBtn();
    }
  };
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const deleteBusinessCustomers = async (index) => {
    const id = getValues(`vendorDetails.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(`startup/vendor/remove?id=${id}`, "", "delete").then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeleteBusinessCustomers(index);
          }
        }
      });
    } else {
      indexBaseddeleteBusinessCustomers(index);
    }
  };
  const indexBaseddeleteBusinessCustomers = (index) => {
    var startupTeams = getValues("vendorDetails");
    if (startupTeams.length === 1 && index === 0) {
      var val = getValues();
      val.vendorDetails = [
        {
          id: 0,
          name: "",
          email: "",
          phone: "",
          canContact: false,
        },
      ];
      setFormData(val);
    } else {
      businessCustomersRemove(index);
      var val = getValues();
      setFormData(val);
    }
  };
  const validateEmail = (value) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValid = emailRegex.test(value);
    return isValid || "Invalid email address";
  };
  const validatePhoneNumber = (value) => {
    // const isValid = value.length === 10;
    if (value.length !== 10) {
      return "Phone number must be 10 digits";
    }
    const matches = value.match(
      /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
    );
    if (matches === null) {
      return "Only numbers allowed";
    }
  };
  const addBusinessCustomersBtn = () => {
    businessCustomersAppend({
      id: 0,
      name: "",
      email: "",
      phone: "",
      canContact: false,
    });
  };
  const onSubmit = (data) => {
    setPageLoading(true);
    var getform = data;
    debugger;
    if (getform.gstLogin !== "") {
      getform.gstLogin = cryptoJS(getform.gstLogin);
    }
    if (getform.gstPassword !== "") {
      getform.gstPassword = cryptoJS(getform.gstPassword);
    }
    if (getform.incTaxLogin !== "") {
      getform.incTaxLogin = cryptoJS(getform.incTaxLogin);
    }
    if (getform.incTaxPassword !== "") {
      getform.incTaxPassword = cryptoJS(getform.incTaxPassword);
    }
    console.log(getform);
    apiService("startup/documents/save", getform, "post").then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          notifyService("success", "Success", "Saved successfully !!");
          // navigator('/startup/founder/scst')
          handleBackapp();
        }
      }
    });
  };
  return (
    <div>
      <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form_div2">
          {(entityType === "Private Limited" ||
            entityType === "LLP" ||
            entityType === "Registered Partnership" ||
            entityType === "Proprietorship") && (
            <>
              <div className="doc-title mt-3">
                <h6>GST Portal :</h6>
              </div>
              <div className="row doc-row">
                <div className="col-lg-4  col-12">
                  <div className="form-group mt-2">
                    <Controller
                      name="gstLogin"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="User Name"
                          placeholder="Enter User Name"
                          fullWidth
                          {...field}
                          inputProps={{ readOnly: readyOnlyValue }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.gstLogin && errors.gstLogin.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-4  col-12">
                  <div className="form-group mt-2">
                    <Controller
                      name="gstPassword"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Password"
                          placeholder="Enter Password"
                          fullWidth
                          {...field}
                          inputProps={{ readOnly: readyOnlyValue }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.gstPassword && errors.gstPassword.message}
                    </FormHelperText>
                  </div>
                </div>
              </div>
              <div className="doc-title mt-3">
                <h6>Income Portal :</h6>
              </div>
              <div className="row doc-row">
                <div className="col-lg-4  col-12">
                  <div className="form-group mt-2">
                    <Controller
                      name="incTaxLogin"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="UserName"
                          placeholder="Enter UserName"
                          fullWidth
                          {...field}
                          inputProps={{ readOnly: readyOnlyValue }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.incTaxLogin && errors.incTaxLogin.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-4  col-12">
                  <div className="form-group mt-2">
                    <Controller
                      name="incTaxPassword"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label=" Password"
                          placeholder="Enter  Password"
                          fullWidth
                          {...field}
                          inputProps={{ readOnly: readyOnlyValue }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.incTaxPassword && errors.incTaxPassword.message}
                    </FormHelperText>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="doc-title mt-3">
            <h6>Other Details :</h6>
          </div>
          <div className="row doc-row">
            {entityType === "Private Limited" && (
              <div className="col-lg-4  col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="dinNos"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="DIN of all Directors & Promoters"
                        placeholder="Enter DIN of all Directors & Promoters"
                        fullWidth
                        {...field}
                        inputProps={{ readOnly: readyOnlyValue }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.dinNos && errors.dinNos.message}
                  </FormHelperText>
                </div>
              </div>
            )}
            {(entityType === "Private Limited" ||
              entityType === "LLP" ||
              entityType === "Registered Partnership" ||
              entityType === "Proprietorship") && (
              <div className="col-lg-4  col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="employeeBreakup"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Employee Breakup of the Company (Division/Sub Division Wise)"
                        placeholder="Enter (Division/Sub Division Wise)"
                        fullWidth
                        {...field}
                        inputProps={{ readOnly: readyOnlyValue }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.employeeBreakup && errors.employeeBreakup.message}
                  </FormHelperText>
                </div>
              </div>
            )}
            <div className="col-lg-4  col-12">
              <div className="form-group mt-2">
                <Controller
                  name="disOfRelaParty"
                  control={control}
                  defaultValue=""
                  rules={{ required: false }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Disclosure of Related Party Transactions"
                      placeholder="Enter Related Party Transactions"
                      fullWidth
                      {...field}
                      inputProps={{ readOnly: readyOnlyValue }}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.disOfRelaParty && errors.disOfRelaParty.message}
                </FormHelperText>
              </div>
            </div>

            {entityType === "LLP" && (
              <div className="col-lg-4  col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="dpinofallDesiPartners"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Designated Partners Identification Number (DPIN) of all Designated Partners *"
                        placeholder="Enter Partners Identification Number"
                        fullWidth
                        {...field}
                        inputProps={{ readOnly: readyOnlyValue }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.dpinofallDesiPartners &&
                      errors.dpinofallDesiPartners.message}
                  </FormHelperText>
                </div>
              </div>
            )}
            <div className="col-lg-4 col-12">
              <div className="form-group mt-2">
                <Controller
                  name="auditorConfirm"
                  control={control}
                  defaultValue=""
                  rules={{ required: false }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Involvement/non Involvement"
                      placeholder="Enter Involvement/non Involvement"
                      fullWidth
                      {...field}
                      inputProps={{ readOnly: readyOnlyValue }}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.auditorConfirm && errors.auditorConfirm.message}
                </FormHelperText>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="form-group mt-2">
                <div className="d-flex">
                  {/* <span className="mt-1">Do you have existing customers?</span> */}
                  <span className="mt-1">Do you have any Vendor</span>
                  <span className="ml-4 d-flex justify-content-center align-items-center">
                    No{" "}
                    <AntSwitch
                      className="mx-2"
                      checked={existingCustomerPresent}
                      disabled={readyOnlyValue}
                      onChange={() => handleFilter(!existingCustomerPresent)}
                      inputProps={{ "aria-label": "ant design" }}
                    />{" "}
                    Yes{" "}
                  </span>
                </div>
              </div>
            </div>

            {existingCustomerPresent === true && (
              <>
                <div className="col-lg-12 col-12 mb-3 pl-3">
                  <Accordion
                    className="Addfundacc-bor"
                    expanded={isAccordionOpen}
                    onChange={() => setIsAccordionOpen(!isAccordionOpen)}
                  >
                    <AccordionSummary
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="Addfundacc"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: 0,
                        }}
                      >
                        <AddCircleIcon className="add-icon mr-3" /> Contact
                        Details of Vendor
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="col-12 mb-3">
                          <div>
                            {businessCustomersFields.length >= 0 && (
                              <TableContainer
                                component={Paper}
                                className="table-back"
                              >
                                <Table aria-label="caption table">
                                  <TableBody>
                                    {businessCustomersFields.map(
                                      (field, index) => (
                                        <TableRow key={index}>
                                          <TableCell
                                            sx={{
                                              borderBottom: "none",
                                              padding: "5px",
                                            }}
                                          >
                                            {index + 1}
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              width: "30%",
                                              borderBottom: "none",
                                              padding: "5px",
                                            }}
                                          >
                                            <FormControl sx={{ width: "100%" }}>
                                              <Controller
                                                name={`vendorDetails.${index}.name`}
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                  required: "Name is required",
                                                }}
                                                render={({ field }) => (
                                                  <TextField
                                                    id="outlined-multiline-static"
                                                    label="Name *"
                                                    placeholder="Enter name"
                                                    fullwidth
                                                    {...field}
                                                    inputProps={{
                                                      readOnly: readyOnlyValue,
                                                    }}
                                                  />
                                                )}
                                              />
                                              <FormHelperText className="text-danger">
                                                {errors.vendorDetails?.[index]
                                                  ?.name &&
                                                  errors.vendorDetails?.[index]
                                                    ?.name.message}
                                              </FormHelperText>
                                            </FormControl>
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              width: "30%",
                                              borderBottom: "none",
                                              padding: "5px",
                                            }}
                                          >
                                            <FormControl sx={{ width: "100%" }}>
                                              <Controller
                                                name={`vendorDetails.${index}.phone`}
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                  required:
                                                    "Phone Number is required",
                                                  validate: validatePhoneNumber,
                                                }}
                                                render={({ field }) => (
                                                  <TextField
                                                    id="outlined-multiline-static"
                                                    label="Phone No*"
                                                    placeholder="Enter phone Number"
                                                    fullwidth
                                                    {...field}
                                                    inputProps={{
                                                      readOnly: readyOnlyValue,
                                                      maxLength: 10,
                                                    }}
                                                    onKeyDown={(e) =>
                                                      validateNumberonly(e)
                                                    }
                                                  />
                                                )}
                                              />
                                              <FormHelperText className="text-danger">
                                                {errors.vendorDetails?.[index]
                                                  ?.phone &&
                                                  errors.vendorDetails?.[index]
                                                    ?.phone.message}
                                              </FormHelperText>
                                            </FormControl>
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              width: "30%",
                                              borderBottom: "none",
                                              padding: "5px",
                                            }}
                                          >
                                            <FormControl sx={{ width: "100%" }}>
                                              <Controller
                                                name={`vendorDetails.${index}.email`}
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                  required: "Email is required",
                                                  validate: validateEmail,
                                                }}
                                                render={({ field }) => (
                                                  <TextField
                                                    id="outlined-multiline-static"
                                                    label="Email *"
                                                    placeholder="Enter email"
                                                    fullwidth
                                                    {...field}
                                                    inputProps={{
                                                      readOnly: readyOnlyValue,
                                                    }}
                                                  />
                                                )}
                                              />
                                              <FormHelperText className="text-danger">
                                                {errors.vendorDetails?.[index]
                                                  ?.email &&
                                                  errors.vendorDetails?.[index]
                                                    ?.email.message}
                                              </FormHelperText>
                                            </FormControl>
                                          </TableCell>

                                          <TableCell
                                            sx={{
                                              borderBottom: "none",
                                              padding: "5px",
                                            }}
                                            align="center"
                                          >
                                            {/* {
                                              businessCustomersFields.length > 1 && ( */}
                                            <DeleteIcon
                                              sx={{
                                                backgroudColor: "#F4F4F4",
                                                fontSize: "24px",
                                                fill: "#808080",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                readyOnlyValue === false ? (
                                                  deleteBusinessCustomers(index)
                                                ) : (
                                                  <></>
                                                );
                                              }}
                                            />

                                            {/* )
                                            } */}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                                <center>
                                  <Button
                                    className="add-more-btn"
                                    disabled={readyOnlyValue}
                                    onClick={() => addBusinessCustomersBtn("")}
                                  >
                                    <AddCircleIcon className="mr-2" />
                                    Add More
                                  </Button>
                                </center>
                              </TableContainer>
                              //     ) : (
                              // <div className='nodata_tag'>
                              //     <p>Fundraising history details is empty</p>
                              // </div>
                            )}
                          </div>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </>
            )}
          </div>
          <div className="form-group d-flex justify-content-end mt-3 mr-2">
            <Button
              variant="contained"
              onClick={handleBackapp}
              className="btn btn-cancel submit px-3"
            >
              Cancel
            </Button>
            {readyOnlyValue === false ? (
              <LoadingButton
                type="submit"
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                className="btn btn-primary submit px-3 ml-3"
              >
                <span>Save & Proceed Next</span>
              </LoadingButton>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* <div className="form-group d-flex justify-content-end mt-3 mr-2">
              <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
            
                  <Button
                    type="submit"
                    // loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    className="btn btn-primary submit px-3 ml-3"
                  >
                    <span>Save & Proceed Next</span>
                  </Button>

            </div> */}
      </form>
    </div>
  );
};

export default DocumentTextscst;
