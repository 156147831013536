import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, MenuItem, Modal, Select, TextField } from '@mui/material';
import notifyService from '../../../api/notifySerivce';
import apiService from '../../../api/apiService';
import DataContext from '../../../context/DataContext';

const UpdateStatus = ({ startupId, userListItemTanseed }) => {
    const { modalstyle, setPageLoading, fullScreen } = useContext(DataContext)
    const [formData, setFormData] = useState({})
    const { handleSubmit, reset, control, clearErrors, setValue, formState: { errors } } = useForm({ values: formData });
    const [openEmailModal, setopenEmailModal] = useState(false)
    const handleOpenModal = () => {
        setFormData({})
        reset()
        setopenEmailModal(true)
    }
    const handleOpenModalClose = () => {
        setFormData({})
        reset()
        setopenEmailModal(false)
    }
    const [statusValue, setStatusValue] = useState(0)
    const handleStatus = (value) => {
        setStatusValue(value)
        setValue('status',value)
        clearErrors('status')
    }
    const onSubmit = (data) => {
        // setPageLoading(true)
        var val = typeof startupId
        if (val === 'string') {
            data.startupId = [startupId]
        } else {
            data.startupId = startupId
        }
        console.log(data);
        
        // apiService('admin/email/send', data, 'post').then((result) => {
        //     setPageLoading(false)
        //     if (result) {
        //         if (result.data.responseStatus === "Success") {
        //             notifyService('success', 'Success', '')
        //             userListItemTanseed()
        //             handleOpenModalClose()
        //         }
        //     }
        // }).catch((err) => {

        // });
        // setEmailValues(emails)
        // exportExcelStartup('All',emails)
    };
    const onError = (error) => {
        console.log(error);
        notifyService("danger", "Error", "Please check mandatory fields");
    };


    return (
        <div>
            <Button
                variant="outlined"
                className='mb-3'
                onClick={handleOpenModal}
            >
                <span>Update Status</span>
            </Button>
            <Dialog
                open={openEmailModal}
                fullScreen={fullScreen}
                maxWidth={'lg'}
            >
                <DialogTitle >
                    <div className='d-flex align-item-center'> Update Status </div>
                </DialogTitle>
                <DialogContent>
                    <form
                        className="signin-form"
                        onSubmit={handleSubmit(onSubmit, onError)}
                    >
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="form-group mt-2">
                                    <Controller
                                        name="status"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Status is required" }}
                                        render={({ field }) => (
                                            <Select
                                                onChange={(e) => handleStatus(e.target.value)}
                                                value={statusValue}
                                                fullWidth
                                            >
                                                <MenuItem value={0}>Pending </MenuItem>
                                                <MenuItem value={1}>Approved </MenuItem>
                                                <MenuItem value={2}>Rejected </MenuItem>
                                                <MenuItem value={3}>Recommended to BDD</MenuItem>
                                                <MenuItem value={4}>Recommend to Mentorship / Other Schemes</MenuItem>
                                            </Select>
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.status && errors.status.message}
                                    </FormHelperText>
                                </div>
                            </div>
                        </div>
                        <div className="form-group text-center mt-4 ">
                            <Button
                                variant="contained"
                                onClick={handleOpenModalClose}
                                className="btn btn-cancel submit px-3"
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                className="btn btn-primary submit px-3 ml-3"
                            >
                                <span>Update</span>
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default UpdateStatus