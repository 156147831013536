import React, { useState } from 'react'
import Viewimage from '../../../api/Viewimage';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const ViewAllReports = ({ startupCheckList }) => {
    debugger
    const roles = localStorage.getItem('role')
    const [openReport, setOpenReport] = useState(false);
    const handleClickOpen = () => {
        setOpenReport(true)
    }
    const handleClickClose = () => {
        setOpenReport(false)
    }
    const [openReportImage, setOpenReportImage] = useState(false);
    const [viewReportImage, setViewReportImage] = useState('')
    const handleClickOpenImage = (value) => {
        setViewReportImage(value)
        setOpenReportImage(true);
    };
    const handleCloseImage = () => {
        setOpenReportImage(false);
    };
    return (
        <>
            <button className="viewdetails-sur mb-3" onClick={() => handleClickOpen()}>
                View Reports
            </button>
            <Dialog
                open={openReport}
                onClose={handleClickClose}
                maxWidth={'md'}
                fullWidth={true}
            >
                <DialogTitle id="responsive-dialog-title">
                    Report
                </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        direction="row"
                        // alignItems="flex-start"
                        justifyContent={'center'}
                        spacing={3}
                    >
                        {
                            (roles === 'SCSTADMIN' || roles === 'SCSTHUBAVP') ? (
                                <>
                                    {
                                        startupCheckList.bddFirstConnectReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.bddFirstConnectReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    BDD First Connect Report
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.bddDeepDiveReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.bddDeepDiveReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    BDD Deep Dive Report
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.icReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.icReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    IC Report
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.pscReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.pscReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    PSC Report
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.lddReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.lddReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    LDD Report
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.fddReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.fddReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    FDD Report
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.cpLddReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.cpLddReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    CP For Report LDD
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.cpFddReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.cpFddReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    CP For Report FDD
                                                </div>
                                            </Grid>
                                        ) 
                                    }
                                    {
                                        startupCheckList.csLddReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.csLddReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    CS For Report LDD
                                                </div>
                                            </Grid>
                                        ) 
                                    }
                                      {
                                        startupCheckList.csFddReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.csFddReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    CS For Report FDD
                                                </div>
                                            </Grid>
                                        ) 
                                    }
                                </>
                            ) : (
                                <></>
                            )
                        }
                        {
                            roles === 'BDD' && (
                                <>
                                    {
                                        startupCheckList.bddFirstConnectReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.bddFirstConnectReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    BDD First Connect Report
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.bddDeepDiveReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.bddDeepDiveReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    BDD Deep Dive Report
                                                </div>
                                            </Grid>
                                        )
                                    }
                                </>
                            )
                        }
                        {
                            roles === 'IC' && (
                                <>
                                {
                                        startupCheckList.bddFirstConnectReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.bddFirstConnectReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    BDD First Connect Report
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.bddDeepDiveReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.bddDeepDiveReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    BDD Deep Dive Report
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.icReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.icReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    IC Report
                                                </div>
                                            </Grid>
                                        ) 
                                    }
                                </>
                            )
                        }
                        {
                            roles === 'PSC' && (
                                <>
                                {
                                        startupCheckList.bddFirstConnectReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.bddFirstConnectReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    BDD First Connect Report
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.bddDeepDiveReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.bddDeepDiveReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    BDD Deep Dive Report
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.icReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.icReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    IC Report
                                                </div>
                                            </Grid>
                                        ) 
                                    }
                                    {
                                        startupCheckList.pscReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.pscReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    PSC Report
                                                </div>
                                            </Grid>
                                        ) 
                                    }
                                </>
                            )
                        }
                        {
                            roles === 'LDD' && (
                                <>
                                {
                                        startupCheckList.bddFirstConnectReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.bddFirstConnectReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    BDD First Connect Report
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.bddDeepDiveReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.bddDeepDiveReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    BDD Deep Dive Report
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.icReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.icReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    IC Report
                                                </div>
                                            </Grid>
                                        ) 
                                    }
                                    {
                                        startupCheckList.pscReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.pscReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    PSC Report
                                                </div>
                                            </Grid>
                                        ) 
                                    }
                                    {
                                        startupCheckList.lddReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.lddReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    LDD Report
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.fddReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.fddReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    FDD Report
                                                </div>
                                            </Grid>
                                        ) 
                                    }
                                    {
                                        startupCheckList.cpLddReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.cpLddReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    CP For Report LDD
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.cpFddReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.cpFddReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    CP For Report FDD
                                                </div>
                                            </Grid>
                                        ) 
                                    }
                                     {
                                        startupCheckList.csLddReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.csLddReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    CS For Report LDD
                                                </div>
                                            </Grid>
                                        ) 
                                    }
                                        {
                                        startupCheckList.csFddReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.csFddReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    CS For Report FDD
                                                </div>
                                            </Grid>
                                        ) 
                                    }
                                </>
                            )
                        }
                        {
                            roles === 'FDD' && (
                                <>
                                 {
                                        startupCheckList.bddFirstConnectReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.bddFirstConnectReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    BDD First Connect Report
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.bddDeepDiveReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.bddDeepDiveReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    BDD Deep Dive Report
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.icReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.icReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    IC Report
                                                </div>
                                            </Grid>
                                        ) 
                                    }
                                    {
                                        startupCheckList.pscReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.pscReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    PSC Report
                                                </div>
                                            </Grid>
                                        ) 
                                    }
                                    {
                                        startupCheckList.lddReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.lddReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    LDD Report
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.fddReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.fddReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    FDD Report
                                                </div>
                                            </Grid>
                                        ) 
                                    }
                                    {
                                        startupCheckList.cpLddReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.cpLddReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    CP For Report LDD
                                                </div>
                                            </Grid>
                                        )
                                    }
                                    {
                                        startupCheckList.cpFddReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.cpFddReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    CP For Report FDD
                                                </div>
                                            </Grid>
                                        ) 
                                    }
                                     {
                                        startupCheckList.csLddReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.csLddReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    CS For Report LDD
                                                </div>
                                            </Grid>
                                        ) 
                                    }
                                        {
                                        startupCheckList.csFddReportDoc && (
                                            <Grid item sm={12} md={3} lg={3}>
                                                <div className='report_view_div' onClick={() => handleClickOpenImage(startupCheckList.csFddReportDoc)}>
                                                    <img src='/images/pdf.png' className='pdf_img' alt='' />
                                                    CS For Report FDD
                                                </div>
                                            </Grid>
                                        ) 
                                    }
                                </>
                            )
                        }
                    </Grid>
                    <section className='report_view_section'>

                    </section>
                    <div className='mt-4'>
                        <p>Comments</p>
                    </div>
                    <div className='mt-4'>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">#</TableCell>
                                        <TableCell align="center">Phase</TableCell>
                                        <TableCell align="center">Comment</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {startupCheckList.reportComments.map((list, index) => (
                                    <TableRow key={index}>
 <>
                                                    <TableCell component="th" scope="row" align="center">
                                                    {index + 1}
                                                    </TableCell>
                                             
                                                    <TableCell align="center">{list.reportType ? list.reportType : `-` }</TableCell>
                                                    <TableCell align="center">{list.comment ?list.comment : `-` }</TableCell>
                                                </>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </DialogContent>
                <div className='text-center p-3'>
                    <Button variant="outlined" onClick={handleClickClose}>
                        Close
                    </Button>
                </div>
            </Dialog >
            <Viewimage viewImage={viewReportImage} open={openReportImage} handleClose={handleCloseImage} />
        </>
    )
}

export default ViewAllReports