import React, { useContext, useState } from 'react'
import DataContext from '../../../context/DataContext'
import { useParams } from 'react-router-dom'
import useDidMountEffect from '../../../hooks/useDidMountEffect'
import apiService from '../../../api/apiService'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Scsthubavpvalidation from './Scsthubavpvalidation'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Scsthubavpevaluation from './Scsthubavpevaluation'
import Applicationscst from '../../scst-page/Applicationscst'
import Fundingscstview from '../../scst-page/Fundingscstview'
import Founderscstview from '../../scst-page/Founderscstview'
import Moreinfosave from '../../investor-module/associated-startups-page/associated-details-page/pages/Moreinfosave'
import { FaCheckCircle } from 'react-icons/fa';
import Viewimage from '../../../api/Viewimage'
import CommonViewPitchDeck from '../../common-page/CommonViewPitchDeck'
import IncubatorAccelerationScst from '../../scst-page/IncubatorAccelerationScst'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Dialog, DialogContent, DialogTitle, FormHelperText, Grid, TextField } from '@mui/material'
import { AiFillSafetyCertificate } from 'react-icons/ai';
import Zoomlist from '../../jury-module/zoom-page/Zoomlist'
import ScstBddVerificationLayout from '../../scstBdd-page/scst-verification-details/ScstBddVerificationLayout'
import notifyService from '../../../api/notifySerivce'
import BDDDeepDiveLayout from '../../scst-page/bdd-dee-dive/BDDDeepDiveLayout'
import ViewAllReports from '../../scst-page/view-reports/ViewAllReports'
import LDDLayout from '../../scst-page/ldd-stage/LDDLayout'
import FDDLayout from '../../scst-page/fdd-stage/FDDLayout'
import { Controller, useForm } from 'react-hook-form'
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
function a11yProps1(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
const Scsthubavpdetails = ({ role }) => {
  debugger
  const { setPageLoading, navigator, getSCSTStartupStatus } = useContext(DataContext)
  const { startupId } = useParams()
  const { id } = useParams()
  let idNumber = atob(startupId)
  let partnerOrInvestorId = atob(id)
  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState({});
  const { handleSubmit, getValues, control, clearErrors, formState: { errors } } = useForm(
    { values: formData }
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [valueTab, setValueTab] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
    // console.log(newValue);
  };
  const [meetingIndex, setMeetingIndex] = useState(1)
  useDidMountEffect(() => {

  }, [])
  const theme = useTheme();
  useDidMountEffect(() => {
    listApi()
    viewStartupDetails(idNumber, partnerOrInvestorId)
    showStartupValue()
  }, [])
  const [listData, setListData] = useState([])
  const listApi = () => {
    setPageLoading(true)
    var req = {
      "partnerOrInvestorId": partnerOrInvestorId,
      "startupId": idNumber
    }
    apiService('partner/scst/admin/startup/get', req, 'post').then((result) => {
      setPageLoading(false)
      if (result && result.data) {
        if (result.data.startupRounds) {
          setListData(result.data.startupRounds)
        }
      }
    }).catch((err) => {

    });
  }
  const [startupGetData, setStartupGetData] = useState({})
  const [startupValue, setStartupValue] = useState({})
  const viewStartupDetails = async (id, partnerOrInvestorId) => {
    setPageLoading(true)
    // setNavTabValue('1')
    setStartupGetData({})
    setStartupValue({})
    apiService(`partner/scstadmin/startups/get?id=${id}&partnerOrInvestorId=${partnerOrInvestorId}`, '', 'get').then((res) => {
      setPageLoading(false)
      if (res) {
        if (res.data) {
          if (res.data.applicationForm) {
            setStartupGetData(res.data.applicationForm)

          }
          setStartupValue(res.data)
          if (role === 'scsthubavp' || role === 'scstadmin') {
            var startupValue = res.data
            if (startupValue.status >= 28) {
              setMeetingIndex(3)
            } else if (startupValue.status >= 21) {
              setMeetingIndex(7)
            }
            else if (startupValue.status >= 17) {
              setMeetingIndex(7)
            }
            else if (startupValue.status >= 16) {
              setMeetingIndex(5)
            }
            else if (startupValue.status >= 11) {
              setMeetingIndex(5)
            }
            else if (startupValue.status >= 9) {
              setMeetingIndex(5)
            }
            else if (startupValue.status >= 8) {
              setMeetingIndex(5)
            }
            else if (startupValue.status >= 5) {
              setMeetingIndex(4)
            } else if (startupValue.status >= 3) {
              setMeetingIndex(3)
            } else if (startupValue.status >= 1) {
              setMeetingIndex(3)
            } else if (startupValue.status < 1) {
              setMeetingIndex(2)
            }
          }
        } else {
          setStartupGetData({})
        }
      }
    })
  }
  const [startupCheckList, setStartupCheckList] = useState([])
  const [comments, setComments] = useState([])
  const showStartupValue = async () => {
    apiService(`startup/clarifications/status/get?id=${idNumber}`, '', 'get').then((res) => {

      if (res) {
        if (res.data) {
          debugger
          setStartupCheckList(res.data)
          if (res.data && res.data.reportComments) {
            setComments(res.data.reportComments)
          }
        }
      }
    })
  }
  const isRegistered = startupGetData.dpiitRegistered;

  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    setViewImage(value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [openReport, setOpeReport] = useState(false);
  const [viewReportImage, setViewReportImage] = useState("");
  const handleClickReportOpen = (value) => {
    setViewReportImage(value);
    setOpeReport(true);
  };
  const handleReportClose = () => {
    setOpeReport(false);
  };
  const onStatusUpdate = (data) => {
    setPageLoading(true)
    var getform = {
      status: data,
      startupId: idNumber
    }
    debugger
    apiService('partner/scst/status/save', getform, 'post').then((res) => {
      setPageLoading(false)
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService('success', 'Success', 'Saved successfully !!')
          viewStartupDetails(idNumber, partnerOrInvestorId)
          showStartupValue()
        }
      }
    })
  };
  const handleBack = () => {
    navigator(`/${role}/startups/list`)
  }
  const [openView, setOpenView] = useState(false);
  const handleViewOpen = () => {
    setOpenView(true);
  };
  const handleViewClose = () => {
    setOpenView(false);
  }
  const [openReportAdmin, setOpeReportAdmin] = useState(false);
  const handleReportCloseAdmin = () => {
    setOpeReportAdmin(false)
  }
  const handleViewOpenAdmin = () => {
    setOpeReportAdmin(true);
  };
  const onSubmit = (data) => {
    setPageLoading(true)
    var getform = data
    getform.status = 4
    getform.startupId = idNumber
    debugger
    apiService('partner/scst/status/save', getform, 'post').then((res) => {
      setPageLoading(false)
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService('success', 'Success', 'Saved successfully !!')
          viewStartupDetails(idNumber, partnerOrInvestorId)
          showStartupValue()
          handleReportCloseAdmin()
        }
      }
    })
  };
  return (
    <div className='box-show'>
      <section className='bg-white'>
        <div className="container-fluid">
          <div className='p-3'>
            <div className='row'>
              <div className="col-lg-9">
                <div className='top-banner'>
                  <div className='d-flex align-items-center'>
                    <div><Button onClick={handleBack}><ArrowBackIcon /></Button></div>
                    <img src={startupGetData.startupLogoName} className='img-fluid user_logos' alt='' />
                  </div>
                  <div className='content'>
                    {/* <h3 className='startupname-sur'>{startupValue.startupName}</h3> */}
                    <div className='d-flex align-items-center mb-3'>
                      <h3 className='mr-3 mb-0'>{startupValue.startupName}</h3>
                      <button className={`dpi-button ${isRegistered ? 'register-btn' : 'not-register-btn'}`}>
                        {isRegistered && <AiFillSafetyCertificate className="icon" />}
                        {isRegistered ? 'DPIIT Recognised' : 'DPIIT Not Registered'}
                      </button>
                      <button
                        className="viewdetails-surpitch"
                        onClick={() => {
                          handleClickOpen(startupGetData.pitchDeck);
                        }}
                      >
                        View PitchDeck
                      </button>
                    </div>
                    <span className='black-clr-sur'><img src='/images/phone.png' alt='' className='mr-2' /> {startupValue.startupPhone ? (startupValue.startupPhone) : ('-')}</span>
                    <span className='black-clr-sur ml-3'><img src='/images/email.png' alt='' className='mr-2' /> {startupValue.startupEmail ? (startupValue.startupEmail) : ('-')}</span>
                    <span className='black-clr-sur ml-3'>Member Since : {startupValue.date}</span>
                    <div className='mt-3'>Status : <span className='status_startup'>{getSCSTStartupStatus(startupValue.status)}</span></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className='ml-auto text-right'>
                  {/* {
                  valueTab === 3 && (
                    <button
                      className="viewdetails-sur mb-3"
                      onClick={() => {
                        handleClickReportOpen(startupValue.additionalDocName);
                      }}
                    >
                      BDD Report
                    </button>
                  )
                } */}
                  {
                    role === 'scstadmin' ? (
                      (startupCheckList.scstadminBddApproveButton === true) && (
                        <>
                          <div>
                            <button className="viewdetails-Approve  mr-3 " onClick={() => onStatusUpdate(3)}>
                              Recommend to BDD
                            </button>
                            <button className="viewdetails-Schemes mt-2" onClick={() => handleViewOpenAdmin()}>
                              Recommend to MentorShip
                            </button>

                          </div>
                        </>
                      )
                    ) : (
                      (startupCheckList.hubadminBddApproveButton === true) && (
                        <>
                          <div>
                            <button className="viewdetails-Approve  mr-3 " onClick={() => onStatusUpdate(3)}>
                              Recommend to BDD
                            </button>
                            <button className="viewdetails-Schemes mt-2" onClick={() => handleViewOpenAdmin()}>
                              Recommend to MentorShip
                            </button>
                          </div>
                        </>
                      )
                    )
                  }
                  {/* {
                    role === 'scstadmin' ? (
                      (startupCheckList.cpSubmitButton === true) && (
                        <span className='status_startup'>Recommend to BDD</span>
                      )
                        (startupCheckList.cpSubmitButtons === true) && (
                        <span className='status_startup'>Recommend to MentorShip</span>
                      )
                    ) : (
                      (startupCheckList.cpSubmitButton === true) && (
                        <span className='status_startup'>Recommend to BDD</span>
                      )
                        (startupCheckList.cpSubmitButton === true) && (
                        <span className='status_startup'>Recommend to MentorShip</span>
                      )
                    )
                  } */}
                  {
                    role === 'scstadmin' ? (
                      <>
                        {startupCheckList.scstadminBddRecommendedButton === true && (
                          <span className='status_startup mr-2'>Recommended to BDD</span>
                        )}
                        {startupCheckList.scstadminBddRejectedButton === true && (
                          <span className='status_startup mr-2'>Recommended to MentorShip</span>
                        )}
                      </>
                    ) : (
                      <>
                      
                      </>
                    )
                  }
                  {
                    role === 'scsthubavp' && (
                      <>
                        {startupCheckList.hubadminBddRecommendedButton === true && (
                          <span className='status_startup mr-2'>Recommended to BDD</span>
                        )}
                        {startupCheckList.hubadminBddRejectedButton === true && (
                          <span className='status_startup mr-2'>Recommended to MentorShip</span>
                        )}
                      </>
                    )
                  }
                  {(startupValue.status >= 6) && (
                    <>
                      {
                        startupCheckList.bddDeepDiveReportDoc ? (
                          <ViewAllReports startupCheckList={startupCheckList} />
                        ) : (
                          <>
                            {
                              startupCheckList.bddFirstConnectReportDoc && (
                                <>
                                  <button className="viewdetails-sur mr-3 mb-3" onClick={() => handleClickReportOpen(startupCheckList.bddFirstConnectReportDoc)}>
                                    BDD First Connect Report
                                  </button>
                                  {comments?.[0]?.comment && (
                                    <span className='c-pointer' data-toggle="tooltip" data-placement="top" title="View Comment" onClick={() => handleViewOpen()}><img
                                      src="/images/comment.png"
                                      alt="Comment Icon"
                                      className="img-fluid"
                                      style={{
                                        width: '40px',
                                        height: '40px',
                                        filter: 'invert(33%) sepia(92%) saturate(1109%) hue-rotate(202deg) brightness(94%) contrast(89%)',
                                      }}
                                    /></span>
                                  )}
                                </>
                              )

                            }
                          </>
                        )
                      }
                      {/* <div>
                      {
                        startupValue.bddFirstConnectReportDoc && (
                          <button className="viewdetails-sur mr-3 mb-3" onClick={() => handleClickReportOpen(startupValue.bddFirstConnectReportDoc)}>
                            BDD First Connect Report
                          </button>
                        )
                      }
                      {
                        startupValue.bddDeepDiveReportDoc && (
                          <button className="viewdetails-sur mb-3" onClick={() => handleClickReportOpen(startupValue.bddDeepDiveReportDoc)}>
                            BDD Deep Dive Report
                          </button>
                        )
                      }
                    </div>

                    <div>
                      {
                        startupValue.icReportDoc && (
                          <button className="viewdetails-sur mr-3 mb-3" onClick={() => handleClickReportOpen(startupValue.icReportDoc)}>
                            IC Report
                          </button>
                        )
                      }
                      {
                        startupValue.pscReportDoc && (
                          <button className="viewdetails-sur mb-3" onClick={() => handleClickReportOpen(startupValue.pscReportDoc)}>
                            PSC Report
                          </button>
                        )
                      }
                    </div> */}

                    </>
                  )}

                  {(startupValue.status === 6) && (
                    <>
                      <div>
                        <button className="viewdetails-Approve  mr-3 " onClick={() => onStatusUpdate(7)}>
                          Approve for Deep Dive
                        </button>
                        <button className="viewdetails-Schemes mt-2" onClick={() => onStatusUpdate(9)}>
                          Recommend to Other Schemes
                        </button>
                      </div>
                    </>
                  )}
                  {(startupValue.status === 10) && (
                    <>
                      <div>
                        <button className="viewdetails-Approve  mr-3 mb-3" onClick={() => onStatusUpdate(11)}>
                          Approve for IC
                        </button>
                        <button className="viewdetails-Schemes " onClick={() => onStatusUpdate(12)}>
                          Recommend to Other Schemes
                        </button>
                      </div>
                    </>
                  )}
                  {(startupValue.status === 18) && (
                    <>
                      <div>
                        <button className="viewdetails-Approve  mr-3 " onClick={() => onStatusUpdate(20)}>
                          Approve for CP
                        </button>
                        <button className="viewdetails-Schemes mt-1" onClick={() => onStatusUpdate(19)}>
                          Recommend to Other Schemes
                        </button>
                      </div>
                    </>
                  )}
                  {(startupValue.status === 21) && (
                    <>
                      <div>
                        <button className="viewdetails-Approve  mr-3 " onClick={() => onStatusUpdate(22)}>
                          Approve for Fund Sanction
                        </button>
                        <button className="viewdetails-Schemes mt-1" onClick={() => onStatusUpdate(23)}>
                          Recommend to Other Schemes
                        </button>
                      </div>
                    </>
                  )}
                  {/* {(startupValue.status === 18) && (
                  <>
                    <div className='mt-3'>
                      <button className="viewdetails-Approve  mr-3 " onClick={() => onStatusUpdate(20)}>
                        Approve for FDD
                      </button>
                      <button className="viewdetails-Schemes " onClick={() => onStatusUpdate(19)}>
                        Recommend to Other Schemes
                      </button>
                    </div>
                  </>
                )} */}
                  {(startupValue.status === 22) && (
                    <>
                      <div className='mt-3'>
                        <button className="viewdetails-Approve  mr-3 " onClick={() => onStatusUpdate(24)}>
                          Approved for Fund Disbursement  </button>
                        <button className="viewdetails-Schemes mt-1" onClick={() => onStatusUpdate(25)}>
                          FS Recommend to Other Schemes
                        </button>
                      </div>
                    </>
                  )}
                  {(startupValue.status === 24) && (
                    <>
                      <div className='mt-3'>
                        <button className="viewdetails-Approve  mr-3 " onClick={() => onStatusUpdate(26)}>
                          Approved for CS Stage</button>

                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="setHeg">
            <Grid container>
              <Grid item xs={2}>
                <Tabs
                  orientation="vertical"
                  // variant="scrollable"
                  value={valueTab}
                  onChange={handleChangeTab}
                  aria-label="Vertical tabs example"
                >
                  <Tab label="Profile" {...a11yProps(0)} className='side-tab' />
                  <Tab label="Validation" {...a11yProps(1)} className='side-tab' />
                  {
                    (startupValue.status >= 1) && (
                      <Tab label="Evaluation" {...a11yProps(2)} className='side-tab' />
                    )
                  }
                  {
                    (startupValue.status >= 5 && startupValue.status !== 28) && (
                      <Tab label="BDD First Connect" {...a11yProps(3)} className='side-tab' />
                    )
                  }
                  {
                    (startupValue.status >= 8 && startupValue.status !== 28) && (
                      <Tab label="BDD deep dive" {...a11yProps(4)} className='side-tab' />
                    )
                  }
                  {
                    (startupValue.status >= 17 && startupValue.status !== 28) && (
                      <Tab label="LDD" {...a11yProps(5)} className='side-tab' />
                    )
                  }
                  {
                    (startupValue.status >= 17 && startupValue.status !== 28) && (
                      <Tab label="FDD" {...a11yProps(6)} className='side-tab' />
                    )
                  }
                  {/* {
                    role === 'scsthubavp' && ( */}
                  <Tab label="Zoom" {...a11yProps(meetingIndex)} className='side-tab' />
                  {/* )
                  } */}
                </Tabs>
              </Grid>
              <Grid item xs={10}>
                <TabPanel value={valueTab} index={0}>
                  <div className='overall table_tag'>
                    <Box>
                      <Tabs
                        className='verticalTab'
                        orientation="horizontal"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                      >
                        <Tab className='verticalLable' label="Application Overview" {...a11yProps(0)} />
                        <Tab className='verticalLable' label="Funding & Financials" {...a11yProps(1)} />
                        <Tab className='verticalLable' label="Founder Info" {...a11yProps(2)} />
                        <Tab className='verticalLable' label="Incubation & Acceleration" {...a11yProps(3)} />
                      </Tabs>
                      <Box sx={{ p: 3 }}>
                        <TabPanel value={value} index={0}><Applicationscst details={startupGetData} detail={startupValue} /></TabPanel>
                        <TabPanel value={value} index={1}><Fundingscstview details={startupGetData} detail={startupValue} /></TabPanel>
                        <TabPanel value={value} index={2}><Founderscstview details={startupGetData} /></TabPanel>
                        <TabPanel value={value} index={3}><IncubatorAccelerationScst details={startupGetData} detail={startupValue} /></TabPanel>
                      </Box>
                    </Box>
                  </div>
                </TabPanel>
                <TabPanel value={valueTab} index={1}>
                  <Box sx={{ p: 3 }}>
                    <Scsthubavpvalidation listData={listData[0]} />
                  </Box>
                </TabPanel>
                {
                  (startupValue.status >= 1) && (
                    <TabPanel value={valueTab} index={2}>
                      <div className='table_tag'>
                        <Box sx={{ p: 3 }}>
                          <Scsthubavpevaluation listApi={listApi} listData={listData[1]} />
                        </Box>
                      </div>
                    </TabPanel>
                  )
                }
                {
                  (startupValue.status >= 5 && startupValue.status !== 28) && (
                    <TabPanel value={valueTab} index={3}>
                      <ScstBddVerificationLayout idNumber={idNumber} path={''} viewStartupDetails={viewStartupDetails} startupValue={startupValue} />
                    </TabPanel>
                  )
                }
                {
                  (startupValue.status >= 8 && startupValue.status !== 28) && (
                    <TabPanel value={valueTab} index={4}>
                      <BDDDeepDiveLayout idNumber={idNumber} path={''} viewStartupDetails={viewStartupDetails} startupValue={startupValue} />
                    </TabPanel>
                  )
                }
                {
                  (startupValue.status >= 17 && startupValue.status !== 28) && (
                    <TabPanel value={valueTab} index={5}>
                      <LDDLayout idNumber={idNumber} path={''} viewStartupDetails={viewStartupDetails} startupValue={startupValue} />
                    </TabPanel>
                  )
                }
                {
                  (startupValue.status >= 17 && startupValue.status !== 28) && (
                    <TabPanel value={valueTab} index={6}>
                      <FDDLayout idNumber={idNumber} path={''} viewStartupDetails={viewStartupDetails} startupValue={startupValue} />
                    </TabPanel>
                  )
                }
                {/* {
                  role === 'scsthubavp' && ( */}
                <TabPanel value={valueTab} index={meetingIndex}>
                  <div className='table_tag'>
                    <Box sx={{ p: 3 }}>
                      <Zoomlist startupId={idNumber} />
                    </Box>
                  </div>
                </TabPanel>
                {/* )
                } */}
              </Grid>
            </Grid>


          </div>
          <CommonViewPitchDeck viewImage={viewImage} open={open} handleClose={handleClose} />
          <Viewimage viewImage={viewReportImage} open={openReport} handleClose={handleReportClose} />
          <Dialog open={openView} maxWidth="sm" fullWidth={true} onClose={handleViewClose}>
            <DialogContent>
              <h1 className="views-scomm">View Comments</h1>

              {/* <p className='mt-4'>{comments}</p> */}
              {comments.map((report, index) => (
                <p key={index} className="mt-4">{report.comment}</p>
              ))}
            </DialogContent>
          </Dialog>


          {/* Admin Report */}
          <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            open={openReportAdmin}
            onClose={handleReportCloseAdmin}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Mentorship
            </DialogTitle>
            <DialogContent>
              <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                  <div className='col-lg-12 col-12'>

                    <div className="form-group mt-2">
                      <Controller name="comments" control={control} defaultValue="" rules={{ required: 'Please Enter Remarks' }}
                        render={({ field }) =>
                          <TextField
                            variant='outlined'
                            multiline
                            label="Remarks *"
                            placeholder='Enter Remarks'
                            fullWidth
                            {...field}
                          />} />
                      <FormHelperText className='text-danger'>{errors.comments && errors.comments.message}</FormHelperText>
                    </div>
                  </div>
                </div>
                <div className='text-center p-3'>
                  <Button className='mr-3' onClick={handleReportCloseAdmin} variant="outlined">
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained">
                    Submit
                  </Button>
                </div>
              </form>
            </DialogContent>
          </Dialog>
        </div>
      </section >
    </div>

  )
}

export default Scsthubavpdetails