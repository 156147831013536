import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, FormHelperText, FormLabel, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import DataContext from '../../../../context/DataContext';
import notifyService from '../../../../api/notifySerivce';
import { Link } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Viewimage from '../../../../api/Viewimage';
import { FaRegFilePdf } from "react-icons/fa";
import { BsFiletypeXls } from "react-icons/bs";
// import cryptodecryptJS from '../../../../hooks/cryptodecryptJS';
// import PreliminaryTextPart1 from './PreliminaryTextPart1';
const fileTypesPDF = ["pdf"];
const fileTypesExcel = ["XLSX",];
const fileTypesPDFandExcel = ["pdf", "XLSX",];
const fileTypesExcelandWord = ["XLSX", "doc", "docx"];
const fileTypesAll = ["pdf", "XLSX", "doc", "docx"];

const FDDPriorityDocu = () => {
    const [formData, setFormData] = useState({});
    const { handleBackapp, setPageLoading } = useContext(DataContext)
    const { handleSubmit, setValue, getValues, clearErrors, control, formState: { errors } } = useForm({
        values: formData,
    });
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const [nonEditAble, setNonEditAble] = useState(false);
    useDidMountEffect(() => {
        getApi()
    }, [])
    const [fileUploadId, setFileUploadId] = useState(0)
    const getApi = async () => {
        setPageLoading(true)
        await apiService(`startup/fdd/prioritydocuments/get`, '', 'get').then((res) => {
            if (res && res.data) {
                var data = res.data
                if (data.editForm === false) {
                    setReadyOnlyValue(true)
                }
                setNonEditAble(true)
                if (res.data.id) {
                    setFileUploadId(res.data.id)
                }

                setFormData(res.data)
            }
            setPageLoading(false)
        })
    }
    const handleFileUpload = (event, filekey, fileName, fileURL, format) => {
        let logoselecetdFile;
        if (event !== null) {
            if (event.target === undefined) {
                logoselecetdFile = event
            } else {
                logoselecetdFile = event.target.files[0]
            }
            if (logoselecetdFile) {
                const fileSizeLimit = 5 * 1024 * 1024;
                if (logoselecetdFile.size > fileSizeLimit) {
                    notifyService(
                        "danger",
                        "File Size Exceeded",
                        "Please upload a file smaller than 5 MB."
                    );
                    return;
                }
                var reader = new FileReader();
                var imagetype = logoselecetdFile.type
                var imagedatatype = imagetype.split("/")
                var img_crt_type = imagedatatype[1]
                debugger
                if (
                    ((format === 'file') && (img_crt_type === "pdf")) ||
                    ((format === 'excel') && (img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet")) ||
                    // ((format === 'pdfAndExcel') && (img_crt_type === "pdf" || img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet")) ||
                    // ((format === 'excelAndDoc') && (img_crt_type === "vnd.openxmlformats-officedocument.wordprocessingml.document" || img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
                    ((format === 'pdfwordexcel') && (img_crt_type === "pdf" || img_crt_type === "vnd.openxmlformats-officedocument.wordprocessingml.document" || img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
                ) {
                    setValue(filekey, "")
                    setValue(fileName, "")
                    debugger
                    var fileValue = logoselecetdFile
                    reader.readAsDataURL(logoselecetdFile);
                    reader.onload = () => {
                        var logourl1 = reader.result;
                        var spl = logourl1.split(',')
                        var ImageValue = spl[1]
                        var img_name = fileValue.name
                        setValue(filekey, ImageValue)
                        setValue(fileName, img_name)
                        setValue(fileURL, logourl1)
                        clearErrors(filekey);
                        clearErrors(fileName);
                        clearErrors(fileURL);
                        var req = {
                            [filekey]: ImageValue,
                            [fileName]: img_name,
                            id: fileUploadId
                        }
                        console.log(req);
                        saveData(req)
                    }
                } else {
                    notifyService('danger', 'File Format Invalided', 'Please check your file format')
                }
            }
        }
    }
    const saveData = (req) => {
        console.log(req);
        apiService('startup/fdd/prioritydocuments/save', req, 'post').then((res) => {
            setPageLoading(false);
            if (res && res.data) {
                if (res.data.responseStatus === "Success") {
                    getApi()
                }
            }
        })
    }

    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const handleClickOpen = (value) => {
        if (value) {
            setViewImage(value);
            setOpen(true);
        }
    };
    // const handleClickOpens = (url) => {
    //     if (url) {
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.download = 'filename.xlsx';
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    //     }

    // };
    const handleClickOpens = (url) => {
        debugger
        if (url) {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.rel = 'noopener noreferrer';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    const onPdfClick1 = () => {
        const pdfUrl = "https://startuptn-public-files.s3.ap-south-1.amazonaws.com/SCST_Annexure_1_2_3.xlsx";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Business_Plan_Template.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    const onPdfClick4 = () => {
        const pdfUrl = "https://startuptn-public-files.s3.ap-south-1.amazonaws.com/SCST_Annexure_4.xlsx";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Business_Plan_Template.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    const onSubmit = (data) => {
        setPageLoading(true);
        // console.log(data);
        if (data.shareHoldingPattern) {
            data.shareHoldingPattern.forEach(element => {
                if (element.date) {
                    element.date = element.date.format('YYYY-MM-DD');
                }
            });
        }
        var req = {
            id: fileUploadId,
            directorsInterestedCompanies: data.directorsInterestedCompanies,
            shareHoldingPattern: data.shareHoldingPattern,
            sharesSubjectedToPledge: data.sharesSubjectedToPledge
        }
        console.log(req);
        apiService('startup/fdd/prioritydocuments/save', req, 'post').then((res) => {
            setPageLoading(false);
            if (res && res.data) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Saved successfully !!')
                    handleBackapp()
                }
            }
        })
    }
    return (
        <>
            <section className='container-fluid'>
                <div className='form_div'>
                    <div className="d-flex align-items-center">
                        <span className="back_span" onClick={handleBackapp}>
                            <img src='/images/Fig-back.png' className='img-fluid' alt='' />
                        </span>
                        <h6 className="ml-2 mb-0">Priority Document</h6>
                    </div>
                    <hr />
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className=''>
                            <div className="row doc-row">
                                <div className='col-12'>
                                    <div className='doc-title mt-3'>
                                        <h6>Priority Documents :</h6>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Accounting Data (or access to the accounting software) for the DD period.</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="accountingDataDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "accountingDataDoc",
                                                            "accountingDataDocName",
                                                            "accountingDataDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('accountingDataDoc')}
                                                        name="file"
                                                        types={fileTypesAll}
                                                        children={
                                                            <div className={getValues('accountingDataDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('accountingDataDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('accountingDataDoc') !== '' ? 'Uploaded (Click to Change)' : 'Accounting Data Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.accountingDataDoc && errors.accountingDataDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpen(getValues('accountingDataDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Preview
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Provisional Financials for the stub period. Provisional Financials to include - Profit and Loss Account - Balance Sheet - Cash Flow Statement</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="provFinancialDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "provFinancialDoc",
                                                            "provFinancialDocName",
                                                            "provFinancialDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('provFinancialDoc')}
                                                        name="file"
                                                        types={fileTypesAll}
                                                        children={
                                                            <div className={getValues('provFinancialDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('provFinancialDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('provFinancialDoc') !== '' ? 'Uploaded (Click to Change)' : 'Prov Financial Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.provFinancialDoc && errors.provFinancialDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('provFinancialDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Signed Audited Financials (profit and loss account, balance sheet, notes on accounts and cash flow statements) of last three financial year <b>as PDF</b> </FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="auditFinancialDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "auditFinancialDoc",
                                                            "auditFinancialDocName",
                                                            "auditFinancialDocURL",
                                                            'file')}
                                                        value={getValues('auditFinancialDoc')}
                                                        name="file"
                                                        types={fileTypesPDF}
                                                        children={
                                                            <div className={getValues('auditFinancialDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('auditFinancialDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('auditFinancialDoc') !== '' ? 'Uploaded (Click to Change)' : 'Audited Financials Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.auditFinancialDoc && errors.auditFinancialDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('auditFinancialDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Signed Audited Financials (profit and loss account, balance sheet, notes on accounts and cash flow statements) of last three financial year <b>as Excel</b> </FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="auditFinancialExcel" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "auditFinancialExcel",
                                                            "auditFinancialExcelName",
                                                            "auditFinancialExcelURL",
                                                            'excel')}
                                                        value={getValues('auditFinancialExcel')}
                                                        name="file"
                                                        types={fileTypesExcel}
                                                        children={
                                                            <div className={getValues('auditFinancialExcel') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('auditFinancialExcel') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('auditFinancialExcel') !== '' ? 'Uploaded (Click to Change)' : 'Audited Financials Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .xlsx</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.auditFinancialExcel && errors.auditFinancialExcel.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('auditFinancialExcel'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Bank Statements of all the Banks (both Operating and Non-Operating) for the DD period <b>as PDF</b> </FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="bankStatementDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "bankStatementDoc",
                                                            "bankStatementDocName",
                                                            "bankStatementDocURL",
                                                            'file')}
                                                        value={getValues('bankStatementDoc')}
                                                        name="file"
                                                        types={fileTypesPDF}
                                                        children={
                                                            <div className={getValues('bankStatementDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('bankStatementDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('bankStatementDoc') !== '' ? 'Uploaded (Click to Change)' : 'Bank Statements Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.bankStatementDoc && errors.bankStatementDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('bankStatementDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Bank Statements of all the Banks (both Operating and Non-Operating) for the DD period  <b>as Excel</b></FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="bankStatementExcel" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "bankStatementExcel",
                                                            "bankStatementExcelName",
                                                            "bankStatementExcelURL",
                                                            'excel')}
                                                        value={getValues('bankStatementExcel')}
                                                        name="file"
                                                        types={fileTypesExcel}
                                                        children={
                                                            <div className={getValues('bankStatementExcel') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('bankStatementExcel') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('bankStatementExcel') !== '' ? 'Uploaded (Click to Change)' : 'Bank Statements Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .xlsx</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.bankStatementExcel && errors.bankStatementExcel.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('bankStatementExcelURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                               
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Monthly MIS, Monthly Compliance Status Report and Internal Audit Report for the stub period and immediate preceeding financial year
                                        - Projected Financials for the next 24 months  *</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="misDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "misDoc",
                                                            "misDocName",
                                                            "misDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('misDoc')}
                                                        name="file"
                                                        types={fileTypesAll}
                                                        children={
                                                            <div className={getValues('misDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('misDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('misDoc') !== '' ? 'Uploaded (Click to Change)' : 'MIS Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.misDoc && errors.misDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('misDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Revenue Tracker - in case of revenue is booked via Ecommerce Platform - provide a reconciliation statement</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="revenueDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "revenueDoc",
                                                            "revenueDocName",
                                                            "revenueDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('revenueDoc')}
                                                        name="file"
                                                        types={fileTypesExcel}
                                                        children={
                                                            <div className={getValues('revenueDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('revenueDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('revenueDoc') !== '' ? 'Uploaded (Click to Change)' : 'Revenue Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .xlsx</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.revenueDoc && errors.revenueDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('revenueDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                    <div>
                                        <span className='c-blue c-pointer ml-2' onClick={onPdfClick4} >Revenue Tracker <BsFiletypeXls className="c-green" /></span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Monthly Statements from E-Commerce websites (If applicable) for sales, commission, other deductions, collections and amount outstanding for the last 3 years</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="ecommerceStatementDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "ecommerceStatementDoc",
                                                            "ecommerceStatementDocName",
                                                            "ecommerceStatementDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('ecommerceStatementDoc')}
                                                        name="file"
                                                        types={fileTypesAll}
                                                        children={
                                                            <div className={getValues('ecommerceStatementDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('ecommerceStatementDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('ecommerceStatementDoc') !== '' ? 'Uploaded (Click to Change)' : 'E-commerce Statement Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.ecommerceStatementDoc && errors.ecommerceStatementDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('ecommerceStatementDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Payroll Workings for each month of the current period and previous year. A reconciliation statement (in a tabular format) between Payroll workings and Books of Accounts.</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="payrollDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "payrollDoc",
                                                            "payrollDocName",
                                                            "payrollDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('payrollDoc')}
                                                        name="file"
                                                        types={fileTypesAll}
                                                        children={
                                                            <div className={getValues('payrollDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('payrollDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('payrollDoc') !== '' ? 'Uploaded (Click to Change)' : 'Payroll Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.payrollDoc && errors.payrollDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('payrollDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Details of applicable statutory compliances (GST, EPF,ESI, PT, Income Tax, other relevant acts appliable to the company) - its registration certificates, remittance challan, monthly/quarterly returns, annual filings</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="statutoryComplianceDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "statutoryComplianceDoc",
                                                            "statutoryComplianceDocName",
                                                            "statutoryComplianceDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('statutoryComplianceDoc')}
                                                        name="file"
                                                        types={fileTypesExcel}
                                                        children={
                                                            <div className={getValues('statutoryComplianceDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('statutoryComplianceDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('statutoryComplianceDoc') !== '' ? 'Uploaded (Click to Change)' : 'Revenue Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .xlsx</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.statutoryComplianceDoc && errors.statutoryComplianceDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('statutoryComplianceDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                    <div>
                                        <span className='c-blue c-pointer ml-2' onClick={onPdfClick1} >Applicable Statutory Compliances <BsFiletypeXls className="c-green" /></span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Provide latest Share Capital Table covering all the movements (from inception to till date) accompanied with documents substanting each movement.</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="shareCapitalDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "shareCapitalDoc",
                                                            "shareCapitalDocName",
                                                            "shareCapitalDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('shareCapitalDoc')}
                                                        name="file"
                                                        types={fileTypesAll}
                                                        children={
                                                            <div className={getValues('shareCapitalDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('shareCapitalDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('shareCapitalDoc') !== '' ? 'Uploaded (Click to Change)' : 'Share Capital Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.shareCapitalDoc && errors.shareCapitalDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('shareCapitalDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Certificates for Startup India Registration specifically applicable to respective line of business</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="startupIndiaDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>
                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "startupIndiaDoc",
                                                            "startupIndiaDocName",
                                                            "startupIndiaDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('startupIndiaDoc')}
                                                        name="file"
                                                        types={fileTypesAll}
                                                        children={
                                                            <div className={getValues('startupIndiaDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('startupIndiaDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}
                                                                    style={{
                                                                        backgroundColor: nonEditAble 
                                                                            ? (getValues('startupIndiaDoc') !== '' ? 'gray' : 'gray') 
                                                                            : ''
                                                                    }}
                                                                    >
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('startupIndiaDoc') !== '' ? 'Uploaded (Click to Change)' : 'Startup India Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={nonEditAble}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.startupIndiaDoc && errors.startupIndiaDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('startupIndiaDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Certificates for  Angel Tax Exemption, MSME and other regulations specifically applicable to respective line of business</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="businessCertificateDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>
                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "businessCertificateDoc",
                                                            "businessCertificateDocName",
                                                            "businessCertificateDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('businessCertificateDoc')}
                                                        name="file"
                                                        types={fileTypesAll}
                                                        children={
                                                            <div className={getValues('businessCertificateDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('businessCertificateDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('businessCertificateDoc') !== '' ? 'Uploaded (Click to Change)' : 'Angel Tax'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.businessCertificateDoc && errors.businessCertificateDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('businessCertificateURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Filings relating to ROC, RBI and other applicable regulations.</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="filingsDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>
                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "filingsDoc",
                                                            "filingsDocName",
                                                            "filingsDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('filingsDoc')}
                                                        name="file"
                                                        types={fileTypesAll}
                                                        children={
                                                            <div className={getValues('filingsDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('filingsDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('filingsDoc') !== '' ? 'Uploaded (Click to Change)' : 'Filings Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.filingsDoc && errors.filingsDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('filingsDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                               
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Secretarial records such as MOA</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="moaDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>
                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "moaDoc",
                                                            "moaDocName",
                                                            "moaDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('moaDoc')}
                                                        name="file"
                                                        types={fileTypesAll}
                                                        children={
                                                            <div className={getValues('moaDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('moaDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}
                                                                    style={{
                                                                        backgroundColor: nonEditAble 
                                                                            ? (getValues('moaDoc') !== '' ? 'gray' : 'gray') 
                                                                            : ''
                                                                    }}
                                                                    >
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('moaDoc') !== '' ? 'Uploaded (Click to Change)' : 'MOA Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={nonEditAble}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.moaDoc && errors.moaDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('moaDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Secretarial records such as AOA</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="aoaDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>
                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "aoaDoc",
                                                            "aoaDocName",
                                                            "aoaDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('aoaDoc')}
                                                        name="file"
                                                        types={fileTypesAll}
                                                        children={
                                                            <div className={getValues('aoaDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('aoaDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}
                                                                    style={{
                                                                        backgroundColor: nonEditAble 
                                                                            ? (getValues('aoaDoc') !== '' ? 'gray' : 'gray') 
                                                                            : ''
                                                                    }}
                                                                    >
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('aoaDoc') !== '' ? 'Uploaded (Click to Change)' : 'AOA Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={nonEditAble}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.aoaDoc && errors.aoaDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('aoaDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Secretarial records such as COI, Board Meeting Minutes, EGM and AGM Minutes, Statutory Registers, Copies of Share Certificates etc.</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="secretarilaRecordsDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>
                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "secretarilaRecordsDoc",
                                                            "secretarilaRecordsDocName",
                                                            "secretarilaRecordsDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('secretarilaRecordsDoc')}
                                                        name="file"
                                                        types={fileTypesAll}
                                                        children={
                                                            <div className={getValues('secretarilaRecordsDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('secretarilaRecordsDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('secretarilaRecordsDoc') !== '' ? 'Uploaded (Click to Change)' : 'Certificates Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.secretarilaRecordsDoc && errors.secretarilaRecordsDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('secretarilaRecordsDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                
                                
                                
                               
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>RKRV's DD Team will be doing 100% vouching of the Books of 1 month, on a sample basis, which will be selected after reviewing the BoAs. Auditee team is required to provide the related Purchase Bills, Sales Bills, Expenses Bills etc. based on that.</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="billsDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>
                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "billsDoc",
                                                            "billsDocName",
                                                            "billsDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('billsDoc')}
                                                        name="file"
                                                        types={fileTypesAll}
                                                        children={
                                                            <div className={getValues('billsDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('billsDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('billsDoc') !== '' ? 'Uploaded (Click to Change)' : 'Vouching  Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.billsDoc && errors.billsDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('billsDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            
                               
                            <div className="row doc-row">
                                <div className='col-12'>
                                    <div className='doc-title mt-3'>
                                        <h6>Provide following statements as of  latest Date :</h6>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Certified Physical Verification Statement of Fixed Assets and Inventories</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="fixedAssetsDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "fixedAssetsDoc",
                                                            "fixedAssetsDocName",
                                                            "fixedAssetsDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('fixedAssetsDoc')}
                                                        name="file"
                                                        types={fileTypesAll}
                                                        children={
                                                            <div className={getValues('fixedAssetsDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('fixedAssetsDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('fixedAssetsDoc') !== '' ? 'Uploaded (Click to Change)' : 'Fixed Assets Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.fixedAssetsDoc && errors.fixedAssetsDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpens(getValues('fixedAssetsDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Download
                                        </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Confirmation of balance for bank balance, any loans given or taken</FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="bankBalanceConfrmDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>
                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "bankBalanceConfrmDoc",
                                                            "bankBalanceConfrmDocName",
                                                            "bankBalanceConfrmDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('bankBalanceConfrmDoc')}
                                                        name="file"
                                                        types={fileTypesAll}
                                                        children={
                                                            <div className={getValues('bankBalanceConfrmDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('bankBalanceConfrmDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('bankBalanceConfrmDoc') !== '' ? 'Uploaded (Click to Change)' : 'Bank Balance Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.bankBalanceConfrmDoc && errors.bankBalanceConfrmDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' />
                                            <Link
                                                onClick={() => handleClickOpens(getValues('bankBalanceConfrmDocURL'))}
                                                style={{ color: "#80809e" }}
                                            > Download
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Top 5 receivables </FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="receivablesDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>
                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "receivablesDoc",
                                                            "receivablesDocName",
                                                            "receivablesDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('receivablesDoc')}
                                                        name="file"
                                                        types={fileTypesAll}
                                                        children={
                                                            <div className={getValues('receivablesDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('receivablesDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('receivablesDoc') !== '' ? 'Uploaded (Click to Change)' : 'Receivables Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.receivablesDoc && errors.receivablesDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' />
                                            <Link
                                                onClick={() => handleClickOpens(getValues('receivablesDocURL'))}
                                                style={{ color: "#80809e" }}
                                            > Download
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-12 mt-2'>
                                    <FormLabel>Top 5 Payables </FormLabel>
                                    <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="payablesDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>
                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "payablesDoc",
                                                            "payablesDocName",
                                                            "payablesDocURL",
                                                            'pdfwordexcel')}
                                                        value={getValues('payablesDoc')}
                                                        name="file"
                                                        types={fileTypesAll}
                                                        children={
                                                            <div className={getValues('payablesDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('payablesDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('payablesDoc') !== '' ? 'Uploaded (Click to Change)' : 'Payables Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.payablesDoc && errors.payablesDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' />
                                            <Link
                                                onClick={() => handleClickOpens(getValues('payablesDocURL'))}
                                                style={{ color: "#80809e" }}
                                            > Download
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group d-flex justify-content-end mt-3 mr-2">
                            <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
                            {
                                (readyOnlyValue === false) ? (
                                    <Button
                                        type="submit"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        className="btn btn-primary submit px-3 ml-3"
                                    >
                                        <span>Save & Proceed Next</span>
                                    </Button>
                                ) : (<></>)
                            }

                        </div>
                    </form>
                </div>
                <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
            </section>
        </>
    )
}



export default FDDPriorityDocu