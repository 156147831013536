// import React, { useContext } from 'react'
// import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
// import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
// import DataContext from '../../../../context/DataContext';

// const Signupinfo = () => {
//   document.getElementById('theme').setAttribute('href', '/css/admin.css');
//   const { navigator } = useContext(DataContext)
//   const handleStartupPage = () =>{
//     localStorage.setItem('routePath', '/startup/dashboard')
//     navigator('/startup/dashboard')
//   }
//   return (
//     <>
//         <section>
//             <div className='container'>
//                 <div className='box'>
//                     <h4 className='font-weight-bold'>Start Your Application</h4>
//                     <p className='p-color mb-3'>Your Gateway to Potential Investor</p>
//                     <div className='row'>
//                         <div className='col-lg-6'>
//                             <p className='p-size mb-3'>Get ready to bridge the gap between your startup and potential investors! by proceeding,you'll find the sections needed to filled out along with an estimated time to complete each.</p>
//                             <p className='p-size mb-3'>This application form will autosave as you fill it out,it will also save each sections once you complete.So you can fill out the form in one go or over time.</p>
//                             <p className='p-size mb-4'>You can navigate between sections - you don't have to fill out the application in the order shown.</p>
//                             <button className='button'><UploadFileRoundedIcon/>  Download Checklist</button>
//                             <button className='button'><QuestionAnswerIcon/>  Need Help,Chat with us</button>
//                         </div>
//                         <div className='col-lg-6'>
//                             <h6 className='font-weight-bold mb-2'>You Must:</h6>
//                             <ul>
//                                 <li className='li-size mb-3'>Complete all the sections of the application</li>
//                                 <li className='li-size mb-3'>Answer all mandatory questions </li>
//                                 <li className='li-size mb-4'>Upload all necessary documents</li>
//                             </ul>
//                             <p className='li-size font-weight-bold mb-4'>It takes between 20-30 minutes to complete the entire process please have all your documents ready to upload</p>
//                             <center><button className='button-1' onClick={handleStartupPage}>START MY APPLICATION</button></center>
//                         </div>
//                     </div>
//                 </div>
//             </div>    
//         </section>  
//     </>
//   )
// }

// export default Signupinfo



import React, { useContext, useState } from 'react'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DataContext from '../../../../context/DataContext';
import { Box, Modal } from '@mui/material';
import Signupscstmodal from './Signupscstmodal';
import { Grid } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { TiMessages } from "react-icons/ti";
import { LuMessagesSquare } from "react-icons/lu";
import notifyService from '../../../../api/notifySerivce';

const Signupinfo = () => {
  const [selectedBox, setSelectedBox] = useState(null);

  document.getElementById('theme').setAttribute('href', '/css/admin.css');
  const { navigator, modalstyle, handleClose, open, handleOpen, } = useContext(DataContext)
  const handleStartupPage = () => {
    debugger
    console.log(selectedBox);
    
    handleOpen()
    if(selectedBox === null){
      notifyService('danger', 'Error', 'Please Select Any Type funding are you seeking')
    }
    switch (selectedBox) {
      case 1:
        localStorage.setItem("routePath", "/startup/dashboard");
        navigator("/startup/dashboard");
        break;
      case 2:
        localStorage.setItem("routePath", "/startup/dashboard");
        navigator("/startup/dashboard/scst");
        break;
      case 3:
        localStorage.setItem("routePath", "/startup/dashboard/scst");
        navigator("/startup/dashboard/scst");
        break;
      case 4:
        localStorage.setItem("routePath", "/startup/investor/tanfund");
        navigator("/startup/investor/tanfund");
        break;
      default:
    }

    // localStorage.setItem('routePath', '/startup/dashboard')
    // navigator('/startup/dashboard')

  }
  const handleBoxClick = (boxNumber) => {
    setSelectedBox(boxNumber);
  };
  return (
    // <>
    //     <section>
    //         <div className='container'>
    //             <div className='box'>
    //                 <h4 className='font-weight-bold'>Start Your Application</h4>
    //                 <p className='p-color mb-3'>Your Gateway to Potential Investor</p>
    //                 <div className='row'>
    //                     <div className='col-lg-6'>
    //                         <p className='p-size mb-3'>Get ready to bridge the gap between your startup and potential investors! by proceeding,you'll find the sections needed to filled out along with an estimated time to complete each.</p>
    //                         <p className='p-size mb-3'>This application form will autosave as you fill it out,it will also save each sections once you complete.So you can fill out the form in one go or over time.</p>
    //                         <p className='p-size mb-4'>You can navigate between sections - you don't have to fill out the application in the order shown.</p>
    //                         <button className='button'><UploadFileRoundedIcon />  Download Checklist</button>
    //                         <button className='button'><QuestionAnswerIcon />  Need Help,Chat with us</button>
    //                     </div>
    //                     <div className='col-lg-6'>
    //                         <h6 className='font-weight-bold mb-2'>You Must:</h6>
    //                         <ul>
    //                             <li className='li-size mb-3'>Complete all the sections of the application</li>
    //                             <li className='li-size mb-3'>Answer all mandatory questions </li>
    //                             <li className='li-size mb-4'>Upload all necessary documents</li>
    //                         </ul>
    //                         <p className='li-size font-weight-bold mb-4'>It takes between 20-30 minutes to complete the entire process please have all your documents ready to upload</p>
    //                         <center><button className='button-1' onClick={handleStartupPage}>START MY APPLICATION</button></center>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //         <Modal
    //             open={open}
    //             onClose={handleClose}
    //             aria-labelledby="modal-modal-title"
    //             aria-describedby="modal-modal-description"
    //         >
    //             <Box sx={modalstyle} className="form_tag_lg">

    //                 <Signupscstmodal />
    //             </Box>
    //         </Modal>
    //     </section>
    // </>
    <>

      <section>
        <div className="container">
          <div className="box">
            <h4 className="font-weight-bold">
              What type of funding are you seeking?
            </h4>

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-end"
              spacing={4}
            >
              <Grid item md={6} lg={6} sm={12} xs={12}>
                <div
                  className={`rectangle  ${selectedBox === 1 ? "clicked" : ""
                    }`}
                  onClick={() => handleBoxClick(1)}
                >
                  <div className="circle font-weight-bold">1</div>

                  <div className="rec-box">
                    <div>
                      <h4 className="font-weight-bold">
                        TANFUND
                        {/* <ErrorRoundedIcon /> */}
                      </h4>
                      <p>
                        Connecting Tamil Nadu startups with global investors.
                      </p>
                    </div>
                    {/* <div className='rec-box1'>
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <BorderLinearProgress variant="determinate" value={formData.teamInfoPercent} />
                                                </Box>
                                                <p className='mt-3 right' >{formData.teamInfoPercent}% Completed</p>
                                            </div> */}
                  </div>
                </div>
              </Grid>
              <Grid item md={6} lg={6} sm={12} xs={12}>
                {/* <div
                  className={`rectangle m-3 ${
                    selectedBox === 2 ? "clicked" : ""
                  }`}
                  onClick={() => handleBoxClick(2)}
                > */}
                <div className="rectangle tanseedbox ">
                  <div className="circle font-weight-bold">2</div>

                  <div className="rec-box">
                    <div>
                      <h4 className="font-weight-bold">
                        TANSEED 6.0
                        {/* <ErrorRoundedIcon /> */}
                      </h4>
                      <p>
                        Seed funding up to 15 lakhs for startups, exchanging a
                        3% equity stake.
                      </p>
                    </div>

                    {/* <div className='rec-box1'>
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <BorderLinearProgress variant="determinate" value={formData.teamInfoPercent} />
                                                </Box>
                                                <p className='mt-3 right' >{formData.teamInfoPercent}% Completed</p>
                                            </div> */}
                  </div>

                </div>
              </Grid>
              <Grid item md={6} lg={6} sm={12} xs={12}>
                <div
                  className={`rectangle  ${selectedBox === 3 ? "clicked" : ""
                    }`}
                  onClick={() => handleBoxClick(3)}
                >
                  <div className="circle font-weight-bold">3</div>

                  <div className="rec-box">
                    <div>
                      <h4 className="font-weight-bold">
                        TN SC/ST Startup Fund
                      </h4>
                      <p>
                        Exclusive funding support for socially marginalized
                        communities.
                      </p>
                    </div>
                  </div>
                  <div></div>
                </div>
              </Grid>
              <Grid item md={6} lg={6} sm={12} xs={12}>
                <div
                  className={`rectangle  ${selectedBox === 4 ? "clicked" : ""
                    }`}
                  onClick={() => handleBoxClick(4)}
                >
                  <div className="circle font-weight-bold">4</div>

                  <div className="rec-box">
                    <div>
                      <h4 className="font-weight-bold">
                        Investor Connect
                      </h4>
                      <p>
                        Investor Connect Program - Tanfund
                      </p>
                    </div>
                  </div>
                  <div></div>
                </div>
              </Grid>
              
            </Grid>
            <div >
              <div className=" d-flex justify-content-end btnapplication  align-content-end mt-2">
                <button className="button-1" onClick={handleStartupPage}>
                  START / VIEW MY APPLICATION
                </button>
              </div>
            </div>
            <div className=" d-flex  justify-content-between  mt-4  ">

              <div className="NeedHelpBtn">
                <button className=" p-2">
                  <LuMessagesSquare className="messagesvg tanseedbox" />
                  <span className=" p-2 tanseedbox "> Need a help,chat with us</span>
                </button>
              </div>
              {/* <button className="button-1" onClick={handleStartupPage}>
                    START MY APPLICATION
                  </button> */}
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Signupinfo