import { Button, Dialog, FormHelperText } from '@mui/material';
import React from 'react'
import { FileUploader } from "react-drag-drop-files";
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import notifyService from '../../api/notifySerivce';
import { useContext } from 'react';
import DataContext from '../../context/DataContext';
import apiService from '../../api/apiService';
import SaveIcon from "@mui/icons-material/Save";
import useDidMountEffect from '../../hooks/useDidMountEffect';
const fileTypes = ["XLSX"];
const Scstimportpage = () => {
    const { handleSubmit, control, setValue, getValues,reset, clearErrors, formState: { errors }, } = useForm({});
    const { setPageLoading } = useContext(DataContext);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [fileData, setFileData] = useState(null)
    const downloadFile = () => {
        const pdfUrl = "https://startuptn-public-files.s3.ap-south-1.amazonaws.com/User_Import_Template.xlsx";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "SCST_ADMIN_Template.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    const handleFileUpload = (event) => {
        if (event !== null) {
            let logoselectedFile;
            if (event.target === undefined) {
                logoselectedFile = event;
            } else {
                logoselectedFile = event.target.files[0];
            }
            if (logoselectedFile) {
                const fileSizeLimit = 5 * 1024 * 1024;
                if (logoselectedFile.size > fileSizeLimit) {
                    notifyService(
                        "danger",
                        "File Size Exceeded",
                        "Please upload a file smaller than 5 MB."
                    );
                    return;
                }
                // var imagetype = event.target.files[0].type
                // var imagedatatype = imagetype.split("/")
                // var img_crt_type = imagedatatype[1]
                var reader = new FileReader();
                var imagetype = logoselectedFile.type
                var imagedatatype = imagetype.split("/")
                var img_crt_type = imagedatatype[1]
                if (img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    var fileValue = logoselectedFile
                    reader.readAsDataURL(logoselectedFile);
                    reader.onload = () => {
                        setFileData(logoselectedFile)
                        setValue('fileName', fileValue.name)
                        clearErrors('fileName')
                    }
                } else {
                    notifyService('danger', 'File Format Invalided', 'Please check your file format')
                }
            }
        } else {
            // Handle case when event is null (optional)
        }
    };
    const onSubmit = (data) => {
        setPageLoading(true)
        const formData = new FormData()
        formData.append("file", fileData);
        apiService("user/excel/import", formData, "fileupload").then(
            (res) => {
                setPageLoading(false);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService("success", "Success", "Saved successfully !!");
                        setOpenSuccess(true);
                    }
                }
            }
        );
    };
    const onError = (error) => {
        console.log(error);
        notifyService("danger", "Error", "Please check mandatory fields");
    };
    const handleCloseSuccess = () => {
        setOpenSuccess(false);
        setFileData(null)
        reset()
    };
    return (
        <>
            <section>
                <div className="container-fluid">
                    <div className="form_div chart p-5">
                        <center>
                            <div className='mt-3'>
                                <h5>Please download the template excel and add the users in to the template excel and upload.</h5>
                                <p>Note: If user already exists in the system, the user data will be updated with excel content.</p>
                            </div>
                        </center>

                        <form className="signin-form py-5" onSubmit={handleSubmit(onSubmit, onError)}>
                            <div>
                                <div className="d-flex justify-content-center">
                                    <div className='form-group'>
                                        <div className='down-border'>
                                            <p className='down-btn-dpiit' onClick={() => downloadFile()}>
                                                <VisibilityIcon className="icon-size mr-1" /> Download Template
                                            </p>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <Controller name="fileName" control={control} defaultValue="" rules={{ required: 'File is required' }}
                                            render={({ field }) =>
                                                <FileUploader
                                                    {...field}
                                                    handleChange={(e) => handleFileUpload(e)}
                                                    name="file"
                                                    types={fileTypes}
                                                    children={
                                                        <div className={fileData !== null ? 'fileupload-pdf-bg-active' : 'fileupload-pdf-bg'}>
                                                            <center>
                                                                <p className={fileData !== null ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon" />
                                                                        {fileData !== null ? 'Uploaded' : 'Upload Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format should be in .XLSX</FormHelperText>
                                        <FormHelperText className='text-danger'>{errors.fileName && errors.fileName.message}</FormHelperText>
                                    </div>
                                </div>
                            </div>
                            {
                                fileData !== null && (
                                    <center>
                                        <Button
                                            type="submit"
                                            startIcon={<SaveIcon />}
                                            variant="contained"
                                            className="btn btn-primary submit px-3"
                                        >
                                            <span>Submit</span>
                                        </Button>
                                    </center>
                                )
                            }
                        </form>
                    </div>
                </div>
            </section>
            <Dialog
                open={openSuccess}
                onClose={handleCloseSuccess}
                maxWidth="sm"
                fullWidth
            >
                <center><img src='/images/verified.png' style={{ width: '20%' }} alt='' /></center>
                <h4 className='text-center mt-3'>Success</h4>
                <div className='text-center my-3'>
                    <h6>An email will be sent to you shortly, once users are onboarded.</h6>
                </div>
                <div className='text-center mb-3'>
                    <Button onClick={handleCloseSuccess} variant='contained' color="success">
                        OK
                    </Button>
                </div>
            </Dialog>
        </>
    )
}

export default Scstimportpage