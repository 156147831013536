import React, { useContext, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import { Link, NavLink } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
// import Badge from '@mui/material/Badge';
// import MailIcon from '@mui/icons-material/Mail';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import MoreIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
// import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
// import PersonAdd from '@mui/icons-material/PersonAdd';
import './navbar.css'
import DataContext from '../../context/DataContext';
import KeyIcon from '@mui/icons-material/Key';
import { Modal } from '@mui/material';
import Changepassword from '../change-password/Changepassword';
import $ from 'jquery';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import apiService from '../../api/apiService';
// import notifyService from '../../api/notifySerivce';
import CustomLink from '../common-page/CustomLink';
const Navbar = ({ scrollContact, scrollAbout }) => {

  const { navigator, modalstyle, handleClose, userLogo, tanseedPartnerRemoveFilter, tanseedJuryRemoveFilter } = useContext(DataContext)
  const [anchorEl, setAnchorEl] = useState();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const userName = localStorage.getItem('name')
  const openMenu = Boolean(anchorEl);

  const [passLable, setPassLable] = useState('Old Password')
  useDidMountEffect(() => {
    if (localStorage.getItem('forcePassword') === 'true') {
      setOpenPassword(true)
      setPassLable('OTP')

    }
  }, [userName, userLogo])
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const role = localStorage.getItem('role')
  const routeNameValue = localStorage.getItem('routePath')
  // const val = '/startup/associated-partners'
  // const val1 = '/startup/associated-hub'
  const handleProfilePage = () => {
    if (role === 'INVESTOR') {
      navigator('/investor/profile')
    } if (role === 'PARTNER') {
      navigator('/partner/profile')
    }
    handleCloseMenu()
  }
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const loginPage = () => {
    navigator('sign-in')
  }
  const ReportsClick = () => {
    navigator('/reports/list')
  }
  // const logoutBtn = async (data, event) => {
  //   localStorage.clear()
  //   setAnchorEl(null);
  //   navigator('/')
  //   await apiService(`tanfund/logout?userid=${``}`, '', 'unauthget').then((response) => {
  //       if (response && response.data.responseStatus === 'Success') {

  //       }
  //   })
  // }

  const logoutBtn = () => {
    var userId = localStorage.getItem('userId')
    apiService(`user/logout?userid=${userId}`, '', 'unauthget').then((res) => {
      if (res) {
        if (res.data && res.data.responseStatus === 'Success') {
          localStorage.clear()
          // notifyService('success', 'Success', 'Logged out successfully.');
          setAnchorEl(null);
          navigator('/')
          handleClose(false);
        }
      }
    })
  }




  // const logoutBtn = async (userId) => {
  //   try {
  //       await apiService(`user/logout?userid=${userId}`, '', 'get');
  //       localStorage.clear();
  //       // notifyService('success', 'Success', 'Logged out successfully.');
  //       navigator('/');
  //     }
  //      catch (error) {
  //     //   console.error('Logout error:', error);
  //     //   notifyService('error', 'Error', 'Failed to logout. Please try again.');
  //   }
  // };
  // const handleLogout = () => {
  //   const userId = localStorage.getItem('userId');
  //   logoutBtn(userId);
  // };


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [openPassword, setOpenPassword] = useState(false);
  const handlePasswordOpen = () => {
    setPassLable('Old Password')
    setOpenPassword(true);
  }
  const handlePasswordClose = () => {
    setOpenPassword(false)
    debugger
    if (localStorage.getItem('forcePassword') === 'true') {
      setOpenPasswordMsg(true)

    } else {
      localStorage.setItem('forcePassword', false)
    }

  };
  $(document).ready(function () {
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 90) {
        $('.appNavbar').addClass('toptotop')
        $('.appNavbar a.scroll_down').removeClass('d-none')
        $('.appNavbar a.scroll_down').addClass('d-inline')
        $('.appNavbar a.scroll_up').removeClass('d-inline')
        $('.appNavbar a.scroll_up').addClass('d-none')
      } else {
        $('.appNavbar').removeClass('toptotop')
        $('.appNavbar a.scroll_down').removeClass('d-inline')
        $('.appNavbar a.scroll_down').addClass('d-none')
        $('.appNavbar a.scroll_up').removeClass('d-none')
        $('.appNavbar a.scroll_up').addClass('d-inline')
        // debugger
      }
    })
  })
  const [openPasswordMsg, setOpenPasswordMsg] = useState(false)
  const handlePasswordMsgClose = () => {
    setOpenPasswordMsg(false)
    logoutBtn()
  }
  // const scrollToBtn = () => {
  //   document.getElementsByTagName('main')[0].scrollTo(0, 100000)
  // }
  let redirects = localStorage.getItem('redirects')
  const handleClick = (event) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
    }
    tanseedPartnerRemoveFilter()
    tanseedJuryRemoveFilter()
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar className='appNavbar' position="fixed" sx={{ padding: '10px', backgroundColor: '#253B80', boxShadow: 'none' }}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Link to={'https://startuptn.in/'} target='_blank' className='scroll_up'>
                <img src="/images/startup-white.png" alt="blue horizontal.png" className='logo_img' />
              </Link>
              {/* <Link to={'https://tngim2024.com/'} target='_blank' className='scroll_up'>
                <img src="/images/TNLogoWhiteLogo1.png" alt="blue horizontal.png" className='logo_tanfund_img' />
              </Link> */}
              <Link to={'/'} className='scroll_down d-none'>
                <img src="/images/Tanfundlogo-white-1.png" alt="blue horizontal.png" className='logo_img' />
              </Link>
              <Link to={'https://startuptn.in/'} target='_blank' className='scroll_down d-none'>
                <img src="/images/startup_racket.svg" alt="blue horizontal.png" className='logo_sml' />
              </Link>
              {/* <Link to={'https://tngim2024.com/'} target='_blank' className='scroll_down d-none'>
                <img src="/images/tamilnadu_t.svg" alt="blue horizontal.png" className='logo_sml' />
              </Link> */}
            </Typography>

            {
              userName != null &&
              <>
                <div>
                  <Box sx={{ display: { lg: 'none', md: 'block' } }}>
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      className='NavtabMenu'
                      onClick={handleOpenNavMenu}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorElNav}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={Boolean(anchorElNav)}
                      onClose={handleCloseNavMenu}
                    >
                      <MenuItem>
                        <Avatar alt={userName} src={userLogo} /> {userName}
                      </MenuItem>
                      <Divider />
                      {
                        (role === 'INVESTOR' || role === 'PARTNER') && (
                          <>
                            <MenuItem onClick={handleProfilePage}>
                              <Avatar /> Profile
                            </MenuItem>
                            <Divider />
                          </>

                        )
                      }
                      <MenuItem onClick={handlePasswordOpen}>
                        <ListItemIcon>
                          <KeyIcon fontSize="small" />
                        </ListItemIcon>
                        Change Password
                      </MenuItem>
                      <MenuItem onClick={logoutBtn}>
                        <ListItemIcon>
                          <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                      </MenuItem>

                    </Menu>
                  </Box>
                  <Box sx={{ display: { lg: 'block', md: 'none', sm: 'none', xs: 'none' } }}>
                    {
                      role === 'STARTUP' && (
                        <Button className='menuBtn'>
                          <NavLink className='link_btn' to='/startup/all-dashboard'>Dashboard</NavLink>
                        </Button>
                      )
                    }
                    {/* {
                      (role === 'STARTUP' && redirects != 'TanseedCompleted') && (<Button className='menuBtn'>
                        <NavLink className='link_btn' to={routeNameValue === '/startup/dashboard/SCST' ? val1 : val}>Dashboard</NavLink>
                      </Button>)
                    } */}
                    {
                      (role !== 'TANSEED') ? (
                        <Button className='menuBtn'>
                          {
                            (redirects !== 'TanseedCompleted') ? (
                              <NavLink
                                className='link_btn'
                                onClick={handleClick}
                                to={role === 'STARTUP' ? routeNameValue : routeNameValue}
                              >
                                {role === 'STARTUP' ? 'Your Application' : 'Dashboard'}
                              </NavLink>
                            ) : (
                              <NavLink
                                className='link_btn'
                                to={`/startup/dashboard/tanseed`}
                                onClick={handleClick}
                              >
                                Your Application
                              </NavLink>
                            )
                          }

                        </Button>
                      ) : (
                        <>
                          <Button className='menuBtn'>
                            <NavLink
                              className='link_btn'
                              to={'tanseed/partner'}
                              onClick={handleClick}
                            >
                              Dashboard
                            </NavLink>
                          </Button>
                          <Button className='menuBtn'>
                            <NavLink
                              className='link_btn'
                              to={'tanseed/startups'}
                              onClick={handleClick}
                            >
                              Startup
                            </NavLink>
                          </Button>
                          {/* <Button className='menuBtn'>
                            <NavLink
                              className='link_btn'
                              to={'tanseed/partnerslist'}
                            >
                              Partners
                            </NavLink>
                          </Button> */}
                        </>
                      )
                    }

                    {
                      role === 'SCSTHUBAVP' && (
                        <>
                          <Button className='menuBtn'>
                            <NavLink className='link_btn' to='/scsthubavp/startups/list'>Startups</NavLink>
                          </Button>

                          {/* <Button className='menuBtn'>
                            <NavLink className='link_btn' to='/scsthubavp/startups/evaluators/list'>Evaluators</NavLink>
                          </Button> */}
                        </>
                      )
                    }
                    {/* {
                      (role === 'SCSTHUBAVP') && (
                        <Button className='menuBtn'>
                          <NavLink className='link_btn' to='/reports/list'>Reports</NavLink>
                        </Button>
                      )
                    } */}
                    {/* <Button className='menuBtn'>
                      <NavLink
                        className='link_btn'

                        to={role === 'STARTUP' ? routeNameValue : routeNameValue}
                      >
                        {role === 'STARTUP' ? 'Your Application' : 'Startups'}
                      </NavLink>
                    </Button> */}
                    {/* <Button className='menuBtn'>
                      <NavLink className='link_btn'  to={routeNameValue}>Your Application</NavLink>
                    </Button> */}
                    <span className='username'>{userName}</span>
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                    >
                      <Avatar alt={userName} src={userLogo} />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={openMenu}
                      onClick={handleCloseMenu}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      {
                        (role === 'INVESTOR' || role === 'PARTNER') && (
                          <>
                            <MenuItem onClick={handleProfilePage}>
                              <Avatar /> Profile
                            </MenuItem>
                            <Divider />
                          </>

                        )
                      }
                      <MenuItem onClick={handlePasswordOpen}>
                        <ListItemIcon>
                          <KeyIcon fontSize="small" />
                        </ListItemIcon>
                        Change Password
                      </MenuItem>
                      <MenuItem onClick={logoutBtn}>
                        <ListItemIcon>
                          <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                      </MenuItem>
                    </Menu>
                  </Box>
                </div>
              </>
            }
            {
              userName === null &&
              <>
                <Box sx={{ display: { lg: 'none', md: 'block' } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    className='NavtabMenu'
                    onClick={handleOpenNavMenu}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                  >
                    {/* {navItems.map((item) => (
                      <MenuItem key={item.id} onClick={handleCloseNavMenu}>
                        <Link className='link_btn' onClick={scrollToBtn}>{item.lable}</Link>
                      </MenuItem>
                    ))} */}
                    <MenuItem onClick={handleCloseNavMenu}>
                      <CustomLink className='link_btn' onClick={scrollAbout}>About</CustomLink>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <CustomLink className='link_btn' onClick={scrollContact}>Contact</CustomLink>
                    </MenuItem>
                    <MenuItem variant="outlined" onClick={loginPage}>Login</MenuItem>

                  </Menu>
                </Box>
                <Box sx={{ display: { lg: 'block', md: 'none', sm: 'none', xs: 'none' } }}>
                  {/* {navItems.map((item) => (
                    <Button className='menuBtn' key={item.id} onClick={scrollToBtn}>
                      <Link>About</Link>
                    </Button>
                  ))} */}
                  <Button className='menuBtn' onClick={scrollAbout}>
                    {/* to={`/zoom`} */}
                    <CustomLink>About</CustomLink>
                  </Button>
                  <Button className='menuBtn' onClick={scrollContact}>
                    <CustomLink>Contact</CustomLink>
                  </Button>
                  <Button className='login_btn' onClick={loginPage}>Login</Button>
                </Box>
              </>
            }
          </Toolbar>
        </AppBar>
      </Box>
      <Modal
        open={openPassword}
      >
        <Box sx={modalstyle} className="form_tag">
          <Changepassword handlePasswordClose={handlePasswordClose} passLable={passLable} />
        </Box>

      </Modal>
      <Modal
        open={openPasswordMsg}
      >
        <Box sx={modalstyle} className="form_tag p-4">
          <div className='text-center'>
            <h4 className='mb-3'>Password Updated.</h4>
            <h6 className='mb-3'>You have been logged out.</h6>
            <p className='mb-3'>Kindly login again with updated password</p>
            <div className="form-group ">
              <Button variant="contained" onClick={handlePasswordMsgClose} className="btn btn-cancel submit px-3 ml-3">OK</Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default Navbar