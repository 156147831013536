import React, { useState } from 'react'
// import Viewimage from '../../../../../api/Viewimage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
// import { Box, Icon, Stack, Tab } from '@mui/material';
import Viewimage from '../../api/Viewimage';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import * as pdfjsLib from 'pdfjs-dist';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
// import { MinimalButton, Tooltip, Viewer, Worker } from '@react-pdf-viewer/core';
import { Worker, Viewer, SpecialZoomLevel, Icon, MinimalButton, Position, Tooltip } from '@react-pdf-viewer/core';
const Fundingscstview = ({ details, detail, }) => {
    const pdfUrl = `data:image/pdf;base64,${details.pitchDeck}`

    // {`data:image/jpeg;base64,${viewImage}`}
    // const pdfUrl = `/sample1.pdf`;
    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState('')
    const handleClickOpen = (value) => {
        setViewImage(value)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const workerSrc = ` https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
    // https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js
    const disableScrollPlugin = () => {
        const renderViewer = ({ slot }) => {
            if (slot.subSlot && slot.subSlot.attrs && slot.subSlot.attrs.style) {
                slot.subSlot.attrs.style = {
                    ...slot.subSlot.attrs.style,
                    overflow: 'hidden',
                };
            }

            return slot;
        };

        return {
            renderViewer,
        };
    };

    const disableScrollPluginInstance = disableScrollPlugin();
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { GoToPreviousPage, GoToNextPage } = pageNavigationPluginInstance;
    return (
        <section>
            {/* <div className='header_tag'>
                <span className='d-flex'><img src={details.startupLogoName} className='img-fluid user_logos' alt='' /><h3 className='startupname'>{detail.startupName}</h3></span>
                <span className='black-clr'><img src='/images/phone.png' alt='' className='mr-2' /> {detail.startupPhone ? (detail.startupPhone) : ('-')}</span>
                <span className='black-clr'><img src='/images/email.png' alt='' className='mr-2' /> {detail.startupEmail ? (detail.startupEmail) : ('-')}</span>
                <span className='social-datas'>
                    <Stack className='social-Stack' direction="row" spacing={1}>
                        {(details.linkedIn) && (
                            <Link to={`${details.linkedIn}`} target="_blank">
                                <img src='/images/linkedin.png' alt='LinkedIn' className='linkedinicon' />
                            </Link>
                        )}
                        {(details.website) && (
                            <Link to={`${details.website}`} target="_blank">
                                <img src='/images/globe.png' alt='Website' className='websiteicon' />
                            </Link>
                        )}
                    </Stack>
                </span>
            </div> */}
            {/* <div className='rec-info1 mt-4'>
                <span className='d-flex'><img src='/images/purple-icon.png' alt='' className='purple-star mr-3' /><b><span className='grey-clr'>Incubated from </span><span className='purple-clr'>TAMILNADU AGRICULTURE COLLEGE</span></b></span>
            </div>
            <div className='rec-info2 mt-3'>
                <span className='d-flex'><img src='/images/blue-icon.png' alt='' className='purple-star mr-3' /><b><span className='blue-clr'>IAF award, Recognized by TNAU</span></b></span>
            </div> */}
            <div className=''>
                {/* <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Funding & Financials" value="1" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" className='application '>
                            
                        </TabPanel>
                    </TabContext>
                </Box> */}
                <div className='mt-4'>
                    <div className='row'>
                        {/* <div className='col-lg-4 mb-3'>
                            <p><b>Product/Service Uniqueness :</b></p>
                            <p>{details.uniqueness}</p>
                        </div> */}
                        {/* <div className='col-lg-4 mb-3'>
                            <p><b>Funding Projection :</b></p>
                            <p>{details.fundProjection ? (details.fundProjection) : (`NA`)}</p>
                        </div> */}
                        <div className='col-lg-4 mb-3'>
                            <p ><b>Revenue Generated (Rs.) :</b></p>
                            <p>{details.annualRevenue}</p>
                        </div>

                        <div className='col-lg-4 mb-3'>
                            <p><b>Funding Expected (Rs.) :</b></p>
                            <p>{details.expectedFunding}</p>
                        </div>
                        {details.startupfundutilization && (
                            
                            <div className='col-lg-8 mb-3'>
                                <p ><b>Utilization of Fund :</b></p>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='center'>No</TableCell>
                                                <TableCell align='center'>Expenses</TableCell>
                                                <TableCell align='center'>Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {details.startupfundutilization.map((list, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align='center'>{index + 1}</TableCell>
                                        
                                                    <TableCell align='center'>{list.expenses}</TableCell>
                                                    <TableCell align='center'>{list.amount}</TableCell>
                                                    

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                                {/* <p ><b>Utilization of Fund :</b></p>
 <p>{details.utilizationDesc ? details.utilizationDesc : 'NA'}</p> */}
                            </div>
                        )}

                        {/* <div className='col-lg-4 mb-3'>
                            <p ><b>Pitch Deck File :</b></p>
                            {
                                details.pitchDeckName ? (
                                    <div className='ml-auto'>
                                        <span className='viewdoc' onClick={() => handleClickOpen(details.pitchDeckName)}>View Document</span>
                                    </div>
                                ) : (`-`)
                            }
                        </div> */}
                    </div>
                </div>
            </div>
            <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
            {
                details.pitchDeck && (
                    <>
                        <p ><b>Pitch Deck File :</b></p>
                        <div className='full-pdf' >
                            <Worker workerUrl={workerSrc}>
                                <Viewer
                                    fileUrl={pdfUrl}
                                    defaultScale={SpecialZoomLevel.PageFit}
                                    plugins={[
                                        disableScrollPluginInstance,
                                        pageNavigationPluginInstance,
                                    ]}
                                />
                            </Worker>

                            <div className='pdf-left-arrow'>
                                <GoToPreviousPage>
                                    {(props) => (
                                        <Tooltip
                                            position={Position.BottomCenter}
                                            target={
                                                <MinimalButton onClick={props.onClick}>
                                                    <Icon size={16}>
                                                        <path d="M18.4.5,5.825,11.626a.5.5,0,0,0,0,.748L18.4,23.5" />
                                                    </Icon>
                                                </MinimalButton>
                                            }
                                            content={() => 'Previous page'}
                                            offset={{ left: 0, top: 8 }}
                                        />
                                    )}
                                </GoToPreviousPage>
                            </div>
                            <div className='pdf-right-arrow' >
                                <GoToNextPage>
                                    {(props) => (
                                        <Tooltip
                                            position={Position.BottomCenter}
                                            target={
                                                <MinimalButton onClick={props.onClick}>
                                                    <Icon size={16}>
                                                        <path d="M5.6.5,18.175,11.626a.5.5,0,0,1,0,.748L5.6,23.5" />
                                                    </Icon>
                                                </MinimalButton>
                                            }
                                            content={() => 'Next page'}
                                            offset={{ left: 0, top: 8 }}
                                        />
                                    )}
                                </GoToNextPage>
                            </div>
                        </div>
                    </>
                )
            }

        </section>
    )
}

export default Fundingscstview
