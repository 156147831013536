import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// import { useTheme } from '@mui/material/styles';
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../../api/apiService';
import { MuiFileInput } from 'mui-file-input';
import { FormHelperText, TextField } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DataContext from '../../../context/DataContext';
import { Link } from 'react-router-dom';
import PreviewIcon from '@mui/icons-material/Preview';
import notifyService from '../../../api/notifySerivce';
import Viewimage from '../../../api/Viewimage';

const StartupAdditionalinfo = ({ data, statusValue, partnerId, historyList }) => {
    // console.log(statusValue)
    const { id } = data
    const { VisuallyHiddenInput, setPageLoading } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, getValues, control, clearErrors, setValue, formState: { errors } } = useForm(
        { values: formData }
    );
    const [additionalDocURL, setAdditionalDocURL] = useState(null)
    const [additionalDocValue, setAdditionalDocValue] = useState(null)
    const [open, setOpen] = useState(false);
    const [checkMethod, setCheckMethod] = useState(false)
    let logoselecetdFile
    // const handleFileUpload = (event, filekey, fileName, setdata, setUrl) => {
    //     setValue(filekey, "")
    //     setValue(fileName, "")
    //     setdata('')
    //     setUrl('')
    //     if (event) {
    //         if (event.target === undefined) {
    //             logoselecetdFile = event
    //         } else {
    //             logoselecetdFile = event.target.files[0]
    //         }
    //         if (logoselecetdFile) {
    //             var reader = new FileReader();
    //             var imagetype = logoselecetdFile.type
    //             var imagedatatype = imagetype.split("/")
    //             var img_crt_type = imagedatatype[1]
    //             if (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png" || img_crt_type === "pdf") {
    //                 if (event.target === undefined) {
    //                     setdata(event)
    //                 } else {
    //                     setdata(event.target.files[0])
    //                 }
    //                 var fileValue = logoselecetdFile
    //                 reader.readAsDataURL(logoselecetdFile);
    //                 reader.onload = () => {
    //                     var logourl1 = reader.result;
    //                     var spl = logourl1.split(',')
    //                     var ImageValue = spl[1]
    //                     var img_name = fileValue.name
    //                     setUrl(logourl1)
    //                     setValue(filekey, ImageValue)
    //                     setValue(fileName, img_name)
    //                     clearErrors(filekey);
    //                     clearErrors(fileName);
    //                     debugger
    //                 }
    //             }
    //         }
    //     }

    // }
    const handleFileUpload = (event, filekey, fileName, setdata, setUrl) => {
        debugger
        if (event) {
            if (event.target === undefined) {
                logoselecetdFile = event
            } else {
                logoselecetdFile = event.target.files[0]
            }
            const maxFileSize = 10 * 1024 * 1024;
            if (logoselecetdFile) {
                if (logoselecetdFile.size < maxFileSize) {
                    var reader = new FileReader();
                    var imagetype = logoselecetdFile.type
                    var imagedatatype = imagetype.split("/")
                    var img_crt_type = imagedatatype[1]
                    if (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png" || img_crt_type === "pdf") {
                        setValue(filekey, "")
                        setValue(fileName, "")
                        setdata('')
                        setUrl('')
                        if (event.target === undefined) {
                            setdata(event)
                        } else {
                            setdata(event.target.files[0])
                        }
                        var fileValue = logoselecetdFile
                        reader.readAsDataURL(logoselecetdFile);
                        reader.onload = () => {
                            var logourl1 = reader.result;
                            var spl = logourl1.split(',')
                            var ImageValue = spl[1]
                            var img_name = fileValue.name
                            setUrl(logourl1)
                            setValue(filekey, ImageValue)
                            setValue(fileName, img_name)
                            clearErrors(filekey);
                            clearErrors(fileName);
                            debugger
                        }

                    } else {
                        notifyService(
                            "danger",
                            "File Format Invalided",
                            "Please check your file format"
                        );
                    }
                } else {
                    notifyService(
                        "danger",
                        "File size exceeds 10MB",
                        "Please check your file size"
                    );
                }
            }
        }
    }
    const onSubmit = (data) => {
        setPageLoading(true)
        var getform = data
        getform.id = id
        getform.status = 1
        getform.userId = partnerId
        apiService('startup/additionalinfo/save', getform, 'post').then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Saved successfully !!')
                    historyList()
                    handleClose()
                }
            }
        })
    };
    const handleClickOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    const [openImage, setOpenImage] = useState(false);
    const [viewImage, setViewImage] = useState('')
    const handleClickOpenImage = (value) => {
        setViewImage(value)
        setOpenImage(true);
    };
    const handleCloseImage = () => {
        setOpenImage(false);
    };
    return (
        <>
            {
                statusValue === 0 ? (
                    <button className='resubmit_btn' onClick={handleClickOpen}>Upload</button>) : (
                    <>
                        {
                            data.document ? (<button className='resubmit_btn' onClick={() => handleClickOpenImage(data.document)}>View Document</button>) : (`-`)
                        }
                        {/* <button className='resubmit_btn' onClick={() => handleClickOpenImage(data.document)}>View Document</button> */}
                    </>
                )
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    Additional Info
                </DialogTitle>
                <DialogContent>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            {/* <div className='col-lg-12 col-12 mb-3'>
                                Comment : <b>{getValues(`comments`)}</b>
                            </div> */}
                            <div className='col-lg-12 col-12'>
                                {
                                    (checkMethod === false) ? (
                                        <div className="form-group mt-2">
                                            <Controller name="additionalDoc" control={control} defaultValue="" rules={{ required: 'Additional Documents File is required' }}
                                                 render={({ field: { value, onChange, ...field } }) => (
                                                    <MuiFileInput
                                                        {...field}
                                                        onChange={(file) => {
                                                            if (file) {
                                                                handleFileUpload(file, "additionalDoc", "additionalDocName", setAdditionalDocValue, setAdditionalDocURL);
                                                            } else {
                                                                setAdditionalDocValue('');
                                                                setValue('additionalDoc', '');
                                                            }
                                                            // onChange(file);
                                                        }}
                                                        // onChange={(e) => handleFileUpload(e, "additionalDoc", "additionalDocName", setAdditionalDocValue, setAdditionalDocURL)}
                                                        value={additionalDocValue}
                                                        variant='outlined'
                                                        fullWidth={true}
                                                        label="Upload Additional Document"
                                                        placeholder='Choose File' />
                                                    )}
                                                 />
                                            <FormHelperText className='text-danger'>{errors.additionalDoc && errors.additionalDoc.message}</FormHelperText>
                                            <span className='font-sm'>Maximum 10 mb allowed doc (png, jpg, jpeg, pdf) <span className='text-danger'>*</span></span>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                statusValue === 0 ? (
                                                    <center><p className='mb-0 mb-4'>Upload Additional Document*</p></center>
                                                ) : (
                                                    <center><p className='mb-0 mb-4'>View Document</p></center>
                                                )
                                            }

                                            {
                                                statusValue === 0 ? (
                                                    <>
                                                        <div className='row'>
                                                            <div className='col-lg-8 col-12 mb-3'>
                                                                <Controller name="additionalDoc" control={control} defaultValue="" rules={{ required: 'Additional Documents File is required' }}
                                                                    render={({ field }) =>
                                                                        <Button
                                                                            component="label"
                                                                            variant="contained"
                                                                            {...field}
                                                                            fullWidth={true}
                                                                            onChange={(e) => handleFileUpload(e, "additionalDoc", "additionalDocName", setAdditionalDocValue, setAdditionalDocURL)}
                                                                            startIcon={<CloudUploadIcon />}
                                                                            href="#file-upload"
                                                                        >
                                                                            Uploaded
                                                                            <VisuallyHiddenInput type="file" />
                                                                        </Button>

                                                                    } />
                                                                <span className='font-sm'>Maximum 10 mb allowed doc (png, jpg, jpeg, pdf) <span className='text-danger'>*</span></span>
                                                            </div>
                                                            <div className='col-lg-4 col-12 mt-1'>
                                                                <Button variant="contained" className='viewbtn-div'>
                                                                    <Link to={`${additionalDocURL}`} target="_blank" color='white'>
                                                                        <PreviewIcon sx={{ mr: 1 }} />
                                                                        View
                                                                    </Link>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    // <div className='col-lg-12 col-12 my-2'>
                                                    <center className='my-2'>
                                                        <Button variant="contained" className='viewbtn-div'>
                                                            <Link to={`${additionalDocURL}`} target="_blank" color='white'>
                                                                <PreviewIcon />
                                                                View
                                                            </Link>
                                                        </Button>
                                                    </center>
                                                    // </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            {/* <div className='col-lg-12 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="startupComments" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                                variant='outlined'
                                                label="Your Comment"
                                                placeholder='Enter Your Comment'
                                                fullWidth
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.startupComments && errors.startupComments.message}</FormHelperText>
                                </div>
                            </div> */}
                        </div>
                        <div className='text-center p-3'>
                            <Button className='mr-3' onClick={handleClose} variant="outlined">
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained">
                                Submit
                            </Button>
                        </div>
                    </form>
                </DialogContent>

            </Dialog>
            <Viewimage viewImage={viewImage} open={openImage} handleClose={handleCloseImage} />
        </>
    )
}

export default StartupAdditionalinfo