import React, { useContext, useState } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import DataContext from "../../../../context/DataContext";
import notifyService from "../../../../api/notifySerivce";
import { Link } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Viewimage from "../../../../api/Viewimage";
import DeleteIcon from "@mui/icons-material/Delete";
import cryptodecryptJS from "../../../../hooks/cryptodecryptJS";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import PreliminaryTextPart1 from "./PreliminaryTextPart1";
import PreliminaryTextPart2 from "./PreliminaryTextPart2";
// const fileTypes = ["pdf", "XLSX", "zip", "doc", "docx"];
const fileTypesAll = ["pdf", "XLSX", "doc", "docx"];
// const fileTypesExcel = ["pdf", "XLSX"];
const PreliminaryDocumentPart2 = () => {
  const [formData, setFormData] = useState({});
  const {
    handleBackapp,
    setPageLoading,
    validateNumberonly,
    validateDecimalNumberonly,
  } = useContext(DataContext);
  const {
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    values: formData,
  });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  useDidMountEffect(() => {
    getApi();
  }, []);
  const [fileUploadId, setFileUploadId] = useState(0);
  const getApi = async () => {
    setPageLoading(true);
    await apiService(`startup/ldd/sectiontwo/get`, "", "get").then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        var data = res.data;
        if (data.editForm === false) {
          setReadyOnlyValue(true);
        }
        // if (data.gstLogin) {
        //     data.gstLogin = cryptodecryptJS(data.gstLogin)
        // }
        // if (data.gstPassword) {
        //     data.gstPassword = cryptodecryptJS(data.gstPassword)
        // }
        // if (data.incTaxLogin) {
        //     data.incTaxLogin = cryptodecryptJS(data.incTaxLogin)
        // }
        // if (data.incTaxPassword) {
        //     data.incTaxPassword = cryptodecryptJS(data.incTaxPassword)
        // }
        if (res.data.id) {
          setFileUploadId(res.data.id);
        }
        if (res.data.shareHoldingPatterns) {
          res.data.shareHoldingPatterns.forEach((element) => {
            if (element.date) {
              element.date = dayjs(element.date, "YYYY-MM-DD");
            }
            // if (element.approvedDate) {
            //     element.approvedDate = dayjs(element.date, "YYYY-MM-DD");
            // }
          });
        }
        if (res.data.remunerationPaidToDirectors) {
          res.data.remunerationPaidToDirectors.forEach((element) => {
            if (element.date) {
              element.date = dayjs(element.date, "YYYY-MM-DD");
            }
          });
        }
        if (res.data.detailsOfBorrowings) {
          res.data.detailsOfBorrowings.forEach((element) => {
            if (element.date) {
              element.date = dayjs(element.date, "YYYY-MM-DD");
            }
          });
        }
        setFormData(res.data);
      }
    });
  };
  const handlestatutoryRegistersPcsDoc = (e) => {
    handleFileUpload(
      e,
      "statutoryRegistersPcsDoc",
      "statutoryRegistersPcsDocName",
      "statutoryRegistersDocPcsURL",
      "file"
    );
  };
  const handleRocPcsDoc = (e) => {
    handleFileUpload(e, "rocPcsDoc", "rocDocPcsName", "rocDocPcsURL", "file");
  };
  const handleShareholderMinutesPcsDoc = (e) => {
    handleFileUpload(
      e,
      "shareholderMinutesPcsDoc",
      "shareholderMinutesDocPcsName",
      "shareholderMinutesDocPcsURL",
      "file"
    );
  };
  const handleStatutoryRegistersDoc = (e) => {
    handleFileUpload(
      e,
      "statutoryRegistersDoc",
      "statutoryRegistersDocName",
      "statutoryRegistersDocURL",
      "file"
    );
  };
  const handleRocDoc = (e) => {
    handleFileUpload(e, "rocDoc", "rocDocName", "rocDocURL", "file");
  };
  const handleShareholderMinutesDoc = (e) => {
    handleFileUpload(
      e,
      "shareholderMinutesDoc",
      "shareholderMinutesDocName",
      "shareholderMinutesDocURL",
      "file"
    );
  };
  const handleFileUpload = (event, filekey, fileName, fileURL, format) => {
    let logoselecetdFile;
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      if (logoselecetdFile) {
        const fileSizeLimit = 5 * 1024 * 1024;
        if (logoselecetdFile.size > fileSizeLimit) {
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 5 MB."
          );
          return;
        }
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type;
        var imagedatatype = imagetype.split("/");
        var img_crt_type = imagedatatype[1];
        if (
          (format === "file" && img_crt_type === "pdf") ||
          img_crt_type === "vnd.ms-excel" ||
          img_crt_type ===
            "vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          img_crt_type ===
            "vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          setValue(filekey, "");
          setValue(fileName, "");

          var fileValue = logoselecetdFile;
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(",");
            var ImageValue = spl[1];
            var img_name = fileValue.name;
            setValue(filekey, ImageValue);
            setValue(fileName, img_name);
            setValue(fileURL, logourl1);
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileURL);
            var req = {
              [filekey]: ImageValue,
              [fileName]: img_name,
              id: fileUploadId,
            };
            // console.log(req);
            saveData(req);
          };
        } else {
          notifyService(
            "danger",
            "File Format Invalided",
            "Please check your file format"
          );
        }
      }
    }
  };
  const saveData = (req) => {
    console.log(req);
    setPageLoading(true);
    apiService("startup/ldd/sectiontwo/save", req, "post").then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          getApi();
        }
      }
    });
  };

  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    if (value) {
      setViewImage(value);
      setOpen(true);
    }
  };
  const handleClickOpens = (url) => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onPdfClick = () => {
    const pdfUrl =
      "https://startuptn-public-files.s3.ap-south-1.amazonaws.com/Business_Plan_Template.xlsx";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Business_Plan_Template.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // const onSubmit = (data) => {
  //     if (data.borrowings) {
  //         data.borrowings.forEach(element => {
  //             if (element.date) {
  //                 element.date = element.date.format('YYYY-MM-DD');
  //             }
  //             if (element.approvedDate) {
  //                 element.approvedDate = element.approvedDate.format('YYYY-MM-DD');
  //             }
  //         });
  //     }
  //     if (data.insurancePolicies) {
  //         data.insurancePolicies.forEach(element => {
  //             if (element.date) {
  //                 element.date = element.date.format('YYYY-MM-DD');
  //             }
  //         });
  //     }
  //     var req = {
  //         id: fileUploadId,
  //         borrowings: data.borrowings,
  //         insurancePolicies: data.insurancePolicies,
  //         gstNumber: data.gstNumber,
  //         gstAuthorizedName: data.gstAuthorizedName,
  //         minSalaryEmployees: data.minSalaryEmployees,
  //         maxSalaryEmployees: data.maxSalaryEmployees,
  //     }
  //     console.log(req);
  //     apiService('startup/ldd/sectiontwo/save', req, 'post').then((res) => {
  //         setPageLoading(false);
  //         if (res && res.data) {
  //             if (res.data.responseStatus === "Success") {
  //                 notifyService('success', 'Success', 'Saved successfully !!')
  //                 handleBackapp()
  //             }
  //         }
  //     })
  // }
  const onSubmit = (data) => {
    if (data.shareHoldingPatterns) {
      data.shareHoldingPatterns.forEach((element) => {
        if (element.date) {
          element.date = element.date.format("YYYY-MM-DD");
        }
      });
    }
    if (data.remunerationPaidToDirectors) {
      data.remunerationPaidToDirectors.forEach((element) => {
        if (element.date) {
          element.date = element.date.format("YYYY-MM-DD");
        }
      });
    }
    if (data.detailsOfBorrowings) {
      data.detailsOfBorrowings.forEach((element) => {
        if (element.date) {
          element.date = element.date.format("YYYY-MM-DD");
        }
      });
    }
    setPageLoading(true);
    apiService("startup/ldd/sectiontwo/save", data, "post").then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          notifyService("success", "Success", "Saved successfully !!");
          handleBackapp();
        }
      }
    });
  };
  const onError = (error) => {
    console.log(error);
    notifyService("danger", "Error", "Please check mandatory fields");
  };
  const [isAccordionOpen1, setIsAccordionOpen1] = useState(true);
  const [isAccordionOpen2, setIsAccordionOpen2] = useState(true);
  const [isAccordionOpen3, setIsAccordionOpen3] = useState(true);
  const [isAccordionOpen4, setIsAccordionOpen4] = useState(true);
  const [isAccordionOpen5, setIsAccordionOpen5] = useState(true);
  const [isAccordionOpen6, setIsAccordionOpen6] = useState(true);
  const {
    fields: shareHoldingPatternsFields,
    append: shareHoldingPatternsAppend,
    remove: shareHoldingPatternsRemove,
  } = useFieldArray({
    control,
    name: "shareHoldingPatterns",
  });
  const adddirectorsFieldsBtn = () => {
    shareHoldingPatternsAppend({
      id: 0,
      name: "",
      date: "",
      shares: "",
      certfNo: "",
      dulyStamped: "",
    });
  };
  const deleteDirectorsInterested = async (index) => {
    const id = getValues(`shareHoldingPatterns.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/ldd/startupShareHoldPattern/remove?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeleteDirectorsInterested(index);
          }
        }
      });
    } else {
      indexBaseddeleteDirectorsInterested(index);
    }
  };
  const indexBaseddeleteDirectorsInterested = (index) => {
    var startupTeams = getValues("shareHoldingPatterns");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.shareHoldingPatterns = [
        {
          id: 0,
          name: "",
          date: "",
          shares: "",
          certfNo: "",
          dulyStamped: "",
        },
      ];
      setFormData(val);
    } else {
      shareHoldingPatternsRemove(index);
      var val = getValues();
      setFormData(val);
    }
  };
  const {
    fields: directorsInterestedCompaniesFields,
    append: directorsInterestedCompaniesAppend,
    remove: directorsInterestedCompaniesRemove,
  } = useFieldArray({
    control,
    name: "directorsInterestedCompanies",
  });
  const addRemunerationPaidToDirectorsBtn = () => {
    directorsInterestedCompaniesAppend({
      id: 0,
      name: "",
      address: "",
      din: "",
      interested: "",
    });
  };
  const deleteDirectorsInterestedCompanies = async (index) => {
    const id = getValues(`directorsInterestedCompanies.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/ldd/startupDirectorsIntCompanies/remove?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeletedirectorsInterestedCompanies(index);
          }
        }
      });
    } else {
      indexBaseddeletedirectorsInterestedCompanies(index);
    }
  };
  const indexBaseddeletedirectorsInterestedCompanies = (index) => {
    var startupTeams = getValues("directorsInterestedCompanies");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.directorsInterestedCompanies = [
        {
          id: 0,
          name: "",
          address: "",
          din: "",
          interested: "",
        },
      ];
      setFormData(val);
    } else {
      directorsInterestedCompaniesRemove(index);
      var val = getValues();
      setFormData(val);
    }
  };
  const {
    fields: remunerationPaidToDirectorsFields,
    append: remunerationPaidToDirectorsAppend,
    remove: remunerationPaidToDirectorsRemove,
  } = useFieldArray({
    control,
    name: "remunerationPaidToDirectors",
  });

  const adddirectorsInterestedCompaniesAppendBtn = () => {
    remunerationPaidToDirectorsAppend({
      id: 0,
      name: "",
      fy: "",
      date: "",
      remuneration: "",
    });
  };

  const deleteRemunerationPaidToDirectors = async (index) => {
    const id = getValues(`remunerationPaidToDirectors.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/ldd/remunerationPaidToDirectors/remove?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeletedremunerationPaidToDirectors(index);
          }
        }
      });
    } else {
      indexBaseddeletedremunerationPaidToDirectors(index);
    }
  };

  const indexBaseddeletedremunerationPaidToDirectors = (index) => {
    var startupTeams = getValues("remunerationPaidToDirectors");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.remunerationPaidToDirectors = [
        {
          id: 0,
          name: "",
          fy: "",
          date: "",
          remuneration: "",
        },
      ];
      setFormData(val);
    } else {
      remunerationPaidToDirectorsRemove(index);
      var val = getValues();
      setFormData(val);
    }
  };
  const {
    fields: relatedPartyTransactionsFields,
    append: relatedPartyTransactionsAppend,
    remove: relatedPartyTransactionsRemove,
  } = useFieldArray({
    control,
    name: "relatedPartyTransactions",
  });
  const addrelatedPartyTransactionsBtn = () => {
    relatedPartyTransactionsAppend({
      id: 0,
      name: "",
      nature: "",
      amount: "",
      fy: "",
    });
  };
  const deleteRelatedPartyTransactions = async (index) => {
    const id = getValues(`relatedPartyTransactions.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/ldd/relatedPartyTransactions/remove?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeleteRelatedPartyTransactions(index);
          }
        }
      });
    } else {
      indexBaseddeleteRelatedPartyTransactions(index);
    }
  };
  const indexBaseddeleteRelatedPartyTransactions = (index) => {
    var startupTeams = getValues("relatedPartyTransactions");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.relatedPartyTransactions = [
        {
          id: 0,
          name: "",
          nature: "",
          amount: "",
          fy: "",
        },
      ];
      setFormData(val);
    } else {
      relatedPartyTransactionsRemove(index);
      var val = getValues();
      setFormData(val);
    }
  };
  const {
    fields: detailsOfBorrowingsFields,
    append: detailsOfBorrowingsAppend,
    remove: detailsOfBorrowingsRemove,
  } = useFieldArray({
    control,
    name: "detailsOfBorrowings",
  });
  const addDetailsOfBorrowingsBtn = () => {
    detailsOfBorrowingsAppend({
      id: 0,
      name: "",
      date: "",
      amount: "",
      secured: "",
      termsOrInterest: "",
      dpt3Filed: "",
    });
  };
  const deleteDetailsOfBorrowings = async (index) => {
    const id = getValues(`detailsOfBorrowings.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/ldd/detailsOfBorrowings/remove?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeleteDetailsOfBorrowings(index);
          }
        }
      });
    } else {
      indexBaseddeleteDetailsOfBorrowings(index);
    }
  };
  const indexBaseddeleteDetailsOfBorrowings = (index) => {
    var startupTeams = getValues("detailsOfBorrowings");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.detailsOfBorrowings = [
        {
          id: 0,
          name: "",
          date: "",
          amount: "",
          secured: "",
          termsOrInterest: "",
          dpt3Filed: "",
        },
      ];
      setFormData(val);
    } else {
      detailsOfBorrowingsRemove(index);
      var val = getValues();
      setFormData(val);
    }
  };
  const {
    fields: companyInvestmentsOrLoanFields,
    append: companyInvestmentsOrLoanAppend,
    remove: companyInvestmentsOrLoanRemove,
  } = useFieldArray({
    control,
    name: "companyInvestmentsOrLoan",
  });
  const addCompanyInvestmentsOrLoanBtn = () => {
    companyInvestmentsOrLoanAppend({
      id: 0,
      name: "",
      amount: "",
      nature: "",
      fy: "",
    });
  };
  const deleteCompanyInvestmentsOrLoan = async (index) => {
    const id = getValues(`companyInvestmentsOrLoan.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/ldd/companyInvestmentsOrLoan/remove?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBaseddeleteCompanyInvestmentsOrLoan(index);
          }
        }
      });
    } else {
      indexBaseddeleteCompanyInvestmentsOrLoan(index);
    }
  };
  const indexBaseddeleteCompanyInvestmentsOrLoan = (index) => {
    var startupTeams = getValues("companyInvestmentsOrLoan");
    if (startupTeams.length === 0 && index === 0) {
      var val = getValues();
      val.companyInvestmentsOrLoan = [
        {
          id: 0,
          name: "",
          amount: "",
          nature: "",
          fy: "",
        },
      ];
      setFormData(val);
    } else {
      companyInvestmentsOrLoanRemove(index);
      var val = getValues();
      setFormData(val);
    }
  };
  const handleDateFormat = (newValue, index) => {
    setValue(`shareHoldingPatterns.${index}.date`, newValue);
    clearErrors(`shareHoldingPatterns.${index}.date`);
  };
  const handleDateFormat2 = (newValue, index) => {
    setValue(`remunerationPaidToDirectors.${index}.date`, newValue);
    clearErrors(`remunerationPaidToDirectors.${index}.date`);
  };
  const handleDateFormat3 = (newValue, index) => {
    setValue(`detailsOfBorrowings.${index}.date`, newValue);
    clearErrors(`detailsOfBorrowings.${index}.date`);
  };
  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackapp}>
              <img src="/images/Fig-back.png" className="img-fluid" alt="" />
            </span>
            <h6 className="ml-2 mb-0">
              Certificate from a Practising Company Secretary{" "}
            </h6>
          </div>
          <hr />
          <form
            className="signin-form"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <div className="row doc-row">
              <div className="col-lg-12  col-12 mt-2">
                <FormLabel>
                  Please provide a certificate from a Practising Company
                  Secretary that the Company:
                </FormLabel>
              </div>
              <div className="col-lg-4  col-12 mt-2">
                <FormLabel>
                  Maintained all statutory registers required under the
                  Companies Act{" "}
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="statutoryRegistersPcsDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handlestatutoryRegistersPcsDoc}
                          value={getValues("statutoryRegistersPcsDoc")}
                          name="file"
                          types={fileTypesAll}
                          children={
                            <div
                              className={
                                getValues("statutoryRegistersPcsDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("statutoryRegistersPcsDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("statutoryRegistersPcsDoc") !==
                                    ""
                                      ? "Uploaded (Click to Change)"
                                      : "Companies Act Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                     Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.statutoryRegistersPcsDoc &&
                        errors.statutoryRegistersPcsDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit">
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      onClick={() =>
                        handleClickOpens(
                          getValues("statutoryRegistersDocPcsURL")
                        )
                      }
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Download
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-lg-4  col-12 mt-2">
                <FormLabel>
                  Filed all necessary forms with the ROC as may be required,
                  along with the list of forms filed and their dates.{" "}
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="rocPcsDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleRocPcsDoc}
                          value={getValues("rocPcsDoc")}
                          name="file"
                          types={fileTypesAll}
                          children={
                            <div
                              className={
                                getValues("rocPcsDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("rocPcsDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("rocPcsDoc") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "ROC Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                     Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.rocPcsDoc && errors.rocPcsDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit">
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      onClick={() =>
                        handleClickOpens(getValues("rocDocPcsURL"))
                      }
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Download
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-lg-4  col-12 mt-2">
                <FormLabel>
                  Maintained Board & Shareholders meeting minutes book as per
                  Companies Act maintained by the Company
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="shareholderMinutesPcsDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleShareholderMinutesPcsDoc}
                          value={getValues("shareholderMinutesPcsDoc")}
                          name="file"
                          types={fileTypesAll}
                          children={
                            <div
                              className={
                                getValues("shareholderMinutesPcsDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("shareholderMinutesPcsDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("shareholderMinutesPcsDoc") !==
                                    ""
                                      ? "Uploaded (Click to Change)"
                                      : "Maintained Board Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                     Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.shareholderMinutesPcsDoc &&
                        errors.shareholderMinutesPcsDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit">
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      onClick={() =>
                        handleClickOpens(
                          getValues("shareholderMinutesDocPcsURL")
                        )
                      }
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Download
                    </Link>
                  </span>
                </div>
              </div>

              <div className="col-lg-12  col-12 mt-2">
                <FormLabel>
                  Please provide us with a copy of the above for our review
                  along with the PCS certificate:
                </FormLabel>
              </div>
              <div className="col-lg-4  col-12 mt-2">
                <FormLabel>
                  Maintained all statutory registers required under the
                  Companies Act{" "}
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="statutoryRegistersDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleStatutoryRegistersDoc}
                          value={getValues("statutoryRegistersDoc")}
                          name="file"
                          types={fileTypesAll}
                          children={
                            <div
                              className={
                                getValues("statutoryRegistersDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("statutoryRegistersDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("statutoryRegistersDoc") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "Companies Act Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                     Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.statutoryRegistersDoc &&
                        errors.statutoryRegistersDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit">
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      onClick={() =>
                        handleClickOpens(getValues("statutoryRegistersDocURL"))
                      }
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Download
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-lg-4  col-12 mt-2">
                <FormLabel>
                  Filed all necessary forms with the ROC as may be required,
                  along with the list of forms filed and their dates.{" "}
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="rocDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleRocDoc}
                          value={getValues("rocDoc")}
                          name="file"
                          types={fileTypesAll}
                          children={
                            <div
                              className={
                                getValues("rocDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("rocDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("rocDoc") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "ROC Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                     Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.rocDoc && errors.rocDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit">
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      onClick={() => handleClickOpens(getValues("rocDocURL"))}
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Download
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-lg-4  col-12 mt-2">
                <FormLabel>
                  Maintained Board & Shareholders meeting minutes book as per
                  Companies Act maintained by the Company
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="shareholderMinutesDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleShareholderMinutesDoc}
                          value={getValues("shareholderMinutesDoc")}
                          name="file"
                          types={fileTypesAll}
                          children={
                            <div
                              className={
                                getValues("shareholderMinutesDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("shareholderMinutesDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("shareholderMinutesDoc") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "Maintained Board Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                     Maximum size: 5 MB. File format .pdf,.xlsx,.doc
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.shareholderMinutesDoc &&
                        errors.shareholderMinutesDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit">
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      onClick={() =>
                        handleClickOpens(getValues("shareholderMinutesDocURL"))
                      }
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Download
                    </Link>
                  </span>
                </div>
              </div>

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen1}
                  onChange={() => setIsAccordionOpen1(!isAccordionOpen1)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Please provide us with a certificate from a Practising
                      Company Secretary setting out the shareholding pattern of
                      the Company as on date along with copies of all share
                      certificates for the present shareholders
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {shareHoldingPatternsFields.length >= 0 && (
                            <TableContainer
                              component={Paper}
                              className="table-back"
                            >
                              <Table aria-label="caption table">
                                <TableBody>
                                  {shareHoldingPatternsFields.map(
                                    (field, index) => (
                                      <TableRow key={index}>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          {index + 1}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`shareHoldingPatterns.${index}.name`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Name of Shareholder is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Name of Shareholder *"
                                                  placeholder="Enter Name of Shareholder"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.shareHoldingPatterns?.[
                                                index
                                              ]?.name &&
                                                errors.shareHoldingPatterns?.[
                                                  index
                                                ]?.name.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`shareHoldingPatterns.${index}.date`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Date of allotment / Transfer is required",
                                              }}
                                              render={({ field }) => (
                                                <LocalizationProvider
                                                  dateAdapter={AdapterDayjs}
                                                >
                                                  <DemoContainer
                                                    components={["DatePicker"]}
                                                    fullWidth
                                                  >
                                                    <DatePicker
                                                      label="Date of allotment / Transfer"
                                                      format="DD/MM/YYYY"
                                                      value={field.value || null}
                                                      sx={{ width: "100%" }}
                                                      inputFormat="DD/MM/YYYY"
                                                      onChange={(newValue) =>
                                                        handleDateFormat(
                                                          newValue,
                                                          index
                                                        )
                                                      }
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                        />
                                                      )}
                                                      disabled={readyOnlyValue}
                                                    />
                                                  </DemoContainer>
                                                </LocalizationProvider>
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.shareHoldingPatterns?.[
                                                index
                                              ]?.date &&
                                                errors.shareHoldingPatterns?.[
                                                  index
                                                ]?.date.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`shareHoldingPatterns.${index}.shares`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Number of shares is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  variant="outlined"
                                                  label="Number of shares *"
                                                  placeholder="Enter Number of shares"
                                                  fullWidth
                                                  multiline
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                    maxLength: 3,
                                                  }}
                                                  {...field}
                                                  onKeyDown={(e) =>
                                                    validateNumberonly(e)
                                                  }
                                                  // value={valuePincode}
                                                  // onInput={(e) => {
                                                  //     const { value } = e.target;
                                                  //     if (value === "" || (/^\d{1,6}$/.test(value))) {
                                                  //         setValuePincode(value);
                                                  //     }
                                                  // }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.shareHoldingPatterns?.[
                                                index
                                              ]?.shares &&
                                                errors.shareHoldingPatterns?.[
                                                  index
                                                ]?.shares.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`shareHoldingPatterns.${index}.certfNo`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Name of Shareholder is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Share Certificate Number *"
                                                  placeholder="Enter Share Certificate Number"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.shareHoldingPatterns?.[
                                                index
                                              ]?.certfNo &&
                                                errors.shareHoldingPatterns?.[
                                                  index
                                                ]?.certfNo.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <FormLabel>
                                              Whether the share certificate is
                                              duly stamped/ Form SH4 (for
                                              transfer) is duly stamped
                                            </FormLabel>
                                            <Controller
                                              name={`shareHoldingPatterns.${index}.dulyStamped`}
                                              control={control}
                                              defaultValue=""
                                              rules={{ required: false }}
                                              render={({ field }) => (
                                                <RadioGroup
                                                  row
                                                  name="position"
                                                  {...field}
                                                >
                                                  <FormControlLabel
                                                    disabled={readyOnlyValue}
                                                    defaultValue="null"
                                                    value="true"
                                                    control={<Radio />}
                                                    label="Yes"
                                                  />
                                                  <FormControlLabel
                                                    disabled={readyOnlyValue}
                                                    defaultValue="null"
                                                    value="false"
                                                    control={<Radio />}
                                                    label="No"
                                                  />
                                                </RadioGroup>
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.shareHoldingPatterns?.[
                                                index
                                              ]?.dulyStamped &&
                                                errors.shareHoldingPatterns?.[
                                                  index
                                                ]?.dulyStamped.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                          align="center"
                                        >
                                          <DeleteIcon
                                            sx={{
                                              backgroudColor: "#F4F4F4",
                                              fontSize: "24px",
                                              fill: "#808080",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              readyOnlyValue === false ? (
                                                deleteDirectorsInterested(index)
                                              ) : (
                                                <></>
                                              );
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() => adddirectorsFieldsBtn("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </TableContainer>
                            //     ) : (
                            // <div className='nodata_tag'>
                            //     <p>Fundraising history details is empty</p>
                            // </div>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen2}
                  onChange={() => setIsAccordionOpen2(!isAccordionOpen2)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Please provide us with a certificate from a Practising
                      Company Secretary setting out the details of Directors (as
                      on date) as per the format below
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {directorsInterestedCompaniesFields.length >= 0 && (
                            <TableContainer
                              component={Paper}
                              className="table-back"
                            >
                              <Table aria-label="caption table">
                                <TableBody>
                                  {directorsInterestedCompaniesFields.map(
                                    (field, index) => (
                                      <TableRow key={index}>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          {index + 1}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`directorsInterestedCompanies.${index}.name`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required: "Name is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Name *"
                                                  placeholder="Enter Name"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .directorsInterestedCompanies?.[
                                                index
                                              ]?.name &&
                                                errors
                                                  .directorsInterestedCompanies?.[
                                                  index
                                                ]?.name.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`directorsInterestedCompanies.${index}.address`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required: "Address is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Address *"
                                                  placeholder="Enter Address"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .directorsInterestedCompanies?.[
                                                index
                                              ]?.address &&
                                                errors
                                                  .directorsInterestedCompanies?.[
                                                  index
                                                ]?.address.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`directorsInterestedCompanies.${index}.din`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "DIN Number is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  variant="outlined"
                                                  label="DIN Number *"
                                                  placeholder="Enter DIN Number"
                                                  fullWidth
                                                  multiline
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                  {...field}
                                                  onKeyDown={(e) =>
                                                    validateNumberonly(e)
                                                  }
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .directorsInterestedCompanies?.[
                                                index
                                              ]?.din &&
                                                errors
                                                  .directorsInterestedCompanies?.[
                                                  index
                                                ]?.din.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <FormLabel>
                                              Interest in other entities (as per
                                              Form MBP-1)
                                            </FormLabel>
                                            <Controller
                                              name={`directorsInterestedCompanies.${index}.interested`}
                                              control={control}
                                              defaultValue=""
                                              rules={{ required: false }}
                                              render={({ field }) => (
                                                <RadioGroup
                                                  row
                                                  name="position"
                                                  {...field}
                                                >
                                                  <FormControlLabel
                                                    disabled={readyOnlyValue}
                                                    defaultValue="null"
                                                    value="true"
                                                    control={<Radio />}
                                                    label="Yes"
                                                  />
                                                  <FormControlLabel
                                                    disabled={readyOnlyValue}
                                                    defaultValue="null"
                                                    value="false"
                                                    control={<Radio />}
                                                    label="No"
                                                  />
                                                </RadioGroup>
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .directorsInterestedCompanies?.[
                                                index
                                              ]?.interested &&
                                                errors
                                                  .directorsInterestedCompanies?.[
                                                  index
                                                ]?.interested.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                          align="center"
                                        >
                                          <DeleteIcon
                                            sx={{
                                              backgroudColor: "#F4F4F4",
                                              fontSize: "24px",
                                              fill: "#808080",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              readyOnlyValue === false ? (
                                                deleteDirectorsInterestedCompanies(
                                                  index
                                                )
                                              ) : (
                                                <></>
                                              );
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() =>
                                    addRemunerationPaidToDirectorsBtn("")
                                  }
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </TableContainer>
                            //     ) : (
                            // <div className='nodata_tag'>
                            //     <p>Fundraising history details is empty</p>
                            // </div>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen3}
                  onChange={() => setIsAccordionOpen3(!isAccordionOpen3)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Please provide us with a certificate from a Practising
                      Company Secretary setting out the details of remuneration
                      paid to Directors (for the last 3 financial years)
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {remunerationPaidToDirectorsFields.length >= 0 && (
                            <TableContainer
                              component={Paper}
                              className="table-back"
                            >
                              <Table aria-label="caption table">
                                <TableBody>
                                  {remunerationPaidToDirectorsFields.map(
                                    (field, index) => (
                                      <TableRow key={index}>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          {index + 1}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`remunerationPaidToDirectors.${index}.name`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Name of Director is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Name of Director *"
                                                  placeholder="Enter Name of Director"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .remunerationPaidToDirectors?.[
                                                index
                                              ]?.name &&
                                                errors
                                                  .remunerationPaidToDirectors?.[
                                                  index
                                                ]?.name.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`remunerationPaidToDirectors.${index}.fy`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required: "FY is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Financial Year *"
                                                  placeholder="Enter Financial Year"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .remunerationPaidToDirectors?.[
                                                index
                                              ]?.fy &&
                                                errors
                                                  .remunerationPaidToDirectors?.[
                                                  index
                                                ]?.fy.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`remunerationPaidToDirectors.${index}.remuneration`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Remuneration is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  variant="outlined"
                                                  label="Remuneration *"
                                                  placeholder="Enter Remuneration"
                                                  fullWidth
                                                  multiline
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                  {...field}
                                                  onKeyDown={(e) =>
                                                    validateNumberonly(e)
                                                  }
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .remunerationPaidToDirectors?.[
                                                index
                                              ]?.remuneration &&
                                                errors
                                                  .remunerationPaidToDirectors?.[
                                                  index
                                                ]?.remuneration.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`remunerationPaidToDirectors.${index}.date`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Date of allotment / Transfer is required",
                                              }}
                                              render={({ field }) => (
                                                <LocalizationProvider
                                                  dateAdapter={AdapterDayjs}
                                                >
                                                  <DemoContainer
                                                    components={["DatePicker"]}
                                                    fullWidth
                                                  >
                                                    <DatePicker
                                                      label="Date of Boar resolution approving the same"
                                                      format="DD/MM/YYYY"
                                                      value={field.value || null}
                                                      sx={{ width: "100%" }}
                                                      inputFormat="DD/MM/YYYY"
                                                      onChange={(newValue) =>
                                                        handleDateFormat2(
                                                          newValue,
                                                          index
                                                        )
                                                      }
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                        />
                                                      )}
                                                      disabled={readyOnlyValue}
                                                    />
                                                  </DemoContainer>
                                                </LocalizationProvider>
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .remunerationPaidToDirectors?.[
                                                index
                                              ]?.date &&
                                                errors
                                                  .remunerationPaidToDirectors?.[
                                                  index
                                                ]?.date.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                          align="center"
                                        >
                                          <DeleteIcon
                                            sx={{
                                              backgroudColor: "#F4F4F4",
                                              fontSize: "24px",
                                              fill: "#808080",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              readyOnlyValue === false ? (
                                                deleteRemunerationPaidToDirectors(
                                                  index
                                                )
                                              ) : (
                                                <></>
                                              );
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() =>
                                    adddirectorsInterestedCompaniesAppendBtn("")
                                  }
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </TableContainer>
                            //     ) : (
                            // <div className='nodata_tag'>
                            //     <p>Fundraising history details is empty</p>
                            // </div>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen4}
                  onChange={() => setIsAccordionOpen4(!isAccordionOpen4)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Please provide us with a certificate from a Practising
                      Company Secretary setting out Details of all related party
                      transactions as per the following format for the last 3
                      financial years
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {relatedPartyTransactionsFields.length >= 0 && (
                            <TableContainer
                              component={Paper}
                              className="table-back"
                            >
                              <Table aria-label="caption table">
                                <TableBody>
                                  {relatedPartyTransactionsFields.map(
                                    (field, index) => (
                                      <TableRow key={index}>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          {index + 1}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`relatedPartyTransactions.${index}.name`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Name of related party is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Name of related party *"
                                                  placeholder="Enter Name of related party"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .relatedPartyTransactions?.[
                                                index
                                              ]?.name &&
                                                errors
                                                  .relatedPartyTransactions?.[
                                                  index
                                                ]?.name.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`relatedPartyTransactions.${index}.nature`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Nature of transaction is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Nature of transaction *"
                                                  placeholder="Enter Nature of transaction"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .relatedPartyTransactions?.[
                                                index
                                              ]?.nature &&
                                                errors
                                                  .relatedPartyTransactions?.[
                                                  index
                                                ]?.nature.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`relatedPartyTransactions.${index}.amount`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Remuneration is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  variant="outlined"
                                                  label="Remuneration *"
                                                  placeholder="Enter Remuneration"
                                                  fullWidth
                                                  multiline
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                  {...field}
                                                  onKeyDown={(e) =>
                                                    validateDecimalNumberonly(e)
                                                  }
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .relatedPartyTransactions?.[
                                                index
                                              ]?.amount &&
                                                errors
                                                  .relatedPartyTransactions?.[
                                                  index
                                                ]?.amount.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`relatedPartyTransactions.${index}.fy`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Financial Year is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Financial Year *"
                                                  placeholder="Enter Financial Year"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .relatedPartyTransactions?.[
                                                index
                                              ]?.fy &&
                                                errors
                                                  .relatedPartyTransactions?.[
                                                  index
                                                ]?.fy.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                          align="center"
                                        >
                                          <DeleteIcon
                                            sx={{
                                              backgroudColor: "#F4F4F4",
                                              fontSize: "24px",
                                              fill: "#808080",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              readyOnlyValue === false ? (
                                                deleteRelatedPartyTransactions(
                                                  index
                                                )
                                              ) : (
                                                <></>
                                              );
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() =>
                                    addrelatedPartyTransactionsBtn("")
                                  }
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </TableContainer>
                            //     ) : (
                            // <div className='nodata_tag'>
                            //     <p>Fundraising history details is empty</p>
                            // </div>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen5}
                  onChange={() => setIsAccordionOpen5(!isAccordionOpen5)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Please provide us with a certificate from a Practising
                      Company Secretary setting out the details of all
                      borrowings (both secured & unsecured) made by the Company
                      as per the following format along with copies of the loan
                      agreement, forms filed, if any.
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {detailsOfBorrowingsFields.length >= 0 && (
                            <TableContainer
                              component={Paper}
                              className="table-back"
                            >
                              <Table aria-label="caption table">
                                <TableBody>
                                  {detailsOfBorrowingsFields.map(
                                    (field, index) => (
                                      <TableRow key={index}>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          {index + 1}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "15%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`detailsOfBorrowings.${index}.date`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Date of borrowing is required",
                                              }}
                                              render={({ field }) => (
                                                <LocalizationProvider
                                                  dateAdapter={AdapterDayjs}
                                                >
                                                  <DemoContainer
                                                    components={["DatePicker"]}
                                                    fullWidth
                                                  >
                                                    <DatePicker
                                                      label="Date of borrowing"
                                                      format="DD/MM/YYYY"
                                                      value={field.value || null}
                                                      sx={{ width: "100%" }}
                                                      inputFormat="DD/MM/YYYY"
                                                      onChange={(newValue) =>
                                                        handleDateFormat3(
                                                          newValue,
                                                          index
                                                        )
                                                      }
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                        />
                                                      )}
                                                      disabled={readyOnlyValue}
                                                    />
                                                  </DemoContainer>
                                                </LocalizationProvider>
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.detailsOfBorrowings?.[
                                                index
                                              ]?.date &&
                                                errors.detailsOfBorrowings?.[
                                                  index
                                                ]?.date.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "15%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`detailsOfBorrowings.${index}.name`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Name of Lender is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Name of Lender *"
                                                  placeholder="Enter Name of Lender"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.detailsOfBorrowings?.[
                                                index
                                              ]?.name &&
                                                errors.detailsOfBorrowings?.[
                                                  index
                                                ]?.name.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "15%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`detailsOfBorrowings.${index}.amount`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required: "Amount is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  variant="outlined"
                                                  label="Amount *"
                                                  placeholder="Enter Amount"
                                                  fullWidth
                                                  multiline
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                  {...field}
                                                  onKeyDown={(e) =>
                                                    validateDecimalNumberonly(e)
                                                  }
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.detailsOfBorrowings?.[
                                                index
                                              ]?.amount &&
                                                errors.detailsOfBorrowings?.[
                                                  index
                                                ]?.amount.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "15%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`detailsOfBorrowings.${index}.secured`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Secured / unsecured is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Secured / unsecured If secured whether charge filed with RoC *"
                                                  placeholder="Enter Secured / unsecured"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.detailsOfBorrowings?.[
                                                index
                                              ]?.secured &&
                                                errors.detailsOfBorrowings?.[
                                                  index
                                                ]?.secured.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "15%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`detailsOfBorrowings.${index}.termsOrInterest`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Repayment terms, interest is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Repayment terms, interest *"
                                                  placeholder="Enter Repayment terms, interest"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.detailsOfBorrowings?.[
                                                index
                                              ]?.termsOrInterest &&
                                                errors.detailsOfBorrowings?.[
                                                  index
                                                ]?.termsOrInterest.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "15%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`detailsOfBorrowings.${index}.dpt3Filed`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Whether DPT-3 filed with RoC & date of filing is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Whether DPT-3 filed with RoC & date of filing *"
                                                  placeholder="Enter Whether DPT-3 filed with RoC & date of filing"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.detailsOfBorrowings?.[
                                                index
                                              ]?.dpt3Filed &&
                                                errors.detailsOfBorrowings?.[
                                                  index
                                                ]?.dpt3Filed.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                          align="center"
                                        >
                                          <DeleteIcon
                                            sx={{
                                              backgroudColor: "#F4F4F4",
                                              fontSize: "24px",
                                              fill: "#808080",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              readyOnlyValue === false ? (
                                                deleteDetailsOfBorrowings(index)
                                              ) : (
                                                <></>
                                              );
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() => addDetailsOfBorrowingsBtn("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </TableContainer>
                            //     ) : (
                            // <div className='nodata_tag'>
                            //     <p>Fundraising history details is empty</p>
                            // </div>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <Accordion
                  className="Addfundacc-bor"
                  expanded={isAccordionOpen6}
                  onChange={() => setIsAccordionOpen6(!isAccordionOpen6)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="Addfundacc"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 0,
                      }}
                    >
                      <AddCircleIcon className="add-icon mr-3" />
                      Please provide us with a certificate from a Practising
                      Company Secretary setting out Details of investments made
                      by the Company / loans granted by the Company
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="col-12 mb-3">
                        <div>
                          {companyInvestmentsOrLoanFields.length >= 0 && (
                            <TableContainer
                              component={Paper}
                              className="table-back"
                            >
                              <Table aria-label="caption table">
                                <TableBody>
                                  {companyInvestmentsOrLoanFields.map(
                                    (field, index) => (
                                      <TableRow key={index}>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          {index + 1}
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`companyInvestmentsOrLoan.${index}.name`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Party Name is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Party Name *"
                                                  placeholder="Enter Party Name"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .companyInvestmentsOrLoan?.[
                                                index
                                              ]?.name &&
                                                errors
                                                  .companyInvestmentsOrLoan?.[
                                                  index
                                                ]?.name.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`companyInvestmentsOrLoan.${index}.amount`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required: "Amount is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  variant="outlined"
                                                  label="Amount *"
                                                  placeholder="Enter Amount"
                                                  fullWidth
                                                  multiline
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                  {...field}
                                                  onKeyDown={(e) =>
                                                    validateDecimalNumberonly(e)
                                                  }
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .companyInvestmentsOrLoan?.[
                                                index
                                              ]?.amount &&
                                                errors
                                                  .companyInvestmentsOrLoan?.[
                                                  index
                                                ]?.amount.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`companyInvestmentsOrLoan.${index}.nature`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Nature (whether loan / investment) is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Nature (whether loan / investment) *"
                                                  placeholder="Enter Nature (whether loan / investment)"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .companyInvestmentsOrLoan?.[
                                                index
                                              ]?.nature &&
                                                errors
                                                  .companyInvestmentsOrLoan?.[
                                                  index
                                                ]?.nature.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`companyInvestmentsOrLoan.${index}.fy`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Financial Year is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Financial Year *"
                                                  placeholder="Enter Financial Year"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors
                                                .companyInvestmentsOrLoan?.[
                                                index
                                              ]?.fy &&
                                                errors
                                                  .companyInvestmentsOrLoan?.[
                                                  index
                                                ]?.fy.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                          align="center"
                                        >
                                          <DeleteIcon
                                            sx={{
                                              backgroudColor: "#F4F4F4",
                                              fontSize: "24px",
                                              fill: "#808080",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              readyOnlyValue === false ? (
                                                deleteCompanyInvestmentsOrLoan(
                                                  index
                                                )
                                              ) : (
                                                <></>
                                              );
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyValue}
                                  onClick={() =>
                                    addCompanyInvestmentsOrLoanBtn("")
                                  }
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </TableContainer>
                            //     ) : (
                            // <div className='nodata_tag'>
                            //     <p>Fundraising history details is empty</p>
                            // </div>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            {/* <PreliminaryTextPart2 {...{ control, getValues, errors, setValue, clearErrors }} setFormData={setFormData} formData={formData} readyOnlyValue={readyOnlyValue} /> */}
            <div className="form-group d-flex justify-content-end mt-3 mr-2">
              <Button
                variant="contained"
                onClick={handleBackapp}
                className="btn btn-cancel submit px-3"
              >
                Cancel
              </Button>
              {readyOnlyValue === false ? (
                <Button
                  type="submit"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Proceed Next</span>
                </Button>
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>
        <Viewimage
          viewImage={viewImage}
          open={open}
          handleClose={handleClose}
        />
      </section>
    </>
  );
};

export default PreliminaryDocumentPart2;
