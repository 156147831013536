import React, { useContext, useState } from "react";
import DataContext from "../../../context/DataContext";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

import CustomLink from "../../common-page/CustomLink";
import ReportsFilter from "./ReportsFilter";
import dayjs from "dayjs";
const formatDateForApi = (date) => date ? dayjs(date).format('DD-MM-YYYY') : null;
const Reports = () => {
    const [preferredSectors, setPreferredSectors] = useState([]);
    const role = localStorage.getItem('role')
    const { setPageLoading, tableIndexValue } = useContext(DataContext);
    const [count, setCount] = useState(0);
    const [scstHubList, setScstHubList] = useState([]);
    var pageNumberPartners = Number(localStorage.getItem('pageScst'))
    const [page, setPage] = useState(pageNumberPartners ? pageNumberPartners : 0);
    var listSizePartners = Number(localStorage.getItem('rowsPerPageScst'))
    const [rowsPerPage, setRowsPerPage] = useState(listSizePartners ? listSizePartners : 10);

    var districtNameScst = localStorage.getItem('districtNameScst')
    const [districtName, setDistrictName] = useState(districtNameScst ? districtNameScst : '')
    // var hubScst = localStorage.getItem('hubScst')
    const [scsthub, setScsthub] = useState("");
    // const [gender, setGender] = useState("All");
    const d = new Date();
    var yearNameScst = Number(localStorage.getItem('yearNameScst'))
    const [yearName, setYearName] = useState(yearNameScst ? yearNameScst : 'All');

    var monthNameScst = Number(localStorage.getItem('monthNameScst'))
    const [monthName, setMonthName] = useState(monthNameScst ? monthNameScst : 0);

    var manualNameScst = Number(localStorage.getItem('manualNameScst'))
    const [manualName, setManualName] = useState(manualNameScst ? manualNameScst : 0)

    var searchInputValueScst = localStorage.getItem('searchInputValueScst')
    const [searchInputValue, setSearchInputValue] = useState(searchInputValueScst ? searchInputValueScst : '');

    var statusValueScst = Number(localStorage.getItem('statusValueScst'))
    const [statusValue, setStatusValue] = useState(statusValueScst ? statusValueScst : 0)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    useDidMountEffect(() => {
        (async () => await scstReports())();
    }, [page, rowsPerPage, districtName, scsthub, yearName, monthName, manualName, searchInputValue, preferredSectors, startDate, endDate, statusValue]);

    const scstReports = () => {
        setPageLoading(true)
        setScstHubList([]);
        var startedDate = formatDateForApi(startDate)
        var endedDate = formatDateForApi(endDate)
        var req = {
            listSize: rowsPerPage,
            pageNumber: page + 1,
            district: districtName,
            hub: scsthub,
            searchString: searchInputValue,
            // status: statusValue,
            sector: preferredSectors,
            startDate: startedDate ? startedDate : "",
            endDate: endedDate ? endedDate : "",
            // gender:gender
        }
        apiService("admin/hub/scst/report/list", req, "post").then((res) => {
            setPageLoading(false);
            if (res) {
                setCount(res.data.count);
                if (res.data.startups) {
                    setScstHubList(res.data.startups);
                } else {
                    setScstHubList([]);
                }
            }
        });
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        localStorage.setItem('pageScst', newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        localStorage.setItem('rowsPerPageScst', parseInt(event.target.value, 10))
        localStorage.setItem('pageScst', 0)
        setPage(0);
    };




    return (
        <div className="reports-commons">
            <div className="scsthub-div form_div chart">
                <div>
                    <ReportsFilter
                        setPage={setPage}
                        page={page}
                        // setGender={setGender}
                        // gender={gender}
                        setStartDate={setStartDate}
                        rowsPerPage={rowsPerPage}
                        startDate={startDate}
                        setEndDate={setEndDate}
                        endDate={endDate}
                        preferredSectors={preferredSectors}
                        setPreferredSectors={setPreferredSectors}
                        districtName={districtName} setDistrictName={setDistrictName}
                        scsthub={scsthub} setScsthub={setScsthub}
                        yearName={yearName} setYearName={setYearName}
                        monthName={monthName} setMonthName={setMonthName}
                        manualName={manualName} setManualName={setManualName}
                        path={role}
                        searchInputValue={searchInputValue} setSearchInputValue={setSearchInputValue}
                        statusValue={statusValue} setStatusValue={setStatusValue}
                        lblName='Reports'
                    />

                    <div className="table_tag">

                        {scstHubList.length ? (
                            <TableContainer component={Paper}>
                                <TablePagination
                                    component="div"
                                    count={count}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    style={{ float: 'left' }}
                                />
                                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>

                                            <TableCell>No</TableCell>
                                            <TableCell>Startup Name</TableCell>
                                            <TableCell>District</TableCell>
                                            <TableCell>Assigned to hub</TableCell>
                                            <TableCell>Entity</TableCell>
                                            <TableCell >Onboarded Type</TableCell>
                                            <TableCell>Date</TableCell>
                                            <TableCell align='center'>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {scstHubList.map((row, index) => (
                                            <TableRow key={index}>

                                                <TableCell>
                                                    {
                                                        tableIndexValue(rowsPerPage, page, index)
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <CustomLink to={`/${role}/startups/${btoa(row.startupId)}/${btoa(row.partnerOrInvestorId)}`}>{row.startupName}</CustomLink>
                                                </TableCell>
                                                <TableCell>{row.district}</TableCell>
                                                <TableCell>{row.investorName}</TableCell>
                                                <TableCell>{row.entityType}</TableCell>
                                                <TableCell>
                                                    {
                                                        row.onboardType === 1 ? 'Manual' : 'Portal'
                                                    }
                                                </TableCell>
                                                <TableCell>{row.date}</TableCell>
                                                <TableCell align='center'>{row.status}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <caption>
                                        <TablePagination
                                            component="div"
                                            count={count}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            style={{ float: 'left' }}
                                        />
                                    </caption>
                                </Table>
                            </TableContainer>
                        ) : (
                            <div className="nodata_tag">
                                <img
                                    src="/images/Tanfundlogo-black-1.png"
                                    className="no_data_logo"
                                    alt=""
                                />
                                <h4>Reports list is empty</h4>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reports
