import React, { useContext, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Accordion, AccordionDetails, AccordionSummary, Button, Table, TableBody, TableCell, TableContainer, TableRow, Paper, TextField, Typography, FormControl, FormHelperText, FormLabel, RadioGroup, FormControlLabel, Radio, Autocomplete, InputLabel, Select, MenuItem } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DataContext from '../../../../context/DataContext';
import notifyService from '../../../../api/notifySerivce';
import { Link } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import Viewimage from '../../../../api/Viewimage';
import { FaRegFilePdf } from "react-icons/fa";
import { BsFiletypeXls } from "react-icons/bs";
// import cryptodecryptJS from '../../../../hooks/cryptodecryptJS';
// import PreliminaryTextPart1 from './PreliminaryTextPart1';
const fileTypesPDF = ["pdf"];
const fileTypesExcel = ["XLSX",];
const fileTypesPDFandExcel = ["pdf", "XLSX",];
const fileTypesAll = ["pdf", "XLSX", "doc", "docx"];

const FDDAdditionalDoc2 = () => {
    const [formData, setFormData] = useState({});
    const { handleBackapp, setPageLoading } = useContext(DataContext)
    const { handleSubmit, setValue, getValues, clearErrors, control, formState: { errors } } = useForm({
        values: formData,
    });
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const [nonEditAble, setNonEditAble] = useState(false);
    const [isAccordionOpen1, setIsAccordionOpen1] = useState(true);
    const {
        fields: patentsFields,
        append: patentsAppend,
        remove: patentsRemove
    } = useFieldArray({
        control,
        name: "patents"
    })
    const addPatentsDetailsBtn = () => {
        patentsAppend({
            id: 0,
            name: "",
            stage: "",
            number: "",
            doc: "",
            docname: ""
        })
    }
    const deletePatentsDetails = async (index) => {
        const id = getValues(`patents.${index}.id`);
        if (id !== 0 && id !== undefined) {
            apiService(`startup/patent/remove?id=${id}`, '', 'delete').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        indexBaseddeletepatentsRemove(index)
                    }
                }
            })
        } else {
            indexBaseddeletepatentsRemove(index)
        }
    }
    const indexBaseddeletepatentsRemove = (index) => {
        var startupTeams = getValues('patents')
        if (startupTeams.length === 1 && index === 0) {

            var val = getValues()
            val.patents = [{
                id: 0,
                name: "",
                stage: "",
                number: "",
                doc: "",
                docname: ""
            }]
            setFormData(val)
        } else {

            patentsRemove(index)
            var val = getValues()
            setFormData(val)
        }
    }
    const [selectedType, setSelectedType] = useState('');

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedType(value);
    };
    useDidMountEffect(() => {
        getApi()
    }, [])
    const [fileUploadId, setFileUploadId] = useState(0)
    const getApi = async () => {
        setPageLoading(true)
        await apiService(`startup/fdd/section/two/get`, '', 'get').then((res) => {
            if (res && res.data) {
                var data = res.data
                if (data.editForm === false) {
                    setReadyOnlyValue(true)
                }
                setNonEditAble(true)
                if (res.data.id) {
                    setFileUploadId(res.data.id)
                }

                setFormData(res.data)
            }
            setPageLoading(false)
        })
    }
    const handleFileUpload = (event, filekey, fileName, fileURL, format) => {
        let logoselecetdFile;
        if (event !== null) {
            if (event.target === undefined) {
                logoselecetdFile = event
            } else {
                logoselecetdFile = event.target.files[0]
            }
            if (logoselecetdFile) {
                const fileSizeLimit = 5 * 1024 * 1024;
                if (logoselecetdFile.size > fileSizeLimit) {
                    notifyService(
                        "danger",
                        "File Size Exceeded",
                        "Please upload a file smaller than 5 MB."
                    );
                    return;
                }
                var reader = new FileReader();
                var imagetype = logoselecetdFile.type
                var imagedatatype = imagetype.split("/")
                var img_crt_type = imagedatatype[1]
                debugger
                if (
                    ((format === 'file') && (img_crt_type === "pdf")) ||
                    ((format === 'excel') && (img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet")) ||
                    ((format === 'pdfAndExcel') && (img_crt_type === "pdf" || img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet")) ||
                    ((format === 'excelAndDoc') && (img_crt_type === "vnd.openxmlformats-officedocument.wordprocessingml.document" || img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet")) ||
                    ((format === 'pdfwordexcel') && (img_crt_type === "pdf" || img_crt_type === "vnd.openxmlformats-officedocument.wordprocessingml.document" || img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
                ) {
                    setValue(filekey, "")
                    setValue(fileName, "")
                    debugger
                    var fileValue = logoselecetdFile
                    reader.readAsDataURL(logoselecetdFile);
                    reader.onload = () => {
                        var logourl1 = reader.result;
                        var spl = logourl1.split(',')
                        var ImageValue = spl[1]
                        var img_name = fileValue.name
                        setValue(filekey, ImageValue)
                        setValue(fileName, img_name)
                        setValue(fileURL, logourl1)
                        clearErrors(filekey);
                        clearErrors(fileName);
                        clearErrors(fileURL);
                        var req = {
                            [filekey]: ImageValue,
                            [fileName]: img_name,
                            id: fileUploadId
                        }
                        // console.log(req);
                        saveData(req)
                    }
                } else {
                    notifyService('danger', 'File Format Invalided', 'Please check your file format')
                }
            }
        }
    }
    const saveData = (req) => {
        console.log(req);
        apiService('startup/fdd/section/two/save', req, 'post').then((res) => {
            setPageLoading(false);
            if (res && res.data) {
                if (res.data.responseStatus === "Success") {
                    getApi()
                }
            }
        })
    }
    const handleClickOpens = (url) => {
        debugger
        if (url) {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.rel = 'noopener noreferrer';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };
    const onSubmit = (data) => {
        handleBackapp()
        // setPageLoading(true);

        // apiService('startup/fdd/section/one/save', data, 'post').then((res) => {
        //     setPageLoading(false);
        //     if (res && res.data) {
        //         if (res.data.responseStatus === "Success") {
        //             notifyService('success', 'Success', 'Saved successfully !!')
        //             handleBackapp()
        //         }
        //     }
        // })
    }
    return (
        <section className='container-fluid'>
            <div className='form_div'>
                <div className="d-flex align-items-center">
                    <span className="back_span" onClick={handleBackapp}>
                        <img src='/images/Fig-back.png' className='img-fluid' alt='' />
                    </span>
                    <h6 className="ml-2 mb-0">Additional Document - 2</h6>
                </div>
                <hr />
                <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className=''>
                        <div className="row doc-row">
                            <div className="col-lg-12 col-12 mb-3 pl-3">
                                <Accordion className='Addfundacc-bor' expanded={isAccordionOpen1} onChange={() => setIsAccordionOpen1(!isAccordionOpen1)}>
                                    <AccordionSummary
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className='Addfundacc'
                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <Typography sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            margin: 0
                                        }}>
                                            <AddCircleIcon className='add-icon mr-3' />List of material patents, copyrights, licenses, trade secrets, trade names and trademarks whether registered (issued and pending) or unregistered.
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <div className='col-lg-12 col-12'>
                                                {
                                                    (patentsFields.length >= 0) && (
                                                        <TableContainer component={Paper} className='table-back' sx={{ overflowX: 'auto', maxWidth: '100%' }}>
                                                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                                <TableBody >
                                                                    {patentsFields.map((field, index, type) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell sx={{ borderBottom: 'none' }}>{index + 1}</TableCell>
                                                                            <TableCell sx={{ width: '20%', borderBottom: 'none' }}>
                                                                                <FormControl sx={{ width: '100%' }}>
                                                                                    <InputLabel id="dropdown-label">Choose *</InputLabel>
                                                                                    <Controller name={`patents.${index}.name`} control={control} defaultValue="" rules={{ required: 'Name is required' }}
                                                                                        render={({ field }) =>
                                                                                            <Select
                                                                                                {...field}
                                                                                                variant="outlined"
                                                                                                onChange={(e) => {
                                                                                                    handleSelectChange(e);
                                                                                                    field.onChange(e);
                                                                                                }}
                                                                                                disabled={nonEditAble}
                                                                                            >
                                                                                                <MenuItem value={'Patent'}>Patent</MenuItem>
                                                                                                <MenuItem value={'Trademark'}>Trademark</MenuItem>
                                                                                                <MenuItem value={'Copyright'}>Copyright</MenuItem>
                                                                                            </Select>
                                                                                        } />
                                                                                    <FormHelperText className='text-danger'>
                                                                                        {errors.patents?.[index]?.name && errors.patents?.[index]?.name.message}
                                                                                    </FormHelperText>
                                                                                </FormControl>
                                                                            </TableCell>
                                                                            <TableCell sx={{ width: '20%', borderBottom: 'none' }} >
                                                                                <FormControl sx={{ width: '100%' }}>
                                                                                    <InputLabel id="dropdown-label">Stage *</InputLabel>
                                                                                    <Controller name={`patents.${index}.stage`} control={control} defaultValue="" rules={{ required: 'Name is required' }}
                                                                                        render={({ field }) =>
                                                                                            <Select
                                                                                                {...field}
                                                                                                variant="outlined"
                                                                                                disabled={nonEditAble}
                                                                                            >
                                                                                                <MenuItem value={'0'}>Process of Applying</MenuItem>
                                                                                                <MenuItem value={'1'}>Applied</MenuItem>
                                                                                                <MenuItem value={'2'}>Granted</MenuItem>
                                                                                                <MenuItem value={'3'}>Yes</MenuItem>
                                                                                            </Select>
                                                                                        } />
                                                                                    <FormHelperText className='text-danger'>
                                                                                        {errors.patents?.[index]?.stage && errors.patents?.[index]?.stage.message}
                                                                                    </FormHelperText>
                                                                                </FormControl>
                                                                            </TableCell>
                                                                            <TableCell sx={{ width: '25%', borderBottom: 'none' }} >
                                                                                <FormControl sx={{ width: '100%' }} >
                                                                                    <Controller name={`patents.${index}.number`} control={control} defaultValue="" rules={{ required: 'Description is required' }}
                                                                                        render={({ field }) =>
                                                                                            <TextField
                                                                                                id="outlined-multiline-static"
                                                                                                label="Description *"
                                                                                                placeholder='Enter Description'
                                                                                                fullwidth
                                                                                                {...field}
                                                                                                disabled={nonEditAble}

                                                                                            />} />
                                                                                    <FormHelperText className='text-danger'>
                                                                                        {errors.patents?.[index]?.number && errors.patents?.[index]?.number.message}
                                                                                    </FormHelperText>
                                                                                </FormControl>
                                                                            </TableCell>
                                                                            <TableCell sx={{ width: '25%', borderBottom: 'none', padding: '5px' }}>
                                                                                {
                                                                                    getValues(`patents.${index}.docname`) !== '' && <span>Upload Receipt <span className='text-danger'>*</span></span>
                                                                                }
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className='form-group my-1'>
                                                                                        <Controller name={`patents.${index}.doc`} control={control} defaultValue="" rules={{ required: false }}
                                                                                            render={({ field }) =>

                                                                                                <FileUploader
                                                                                                    {...field}
                                                                                                    handleChange={(e) => handleFileUpload(e,
                                                                                                        `patents.${index}.doc`,
                                                                                                        `patents.${index}.docName`,
                                                                                                        `patents.${index}.docNameURL`,
                                                                                                        'file'
                                                                                                    )}
                                                                                                    value={getValues(`patents.${index}.doc`)}
                                                                                                    name="file"
                                                                                                    types={fileTypesPDF}
                                                                                                    children={

                                                                                                        <div className={getValues(`patents.${index}.doc`) !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                                                            <center>
                                                                                                                <p className={getValues(`patents.${index}.doc`) !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}
                                                                                                                 style={{
                                                                                                                    backgroundColor: nonEditAble 
                                                                                                                        ? (getValues(`patents.${index}.doc`) !== '' ? 'gray' : 'gray') 
                                                                                                                        : ''
                                                                                                                }}>
                                                                                                                    <span>
                                                                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                                                                        {getValues(`patents.${index}.doc`) !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                                                                                                    </span>
                                                                                                                </p>
                                                                                                            </center>
                                                                                                        </div>
                                                                                                    }
                                                                                                    disabled={nonEditAble}
                                                                                                />
                                                                                            } />
                                                                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                                                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.patents?.[index]?.doc && errors.patents?.[index]?.doc.message}</FormHelperText>

                                                                                    </div>
                                                                                    <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link
                                                                                        onClick={() => handleClickOpens(getValues(`patents.${index}.docname`))}
                                                                                        style={{ color: "#80809e" }}
                                                                                    > Download
                                                                                    </Link>
                                                                                    </span>
                                                                                </div>
                                                                            </TableCell>
                                                                            {/* <TableCell sx={{ border: 'none' }} align='center' disabled={nonEditAble}>
                                                                                <DeleteIcon style={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} />
                                                                                
                                                                            </TableCell> */}
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                            {/* <center >
                                                                <Button className='add-more-btn' disabled={nonEditAble} onClick={() => addPatentsDetailsBtn('')  }><AddCircleIcon className='mr-2' />Add More</Button>
                                                            </center> */}
                                                        </TableContainer>
                                                    )
                                                }

                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>Availed any facilities from the bank such as OD, CC or Term Loans? If yes, provide with the - Closing balance confirmation for outstanding liability - Sanction Letters</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="bankFacilityDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>

                                                <FileUploader
                                                    {...field}
                                                    handleChange={(e) => handleFileUpload(e,
                                                        "bankFacilityDoc",
                                                        "bankFacilityDocName",
                                                        "bankFacilityDocURL",
                                                        'pdfwordexcel')}
                                                    value={getValues('bankFacilityDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('bankFacilityDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('bankFacilityDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('bankFacilityDoc') !== '' ? 'Uploaded (Click to Change)' : 'Bank Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.bankFacilityDoc && errors.bankFacilityDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit" onClick={() => handleClickOpens(getValues('bankFacilityDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link
                                         style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>List out each location, Rental Agreement for each location </FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="rentalAgreementDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>

                                                <FileUploader
                                                    {...field}
                                                    handleChange={(e) => handleFileUpload(e,
                                                        "rentalAgreementDoc",
                                                        "rentalAgreementDocName",
                                                        "rentalAgreementDocURL",
                                                        'pdfwordexcel')}
                                                    value={getValues('rentalAgreementDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('rentalAgreementDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('rentalAgreementDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('rentalAgreementDoc') !== '' ? 'Uploaded (Click to Change)' : 'Location Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.rentalAgreementDoc && errors.rentalAgreementDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit" onClick={() => handleClickOpens(getValues('rentalAgreementDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                                        
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>Does the company have "Employee Advance Policy", if so provide thereof.</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="advancePolicyDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>

                                                <FileUploader
                                                    {...field}
                                                    handleChange={(e) => handleFileUpload(e,
                                                        "advancePolicyDoc",
                                                        "advancePolicyDocName",
                                                        "advancePolicyDocURL",
                                                        'pdfwordexcel')}
                                                    value={getValues('advancePolicyDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('advancePolicyDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('advancePolicyDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('advancePolicyDoc') !== '' ? 'Uploaded (Click to Change)' : 'Policy Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.advancePolicyDoc && errors.advancePolicyDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit" onClick={() => handleClickOpens(getValues('advancePolicyDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                                        
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>Details of ESOP creation/implementation status, if any</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="esopDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>

                                                <FileUploader
                                                    {...field}
                                                    handleChange={(e) => handleFileUpload(e,
                                                        "esopDoc",
                                                        "esopDocName",
                                                        "esopDocURL",
                                                        'pdfwordexcel')}
                                                    value={getValues('esopDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('esopDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('esopDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('esopDoc') !== '' ? 'Uploaded (Click to Change)' : 'ESOP Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.esopDoc && errors.esopDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit" onClick={() => handleClickOpens(getValues('esopDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                                        
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>Agreements and any other documentation done for loans received.</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="companyLoanDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>

                                                <FileUploader
                                                    {...field}
                                                    handleChange={(e) => handleFileUpload(e,
                                                        "companyLoanDoc",
                                                        "companyLoanDocName",
                                                        "companyLoanDocURL",
                                                        'pdfwordexcel')}
                                                    value={getValues('companyLoanDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('companyLoanDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('companyLoanDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}
                                                                style={{
                                                                    backgroundColor: nonEditAble 
                                                                        ? (getValues('companyLoanDoc') !== '' ? 'gray' : 'gray') 
                                                                        : ''
                                                                }}
                                                                >
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('companyLoanDoc') !== '' ? 'Uploaded (Click to Change)' : 'Loans Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={nonEditAble}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.companyLoanDoc && errors.companyLoanDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"  onClick={() => handleClickOpens(getValues('companyLoanDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                                       
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>In case directors, have given loans, a declaration that the loans given to the company is not out of borrowed funds.</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="loanDeclarationDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>

                                                <FileUploader
                                                    {...field}
                                                    handleChange={(e) => handleFileUpload(e,
                                                        "loanDeclarationDoc",
                                                        "loanDeclarationDocName",
                                                        "loanDeclarationDocURL",
                                                        'pdfwordexcel')}
                                                    value={getValues('loanDeclarationDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('loanDeclarationDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('loanDeclarationDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('loanDeclarationDoc') !== '' ? 'Uploaded (Click to Change)' : 'Borrowed Funds Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.loanDeclarationDoc && errors.loanDeclarationDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit" onClick={() => handleClickOpens(getValues('loanDeclarationDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                                        
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>List of any disputed liability claims asserted by any of the Company’s vendors, suppliers, or customers.</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="liabilityClaimDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>

                                                <FileUploader
                                                    {...field}
                                                    handleChange={(e) => handleFileUpload(e,
                                                        "liabilityClaimDoc",
                                                        "liabilityClaimDocName",
                                                        "liabilityClaimDocURL",
                                                        'pdfwordexcel')}
                                                    value={getValues('liabilityClaimDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('liabilityClaimDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('liabilityClaimDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('liabilityClaimDoc') !== '' ? 'Uploaded (Click to Change)' : 'Claims Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.liabilityClaimDoc && errors.liabilityClaimDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"  onClick={() => handleClickOpens(getValues('liabilityClaimDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                                       
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>Any ongoing litigations?</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="ongoingLitigationDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>

                                                <FileUploader
                                                    {...field}
                                                    handleChange={(e) => handleFileUpload(e,
                                                        "ongoingLitigationDoc",
                                                        "ongoingLitigationDocName",
                                                        "ongoingLitigationDocURL",
                                                        'pdfwordexcel')}
                                                    value={getValues('ongoingLitigationDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('ongoingLitigationDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('ongoingLitigationDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('ongoingLitigationDoc') !== '' ? 'Uploaded (Click to Change)' : 'Litigations Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.ongoingLitigationDoc && errors.ongoingLitigationDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit" onClick={() => handleClickOpens(getValues('ongoingLitigationDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                                        
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>Note on Pricing Policy</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="pricingPolicyDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>

                                                <FileUploader
                                                    {...field}
                                                    handleChange={(e) => handleFileUpload(e,
                                                        "pricingPolicyDoc",
                                                        "pricingPolicyDocName",
                                                        "pricingPolicyDocURL",
                                                        'pdfwordexcel')}
                                                    value={getValues('pricingPolicyDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('pricingPolicyDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('pricingPolicyDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('pricingPolicyDoc') !== '' ? 'Uploaded (Click to Change)' : 'Pricing Policy Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.pricingPolicyDoc && errors.pricingPolicyDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit" onClick={() => handleClickOpens(getValues('pricingPolicyDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                                        
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>List of Key Customers and the Revenue from them for the last 24 Months</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="keyCustomerDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>

                                                <FileUploader
                                                    {...field}
                                                    handleChange={(e) => handleFileUpload(e,
                                                        "keyCustomerDoc",
                                                        "keyCustomerDocName",
                                                        "keyCustomerDocURL",
                                                        'pdfwordexcel')}
                                                    value={getValues('keyCustomerDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('keyCustomerDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('keyCustomerDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('keyCustomerDoc') !== '' ? 'Uploaded (Click to Change)' : 'Revenue Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.keyCustomerDoc && errors.keyCustomerDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit" onClick={() => handleClickOpens(getValues('keyCustomerDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                                        
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>Note on internal control system</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="internalControlSysDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>

                                                <FileUploader
                                                    {...field}
                                                    handleChange={(e) => handleFileUpload(e,
                                                        "internalControlSysDoc",
                                                        "internalControlSysDocName",
                                                        "internalControlSysDocURL",
                                                        'pdfwordexcel')}
                                                    value={getValues('internalControlSysDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('internalControlSysDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('internalControlSysDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('internalControlSysDoc') !== '' ? 'Uploaded (Click to Change)' : 'Internal Control Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.internalControlSysDoc && errors.internalControlSysDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"  onClick={() => handleClickOpens(getValues('internalControlSysDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                                       
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>Any notices received, assessment orders, demand relating to any statutory authorities Legal notice received, issued and status so far</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="noticesDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>

                                                <FileUploader
                                                    {...field}
                                                    handleChange={(e) => handleFileUpload(e,
                                                        "noticesDoc",
                                                        "noticesDocName",
                                                        "noticesDocURL",
                                                        'pdfwordexcel')}
                                                    value={getValues('noticesDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('noticesDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('noticesDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('noticesDoc') !== '' ? 'Uploaded (Click to Change)' : 'Authorities Legal Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.noticesDoc && errors.noticesDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"  onClick={() => handleClickOpens(getValues('noticesDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                                       
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>Any major disputes with customers, vendors, employees and status of the same.</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="majorDisputeDoc" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>

                                                <FileUploader
                                                    {...field}
                                                    handleChange={(e) => handleFileUpload(e,
                                                        "majorDisputeDoc",
                                                        "majorDisputeDocName",
                                                        "majorDisputeDocURL",
                                                        'pdfwordexcel')}
                                                    value={getValues('majorDisputeDoc')}
                                                    name="file"
                                                    types={fileTypesAll}
                                                    children={
                                                        <div className={getValues('majorDisputeDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('majorDisputeDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('majorDisputeDoc') !== '' ? 'Uploaded (Click to Change)' : 'Major Disputes Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.majorDisputeDoc && errors.majorDisputeDoc.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit" onClick={() => handleClickOpens(getValues('majorDisputeDocURL'))}><VisibilityIcon className='icon-size mr-1' /><Link

                                        
                                        style={{ color: "#80809e" }}
                                    > Download
                                    </Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group d-flex justify-content-end mt-3 mr-2">
                        <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
                        {
                            (readyOnlyValue === false) ? (
                                <Button
                                    type="submit"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    className="btn btn-primary submit px-3 ml-3"
                                >
                                    <span>Save & Proceed Next</span>
                                </Button>
                            ) : (<></>)
                        }

                    </div>
                </form>
            </div>
            {/* <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} /> */}
        </section>
    )
}

export default FDDAdditionalDoc2
